import { useState, useEffect, useCallback } from "react";
import { Container, Box, Stepper, StepLabel, Step, Typography, Grid, Card, CardContent, CardActions, Button, CardHeader } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox, Alert, AlertTitle, Collapse } from '@mui/material'
import { useDataStore } from '../scripts/formStore'
import { sharesInit } from "../scripts/initFormData";
import apiReq from '../scripts/apiReq'
import Personal from '../components/forms/Shares/Personal'
import Shares from '../components/forms/Shares/Shares'
import Address from '../components/forms/Shares/Address'

import { useLocation, useNavigate } from 'react-router-dom'
// import { MaritalStatus, OwnershipStatus, IDType, EmploymentType, ReferenceType } from '../scripts/enums'
// import moment from 'moment'

import sanitizeInput from '../scripts/sanitizeInput'
import { formatMoney } from "../scripts/utilities";
import { SettlementMethod } from "../scripts/enums";

export default function App() {
	let defaultData = useDataStore((state) => state.shares);
	const manageData = useDataStore((state) => state.manageData);
	const [showError, setError] = useState({ error: false, msg: '' });

	// capture application data passed to the form, then clear location state to prevent data being used again on reloads
	const appData = useLocation().state
	if (appData) {
		defaultData = (appData.newApp) ? sharesInit : appData
		window.history.replaceState({}, document.title)
	}

	let [data, setData] = useState(defaultData);
	const DefaultStep = defaultData.step !== undefined ? defaultData.step : 0; //check if previous step is already available
	const DefaultCompleted = defaultData.reviewStep !== undefined ? defaultData.reviewStep : false; //check if form has already reach review step
	let [currentStep, setCurrentStep] = useState(DefaultStep);
	let [completed, setCompleted] = useState(DefaultCompleted);
	const [formLabels, setformLabels] = useState([]);

	//disable number field scroll issue
	document.addEventListener("wheel", function (event) {
		if (document.activeElement.type === "number") {
			document.activeElement.blur();
		}
	});

	useEffect(() => {
		// code to run on component mount
		//get data here
		checkForData();
		// formatAPIDates();
		steps.forEach((child) => {
			formLabels.push(child.props.label);
			setformLabels(formLabels);
		})
		// eslint-disable-next-line
	}, []);


	//every time data / currentstep state change set the global local cache
	useEffect(() => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		// console.log('data changed', data);
		// let step = currentStep + 1;
		setToLocalStorage(data, currentStep);
		//eslint-disable-next-line
	}, [data, currentStep]);


	//eslint-disable-next-line
	const formSucess = useNavigate();

	const makeRequest = (formData) => {
		//Attach id to data so API knows to update
		let existingEntry = data.id !== undefined && data.id !== "";
		if (existingEntry) formData.id = data.id;

		formData.lastCompletedStep = data.step;

		if(completed) formData.VisitedReviewStep = true;
		else formData.VisitedReviewStep = false;

		if(formData.isMember !== undefined){
			if(formData.isMember) formData.hasCoApplicant = false;
			else{
				formData.shareAccountNumber = 0;
				if(formData.savingsAccountNumber !== undefined) formData.savingsAccountNumber = 0;
			}
		}

		//if whole data  is sent 
		if (formData.isComplete !== undefined && formData.isComplete) {
			delete formData.reviewStep;
			delete formData.step;
			delete formData.updated;
		}
		delete formData.confirmEmail;

		console.log('sending this to API', { formData, existingEntry });
		apiReq.post('Shares/update', formData).then(data => {
			console.log("makeRequest: Response from API", data);
			if (formData.isComplete !== undefined && formData.isComplete) {
				manageData(sharesInit, 'shares');
				formSucess('/apply/success', { state: 'Form submitted' });
				return
			}
			if (data === "Email Already Exisits") {
				setCurrentStep((prev) => prev - 1);
				setError({ error: true, msg: 'This email address already exists!' });
				setData((prev) => ({ ...prev, confirmEmail: '' }));
				return
			}
			//This is to check if it was updated or created
			if (typeof data === 'object' && data !== null) setData((prev) => ({ ...prev, ...data }));
			else {
				if (!existingEntry) setData((prev) => ({ ...prev, id: data }));
			}
			// setCurrentStep((prev) => prev + 1);
			setError({ error: false, msg: '' });
		}).catch(resp => {
			console.log("Error in API \n", resp);
			setError({ error: true, msg: 'There was a problem retrieving your application. Please try again, or contact us for further assistance.' })
		});
	}

	// Set to the global local cache
	const setToLocalStorage = useCallback((data, step) => {
		console.log('setting local storage');
		if (completed) data.reviewStep = true;
		else data.reviewStep = false;
		data.step = step;
		data.updated = new Date().getTime();
		manageData(data, 'shares');
	}, [manageData, completed]);

	const handleNextStep = (newData, final = false) => {
		console.log('Handling next step', newData);
		if (newData !== undefined) {
			if(final === true) newData.visitedReviewStep = true;
			setData((prev) => ({ ...prev, ...newData }));
			makeRequest(newData);
		}
		//if form completed
		if (final === true) {
			setCompleted(true);
			setCurrentStep(steps.length);
			// makeRequest(newData);
			return;
		}

		setCurrentStep((prev) => prev + 1);
		// if(newData !== undefined) setToLocalStorage(newData, currentStep + 1);
	};

	const handlePrevStep = (newData) => {
		if (newData !== undefined) {
			setData((prev) => ({ ...prev, ...newData }));
			if (completed) makeRequest(newData);
			setToLocalStorage(newData, currentStep - 1);
		}
		setCurrentStep((prev) => prev - 1);
	};

	const personalData = {
		shareAccountNumber: data.shareAccountNumber,
		firstName: data.firstName,
		lastName: data.lastName,
		email: data.email,
		isMember: data.isMember,
		hasCoApplicant: data.hasCoApplicant
	}

	const physicalAddressData = {
		...data.physicalAddress,
		nationality: data.nationality
	}

	const sharesData = {
		sharesRequested: data.sharesRequested,
		purchaseTotal: data.purchaseTotal,
		settlementMethod: data.settlementMethod,
		savingsAccountNumber: data.savingsAccountNumber,
		isMember: data.isMember
	}

	//Put the steps in array to switch between them during render
	const steps = [
		<Personal sanitizeInput={sanitizeInput} next={handleNextStep} data={personalData} label="Personal" completed={completed} />,
		<Shares sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={sharesData} label="Shares" completed={completed} />,
		<Address sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={physicalAddressData} label="Address" completed={completed} />,
	];

	// Check for user data in the begin 
	const checkForData = () => {
		let day = 886400 * 1000;
		let thirtyDays = day * 30;
		let timePassed = new Date().getTime() - data.updated;
		if (data.updated !== null && timePassed < thirtyDays){
			if(data.step > 0) setCurrentStep(data.step + 1);
		}
		else{
			setData(sharesInit);
			setCurrentStep(0);
		}
		// console.log({timePassed}, data.updated !== null && timePassed < thirtyDays)
	}

	function MyStepper(steps) {
		return (
			<Stepper alternativeLabel activeStep={currentStep}>
				{
					steps.steps.map((child, index) => {
						return (
							<Step key={child.props.label} completed={completed || currentStep > index}>
								<StepLabel>{child.props.label}</StepLabel>
							</Step>
						)
					})
				}
			</Stepper>
		)
	}

	return (
		<Container>
			<Box><h1 className="page-title center">Purchase Shares</h1></Box>
			<Box>
				<MyStepper steps={steps} />
			</Box>
			<Box>
				<Collapse in={showError.error} style={{ marginTop: '1.5rem' }}>
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						{showError.msg}
					</Alert>
				</Collapse>
			</Box>
			{
				currentStep < steps.length && completed &&
				<Box paddingTop={6}>
					<Typography align="center" variant="h5" style={{ textAlign: 'center' }}>{steps[currentStep].props.label}</Typography>
				</Box>
			}
			<Box paddingTop={6}>
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						{currentStep < steps.length ? steps[currentStep] : <StepReview data={data} formLabels={formLabels} prev={handlePrevStep} setCurrentStep={setCurrentStep} makeRequest={makeRequest} />}
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}


const StepReview = ({ data, prev, formLabels, setCurrentStep, makeRequest }) => {
	const [submitDisabled, setSubtmitBtn] = useState(true);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography align="center" variant="h4" component="div">Review information here</Typography>
					</CardContent>

				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Personal" />
					<CardContent>
						<Container>First Name: <Typography variant="span" color="text.secondary">{data.firstName}</Typography></Container>
						<Container>Last Name: <Typography variant="span" color="text.secondary">{data.lastName}</Typography></Container>
						<Container>Email: <Typography variant="span" color="text.secondary">{data.email}</Typography></Container>
						<Container>Existing Member: <Typography variant="span" color="text.secondary">{data.isMember ? 'Yes' : 'No'}</Typography></Container>
						<Container>Share Account Number: <Typography variant="span" color="text.secondary">{data.shareAccountNumber ? 'Yes' : 'No'}</Typography></Container>
						<Container>Has Co Applicant: <Typography variant="span" color="text.secondary">{data.hasCoApplicant ? 'Yes' : 'No'}</Typography></Container>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(0))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Shares" />
					<CardContent>
						<Container>Shares Requested: <Typography variant="span" color="text.secondary">{data.sharesRequested}</Typography></Container>
						<Container>Purchase Total: <Typography variant="span" color="text.secondary">{formatMoney(data.purchaseTotal)}</Typography></Container>
						<Container>Settlement Method: <Typography variant="span" color="text.secondary">{SettlementMethod.getLabel(data.settlementMethod)}</Typography></Container>
						<Container>Savings Account Number: <Typography variant="span" color="text.secondary">{data.savingsAccountNumber}</Typography></Container>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(1))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Address" />
					<CardContent>
						<Container>Street: <Typography variant="span" color="text.secondary">{data.physicalAddress.street}</Typography></Container>
						<Container>City: <Typography variant="span" color="text.secondary">{data.physicalAddress.city}</Typography></Container>
						<Container>State: <Typography variant="span" color="text.secondary">{data.physicalAddress.state}</Typography></Container>
						<Container>Zip: <Typography variant="span" color="text.secondary">{data.physicalAddress.zip}</Typography></Container>
						<Container>Nationality: <Typography variant="span" color="text.secondary">{data.nationality}</Typography></Container>
						{/* <Container>Ownership: <Typography variant="span" color="text.secondary">{OwnershipStatus.getLabel(data.physicalAddress.ownership)}</Typography></Container> */}
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(2))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Disclaimer Agreement" />
					<CardContent>
						<Grid container>
							<Grid item xs={12}>
								<Alert severity="info">You promise that everything you have stated in this application is correct to the best of your knowledge and that the above information is a complete listing of what you owe.
									If there are any important changes you will notify us in writing immediately. You authorize the Credit Union to obtain credit
									reports in connection with this application for credit and for any update, increase, renewal, extension or collection of the credit received. You understand that the
									Credit Union will rely on the information in this application and your credit report to make its decision. If you request, the Credit Union will tell you the name and address of any credit bureau
									from which it received a credit report on you. It is a crime to willfully and deliberately provide incomplete or incorrect information
								</Alert>
							</Grid>
							<Grid item xs={12} alignContent="center" justifyContent={"center"}>
								<FormGroup>
									<FormControlLabel control={<Checkbox onClick={() => setSubtmitBtn(submitDisabled ? false : true)} />} label="I promise" />
								</FormGroup>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions>
						<Button size="small" variant="contained" color="primary" disabled={submitDisabled} onClick={() => makeRequest({ isComplete: true, ...data })}>
							Submit
						</Button>
					</CardActions>
				</Card>
			</Grid>
		</Grid>
	);
};

