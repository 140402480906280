import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import GetInProgressAppForm from '../components/GetInProgressAppForm'
import Custom404 from './Custom404';
import apiReq from "../scripts/apiReq";
import { LinearProgress } from "@mui/material";

const ServiceDetails = () => {
  const { urlSlug } = useParams();
  const [modalState, updateModalState] = useState(false)
  const [service, setService] = useState(null)
  const [isPending, setIsPending] = useState(true)
  const [error, setError] = useState(null);

  const navigate = useNavigate()
  const serviceData = useLocation().state
  
  // get service details on mount
  useEffect(() => {
    // use data coming from services page, otherwise get the API
    if (serviceData) {
      setService(serviceData)
      setIsPending(false)
    } else {
      apiReq.get(`services/view/${urlSlug}`).then(response => {
        // console.log("API response: ", response)
  
        if (Object.keys(response).length) {
          setService(response)
        } else {
          setError("No service found")
        }
  
        setIsPending(false)
      }).catch(e => {
        console.log("Error getting service from API:\n", e)
        setError("There was a problem loading the service")
        setIsPending(false)
      })
    }

    // when unmounting, abort running API requests
    return () => {
      apiReq.stop()
    }
    //eslint-disable-next-line
  }, [])
  
  const _handleModalOpen = () => updateModalState(true);
  const _handleModalClose = () => updateModalState(false);

  if (error) return <Custom404 />

  return (
    <>
      <CssBaseline />
      <Container>
        <Box className="service-details" sx={!service ? { p: '10rem' }: {}}>
            { isPending && <LinearProgress /> }
            { service && (
              <article>
                <h1 className="page-title">{ service.friendlyName }</h1>
                <div style={{ marginBottom: '3rem' }}>{ service.details }</div>

                <Stack direction="row" spacing={20}>
                  <Button onClick={_handleModalOpen} variant="outlined" color="warning">Continue Application</Button>
                  <Button onClick={() => navigate(`/apply/${urlSlug}`, { state: { newApp: true } })} variant="contained">New Application</Button>
                </Stack>

                <GetInProgressAppForm modalState={modalState} closeHandler={_handleModalClose} service={service} />
              </article>
            )}
        </Box>
      </Container>
    </>
  );
}

export default ServiceDetails;

