import { Box, Button, Container, CssBaseline } from "@mui/material";
import { Link } from "react-router-dom"

const Custom404 = () => {
  return (
    <>
      <CssBaseline />
      <Container>
        <Box sx={{ textAlign: 'center' }}>
            <h1 className="page-title center">Resource Not Found</h1>
            <Button component={Link} to="/" variant="contained">Go to Home</Button>
        </Box>
      </Container>
    </>
  );
}
 
export default Custom404;