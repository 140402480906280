// use this file for generic functions that aren't likely to rely on specific third-party packages

/**
 * Halts processing for the specified time
 * @param {*} time in milliseconds
 */
export const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

/**
 * Formats a number as money using the ECMAScript Internationalization API
 * @param {*} number 
 * @returns `formatted number`
 */
export const formatMoney = (number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
}

/**
 * Performs a deep equality check of two values -- code credit: https://www.30secondsofcode.org/js/s/equals
 * @param {*} a 
 * @param {*} b 
 * @returns `true` or `false`
 */
export const deepCompare = (a, b) => {
    console.log("Comparing: ", "new: ", a, "old: ", b)
    // check if the values are identical
    if (a === b) {
        console.log("identical comparison")
        return true;
    }

    // check if date objects are the same on a millisecond scale
    if (a instanceof Date && b instanceof Date) {
        console.log("date comparison")
        return a.getTime() === b.getTime();
    }

    // check if both values are non-object values with an equivalent value (strict comparison)
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) {
        console.log("non-object value comparison")
        return a === b;
    }
    
    // check if only one value is null or undefined or if their prototypes differ
    console.log(a.prototype, b.prototype)
    if (a.prototype !== b.prototype) {
        console.log("prototypes comparison")
        return false;
    }

    // if none of the conditions above are met, check if values have the same number of keys
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) {
        console.log("keys comparison")
        return false;
    }

    // recursively deep compare each key in both objects / arrays
    // .every() executes the provided function, and returns immediately on a falsy value
    console.log("recursive comparison 👇🏾")
    console.log("object is array?", Array.isArray(a))
    return keys.every(k => {
        console.log(`-----\ncomparing: a[${k}] and b[${k}]`)
        return deepCompare(a[k], b[k])
    });
};