import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Grid, LinearProgress, Switch, Stack, Typography, Alert, AlertTitle, Collapse } from '@mui/material'
import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const stepTwoValidationSchema = Yup.object({
	address: Yup.string().required().label("Home Address"),
	city: Yup.string().required().label("City"),
	state: Yup.string().required().label("State"),
	// island: Yup.string().required().label("Island"),
});

const Assets = (props) => {
	const [assetsValue, setAssets] = useState(null);
	const [total, settotal] = useState(0);
	const [showError, setError] = useState({ error: false, msg: '' });
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});

	const handleSubmit = async (values) => {
		await sleep(100);
		if (!validateOtherAsset(true)) props.next({ assets: assetsValue });
		else setformSubmitting(false);
	};

	const handleCustomSubmit = async (type, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if (!validateOtherAsset(true)) {
					if (showError.error) setError({ error: false, msg: '' });
					if(type === 'prev') props.prev({ assets: assetsValue }); //go to previous step
					else props.next({ assets: assetsValue }, true); // go to review step
				} else setformSubmitting(false);
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true); 
				setformSubmitting(false);
			}
		} else if(type === 'prev') props.prev({ assets: assetsValue });
	}

	function validateOtherAsset(showAlert = false) {
		let foundEmpty = false;

		//check if any field is empty
		if (assetsValue.length > 0) {
			assetsValue.forEach(asset => {
				if (asset.asset === '') foundEmpty = true;
				if (asset.particulars === '') foundEmpty = true;
				if (asset.marketValue < 1) foundEmpty = true;
			})
		}

		if (foundEmpty && showAlert) setError({ error: true, msg: 'Please make sure all fields populated before proceeding' });
		return foundEmpty;
	}

	function addOtherAsset() {
		// let foundEmpty = validateOtherAsset();

		// if(!foundEmpty){
		//set new blank data into otherIncome array field
		let tempExpense = assetsValue;
		tempExpense.push({ asset: '', particulars: '', marketValue: 0, pledgedAsCollateral: false });
		setAssets(() => [...tempExpense]);
		// setshowNewField(true);
		// } else alert('Please make sure all fields populated before adding another asset');
	}

	function removeOtherAsset(index) {
		let tempExpense = assetsValue;
		tempExpense = tempExpense.filter((asset, i) => {
			return index !== i && asset
		});

		setAssets(() => tempExpense)
	}

	//Formik only updates the initialvalue from the field name and the global cache isnt set up with all fields on first level
	function handleOtherAssetts(e) {
		let tempFields = assetsValue;

		let targetOptions = e.target.id.split("_");
		let field = targetOptions[0], id = parseInt(targetOptions[1]);
		tempFields[id][field] = field === 'pledgedAsCollateral' ? e.target.checked : props.sanitizeInput({ fieldValue: e.target.value }).fieldValue;

		setAssets(() => [...tempFields]);
	}

	//everytime the data change from the props is changes the state
	useEffect(() => {
		console.log('setting fieldval 1st', props.data);
		if(props.data.length === 0) props.data.push({ asset: '', particulars: '', marketValue: 0, pledgedAsCollateral: false });
		setAssets(props.data);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (assetsValue !== null && assetsValue.length > 0) {
			let total = 0;
			assetsValue.forEach(income => {
				if (parseFloat(income.marketValue) > 0) total += parseFloat(income.marketValue);
			});

			settotal(total.toFixed(2));
		} else settotal(0.00);
	}, [assetsValue]);

	const btnColumnSize = props.completed ? 4 : 6;

	if (assetsValue === null) return <LinearProgress />

	return (
		<Formik enableReinitialize={true} validationSchemaz={stepTwoValidationSchema} initialValues={assetsValue} onSubmit={handleSubmit} >
			{({ values, handleChange, touched, errors, isSubmitting, validateForm, setFieldTouched }) => (
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Collapse in={showError.error}>
								<Alert severity="error" >
									<AlertTitle>Error</AlertTitle>
									{showError.msg}
								</Alert>
							</Collapse>
						</Grid>
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Asset</TableCell>
											<TableCell align="right">Particulars</TableCell>
											<TableCell align="right" style={{ width: 200 }}>Market Value (EC $)</TableCell>
											<TableCell align="right">Pledged as Collateral</TableCell>
											<TableCell align="right">Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											values.length > 0 &&
											values.map((data, index) => {
												return (
													<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
														<TableCell component="th" scope="row">
															<Field value={data.asset} onChange={handleOtherAssetts} fullWidth component={TextField} name={"asset_" + index} id={"asset_" + index} />
														</TableCell>
														<TableCell align="right">
															<Field value={data.particulars} onChange={handleOtherAssetts} fullWidth component={TextField} name={"particulars_" + index} id={"particulars_" + index} />
														</TableCell>
														<TableCell align="right">
															<Field value={data.marketValue} onChange={handleOtherAssetts} fullWidth component={TextField} type="number" name={"marketValue_" + index} id={"marketValue_" + index} />
														</TableCell>
														<TableCell align="center">
															<Stack direction="row" spacing={1} alignItems="center" justifyContent="right">
																<Typography>No</Typography>
																<Field
																	component={Switch} name={"pledgedAsCollateral_" + index} id={"pledgedAsCollateral_" + index} onChange={handleOtherAssetts} checked={data.pledgedAsCollateral ? true : false} />
																<Typography>Yes</Typography>
															</Stack>
														</TableCell>
														<TableCell align="right">
															<Button variant="text" startIcon={<RemoveCircleIcon />} onClick={() => removeOtherAsset(index)}></Button>
														</TableCell>
													</TableRow>
												)
											})
										}
										<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
											{/* <TableCell component="th" scope="row">
											Add new asset
										</TableCell> */}
											<TableCell component="th" scope="row" colSpan={3}>
												<Typography align="right">EC ${total}</Typography>
											</TableCell>
											<TableCell align="right" colSpan={4}>
												<Button variant="text" startIcon={<AddCircleIcon />} onClick={() => addOtherAsset()}></Button>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>

						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', {validateForm, setFieldTouched})}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" type="submit" >
								Next
							</Button>
						</Grid>
						{
							props.completed &&
								<Grid item xs={btnColumnSize}>
									<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', {validateForm, setFieldTouched})} >
										Review
									</Button>
								</Grid>
						}
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Assets