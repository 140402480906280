import { Link, useLocation } from "react-router-dom";
import * as React from 'react';
import logo from '../images/logo.png'
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";

import '../styles/Header.css';

const pages = [
    {
        linkName: "Home",
        href: "/"
    },
    {
        linkName: "Services",
        href: "/services"
    },
    {
        linkName: "Member Loan",
        href: "/apply/member-loan"
    },
    {
        linkName: "Membership",
        href: "/apply/membership"
    },
    {
        linkName: "Shares",
        href: "/apply/shares"
    },
    {
        noNav: true,
        href: "/apply/success"
    }
]

const styles = {
    logoContainer: {
        textAlign: { xs: 'center', md: 'left' },
        mb: { xs: '3rem', md: 0 }
    },
    tagLineContainer: {
        mt: 10,
        textAlign: { xs: 'center', md: 'left' }
    }
}

const Header = () => {
    const currentPath = useLocation().pathname;

    return (
        <>
            <Box className="pre-header"></Box>
            <Box className="main-header">
                <Container>
                    <Grid container item>
                        <Grid item xs={12} md={3} sx={styles.logoContainer}>
                            <Link to="/"><img src={logo} alt="logo" className="logo" /></Link>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <nav className="navbar" sx={styles.navBar}>
                                <ul>
                                    {pages.map((page, i) => (
                                        page.noNav ? 
                                            null
                                        :
                                            <li key={i}>
                                                <Link to={page.href} className={currentPath === page.href ? 'active' : ''}>{page.linkName}</Link>
                                            </li>
                                    ))}
                                </ul>
                            </nav>
                        </Grid>
                        <Grid item xs={12} sx={styles.tagLineContainer}><h1 className="tag-line">An Application For Every Service</h1></Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default Header;