import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

import { Alert, AlertTitle, Collapse, Container, Modal, Grid, Typography, Button, CircularProgress } from "@mui/material";

import Stack from '@mui/material/Stack';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import OITextField from './Textfield';
import sanitizeInput from '../scripts/sanitizeInput'
import apiReq from "../scripts/apiReq";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  accessCode: ''
}

// const INITIAL_FORM_STATE = {
//   firstName: 'Asquil',
//   lastName: 'Ferlance',
//   email: 'asquil.ferlance@gmail.com',
//   accessCode: '47587ABBDC'
// }

//Handles Form validation using YUP
const formValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Email address is invalid')
    .required('Required'),
  accessCode: Yup.string()
    .required('Required'),
});


export default function GetInProgressAppForm({ modalState, closeHandler, service }) {
  const [showError, setError] = React.useState({ error: false, msg: '' });
  const [isProcessing, setIsProcessing] = React.useState(false);
  const navigate = useNavigate();

  //Handles the form submission
  const handleSubmit = (values) => {
    setIsProcessing(true)
    
    // retrieve application from API
    apiReq.post(`${service.serviceKey}/view`, values).then(response => {
      if (Object.keys(response).length > 0) {
        navigate(`/apply/${service.slug}`, { state: response });
      } else {
        setError({ error: true, msg: 'We could not find your application. Please verify your access details, or contact us for further assistance.' })
        setIsProcessing(false)
      }
    }).catch(e => {
      console.log("Error getting in progress application from API:\n", e)
      setError({ error: true, msg: 'There was a problem retrieving your application. Please try again, or contact us for further assistance.' })
      setIsProcessing(false)
    })
	};

  return (
    <Formik
      initialValues={INITIAL_FORM_STATE}
      validationSchema={formValidationSchema}
      onSubmit={handleSubmit}
      onReset={closeHandler}
    >
    {({values, isSubmitting, handleBlur, setFieldValue, isValid}) => (
      <Modal open={modalState} disabled={isSubmitting} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Container maxWidth="md">
          <Box sx={style}>
              <Form id="GetInProgressAppForm">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      Continue {service.name || 'In Progress'} Application
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <p>
                      We automatically save application progress allowing applicants to complete it at their leisure. Provide your information below to continue your application.
                    </p>
                    <Collapse in={showError.error} style={{marginTop: '1rem'}}>
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {showError.msg}
                      </Alert>
                    </Collapse>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <OITextField
                      id="firstName"
                      name="firstName"
                      label="First name"
                      onBlur={(_e) => setFieldValue('firstName', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
                      value={values.firstName}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <OITextField
                      id="lastName"
                      name="lastName"
                      label="Last name"
                      onBlur={(_e) => setFieldValue('lastName', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
                      value={values.lastName}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <OITextField
                      id="email"
                      name="email"
                      label="Email address"
                      onBlur={(_e) => setFieldValue('email', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
                      value={values.email}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <OITextField
                      id="accessCode"
                      name="accessCode"
                      label="One-time access code"
                      onBlur={(_e) => setFieldValue('accessCode', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
                      value={values.accessCode}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      <Button 
                        startIcon={isProcessing ? <CircularProgress size="1rem" /> : null}
                        disabled={!isValid || isProcessing}
                        type="submit" size="small" variant="contained" color="primary"
                        >
                        Go to Application
                      </Button>
                      <Button size="small" type="reset" disabled={isProcessing} onClick={() => setError({ error: false, msg: '' })}>Cancel</Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
          </Box>
        </Container>
      </Modal>
    )}
    </Formik>
  );
}
