import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Grid, MenuItem, Select, LinearProgress, FormControl, InputLabel, Alert } from '@mui/material'
import { IDType } from "../../../scripts/enums";
import { useEffect, useState } from "react";
import { membershipInit } from "../../../scripts/initFormData";
import { cloneDeep } from 'lodash'

// set up dropdown list values
let MenuItems = [];
IDType.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})

const Identification = (props) => {
	const [govIDs, setGovIDs] = useState(null);
	const [formSubmitting, setformSubmitting] = useState(false);

	const formValidationSchema = Yup.array(Yup.object().shape({
		type: Yup.string().required("Required").test({
			name: 'duplicateID',
			message: 'Select different types of IDs',
			test: function (value) {
				const idsWithValue = govIDs.filter(govID => parseInt(govID.type) === parseInt(value))
				return idsWithValue.length > 1 ? false : true
			},
		}),
		number: Yup.string().required("Required"),
		issueDate: Yup.date("Invalid").nullable().required("Required").test({
			name: 'issueDate',
			message: 'Invalid',
			test: function (value) {
				let issueTime = new Date(value).getTime();
				let expTime = new Date(this.parent.expiryDate).getTime();

				if(issueTime > new Date().getTime()) return this.createError({ message: 'This date has not come yet' });
				if(issueTime > expTime && expTime > 0) return this.createError({ message: 'This must be before the expiry date' });

				return true
			},
		}),
		expiryDate: Yup.date("Invalid").nullable().required("Required").test({
			name: 'expiryDate',
			message: 'Invalid',
			test: function (value) {
				let issueTime = new Date(this.parent.issueDate).getTime()
				let expTime = new Date(value).getTime();

				if(expTime < new Date().getTime()) return this.createError({ message: 'This ID appears to be expired' });
				if(expTime < issueTime) return this.createError({ message: 'This must be after the issue date' });

				return true
			},
		}),
	}));

	const handleCustomSubmit = async (type, formikProps) => {
		setformSubmitting(true)

		if(type === 'next' || props.completed){
			let validated = await formikProps.validateForm(); //Validate form

			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev({govIDs: govIDs}); //go to previous step
				else props.next({govIDs: govIDs}, type === 'review'); // go to next or review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, false);
				setformSubmitting(false);
			} 
		} else if(type === 'prev') props.prev({govIDs: govIDs});
	}

	// Formik's handleChange only updates top level field names in its `values` object (whether they exist already or not)
	// since our data is nested inside an array, we need our own custom change handler
	const handleCustomChange = (e) => {
		// console.log('manageChange', e);
		
		let tempFields = govIDs;
		//Determine what to update based on field name
		let targetOptions = e.target.id.split("_");
		let	field = targetOptions[0], id = parseInt(targetOptions[1]);
		govIDs[id][field] = e.target.value;
		//Set to state
		setGovIDs(() => [...tempFields]);
	}

	useEffect(() => {
		// sometimes, the array data might be empty, so we deep clone that portion of the overall `init` so the fields can show
		setGovIDs(props.data.length > 0 ? props.data : cloneDeep(membershipInit.govIDs));
		//eslint-disable-next-line
	}, []);

	const btnColumnSize = props.completed ? 4 : 6;

	if(govIDs === null) return <LinearProgress />
	
	return (
		<Formik enableReinitialize={true} validationSchema={formValidationSchema} initialValues={govIDs} >
		{({values, errors, validateForm, setFieldTouched}) => (
			<Form autoComplete="off">
				
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Alert severity="info">
							For security, we ask that you present your picture IDs in person at one of our branches.
						</Alert>
					</Grid>
					<Grid item xs={12}>
					{
						values.length > 0 && 
						values.map((govID, index) => {
							//console.log({touched, errors, values, govID});
							let error = errors[index] !== undefined ? {
								type: errors[index].type !== undefined,
								number: errors[index].number !== undefined,
								issueDate: errors[index].issueDate !== undefined,
								expiryDate: errors[index].expiryDate !== undefined,
							} : false;

							return(
								<Grid container spacing={2} key={index} marginBottom={2}>
									<Grid item xs={12}>
										<h2>ID #{index + 1}</h2>
									</Grid>

									<Grid item xs={12} md={6}>
										<FormControl fullWidth>
											<InputLabel shrink htmlFor={"type_" + index}>ID Type</InputLabel>
											<Field 
												error={error && error.type ? true : false}
												onChange={(e) => handleCustomChange({target:{id: "type_" + index, value: e.target.value}})}
												value={govID.type}
												fullWidth component={Select} label="ID Type" name={"type_" + index} id={"type_" + index}>
												{ MenuItems }
											</Field>
											{ error && error.type && <span className="errMsg">{errors[index].type}</span> }
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<Field 
											error={error && error.number ? true : false}
											value={govID.number}
											fullWidth component={TextField} label="ID Number" name={"number_" + index} id={"number_" + index} onChange={handleCustomChange} />
											{ error && error.number && <span className="errMsg">{errors[index].number}</span> }
									</Grid>
									<Grid item xs={12} md={6}>
										<Field 
											error={error && error.issueDate ? true : false}
											value={govID.issueDate !== null ? govID.issueDate : ''}
											fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Issue Date (dd/mm/yyyy)" type="date" name={"issueDate_" + index} id={"issueDate_" + index} onChange={handleCustomChange} />
											{ error && error.issueDate && <span className="errMsg">{errors[index].issueDate}</span> }
									</Grid>
									<Grid item xs={12} md={6}>
										<Field 
											error={error && error.expiryDate ? true : false}
											value={govID.expiryDate !== null ? govID.expiryDate : ''}
											fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Expiry Date (dd/mm/yyyy)" type="date" name={"expiryDate_" + index} id={"expiryDate_" + index} onChange={handleCustomChange} />
											{ error && error.expiryDate && <span className="errMsg">{errors[index].expiryDate}</span> }
									</Grid>
								</Grid>
							)
						})
					}
					</Grid>
					
					<Grid item xs={btnColumnSize}>
						<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', {validateForm, setFieldTouched})}>
							Previous
						</Button>
					</Grid>
					<Grid item xs={btnColumnSize}>
						<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('next', {validateForm, setFieldTouched})}>
							Next
						</Button>
					</Grid>
					{
						props.completed &&
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', {validateForm, setFieldTouched})} >
								Review
							</Button>
						</Grid>
					}
				</Grid>
			</Form>
		)}
		</Formik>
	);
};

export default Identification