import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Grid, TextField, Switch, Stack, Typography } from '@mui/material'
import { useState } from "react";

const stepTwoValidationSchema = Yup.object({
	guarantorFor: Yup.string().when('isGuarantor', {
		is: true,
		then: Yup.string().required("Required"),
		otherwise: Yup.string()
	}).label("Person garantee for"),
});

const Legal = (props) => {
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});

	const handleSubmit = async (values) => {
		await sleep(100);
		if(!values.isGuarantor) values.guarantorFor = '';
		// console.log({values});
		props.next(values);
	};

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev(values); //go to previous step
				else props.next(values, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true); 
				setformSubmitting(false);
			}
		} else if(type === 'prev') props.prev(values);
	}

	const _handleFieldChange = (event, formikProps) => {
		// run user input through our sanitization logic to safeguard against XSS attacks
		const fieldValue = event.target.value;
		const fieldName = event.target.id;
		const cleanValue = props.sanitizeInput({ fieldValue });

		// console.log({fieldValue, fieldName: event.target})
		// update field with clean value, then run formik's change handler to carry on regular processing (such as validation)
		formikProps.setFieldValue(fieldName, cleanValue.fieldValue).then(resp => formikProps.handleBlur(event));
	}

	const btnColumnSize = props.completed ? 4 : 6;

	return (
		<Formik enableReinitialize={true} validationSchema={stepTwoValidationSchema} initialValues={props.data} onSubmit={handleSubmit} >
			{({ values, handleChange, touched, errors, setFieldValue, handleBlur, validateForm, isSubmitting, setFieldTouched }) => (
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<p>Do you have outstanding judgments?</p>
							<Stack direction="row" spacing={1} alignItems="center">
								<Typography>No</Typography>
								<Field
									component={Switch} onChange={handleChange} name="hasOutstandingJudgments" id="hasOutstandingJudgments" checked={values.hasOutstandingJudgments ? true : false} />
								<Typography>Yes</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<p>Are you a part in a lawsuit?</p>
							<Stack direction="row" spacing={1} alignItems="center">
								<Typography>No</Typography>
								<Field
									component={Switch} onChange={handleChange} name="isPartyInLawsuit" id="isPartyInLawsuit" checked={values.isPartyInLawsuit ? true : false} />
								<Typography>Yes</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<p>Have you had property, furniture, or vehicles foreclosed upon or repossessed?</p>
							<Stack direction="row" spacing={1} alignItems="center">
								<Typography>No</Typography>
								<Field
									component={Switch} onChange={handleChange} name="hadForeclosure" id="hadForeclosure" checked={values.hadForeclosure ? true : false} />
								<Typography>Yes</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<p>Are you a co-maker, co-signer, or guarantor on any loan not listed above?</p>
							<Stack direction="row" spacing={1} alignItems="center">
								<Typography>No</Typography>
								<Field
									component={Switch} onChange={handleChange} name="isGuarantor" id="isGuarantor" checked={values.isGuarantor ? true : false} />
								<Typography>Yes</Typography>
							</Stack>
						</Grid>
						{
							values.isGuarantor &&
							<Grid item xs={12}>
								{/* <p>Name of person</p> */}
								<Field fullWidth
									error={touched.guarantorFor && errors.guarantorFor ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									component={TextField} label="Name of Person" onChange={handleChange} name="guarantorFor" id="guarantorFor" value={values.guarantorFor} />
								<ErrorMessage className="errMsg" component="span" name="guarantorFor" />
							</Grid>
						}
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, {validateForm, setFieldTouched})}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
								Next
							</Button>
						</Grid>
						{
							props.completed &&
							<Grid item xs={btnColumnSize}>
								<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
									Review
								</Button>
							</Grid>
						}
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Legal