// functions that extract data from our enumerations below

/**
 * Gets a key from an enumeration
 * @param {*} arg the value or label used to look for the key
 * @param {*} obj the enumeration (immutable object) to examine
 * @returns `the key`
 */
export const getEnumKey = (arg, obj) => {
    for (const key in obj)
        if (obj[key].label === arg || obj[key].value === arg)
                return key
}

/**
 * Gets a label from an enumeration
 * @param {*} arg the key or value used to look for the label
 * @param {*} obj the enumeration (immutable object) to examine
 * @returns `the label`
 */
export const getEnumLabel = (arg, obj) => {
    for (const key in obj)
        if (key === arg || obj[key].value === arg)
                return obj[key].label
}

/**
 * Gets a value from an enumeration. If arg is `null`, return all values in an array
 * @param {*} arg the key or label used to look for the value
 * @param {*} obj the enumeration (immutable object) to examine
 * @returns `the value`
 */
export const getEnumValue = (arg, obj) => {
    for (const key in obj)
        if (!(obj[key] instanceof Function) && (key === arg || obj[key].label === arg))
            return obj[key].value
}

/**
 * Returns arrays containing the keys, labels, values, and the entries from an enumeration
 * @param {*} obj the enumeration (immutable object) to examine
 * @returns `object of arrays`
 */
export const getEnumEntries = (obj) => {
    const keys = []
    const labels = []
    const values = []
    const entries = []

    Object.entries(obj).forEach(entryArr => {
        if (!(entryArr[1] instanceof Function)) {
            keys.push(entryArr[0])
            labels.push(entryArr[1].label)
            values.push(entryArr[1].value)
            entries.push(entryArr)
        }
    })

    return { keys, labels, values, entries }
}

// immutable (frozen) objects that function as enumerations since JS does not natively support them.

export const MaritalStatus = {
    single: {
        value: 1,
        label: "Single"
    },
    married: {
        value: 2,
        label: "Married"
    },
    divorced: {
        value: 3,
        label: "Divorced"
    },
    widowed: {
        value: 4,
        label: "Windowed"
    },
    separated: {
        value: 5,
        label: "Separated"
    },
    other: {
        value: 99,
        label: "Other"
    },
    getLabel(arg) { return getEnumLabel(arg, this) },
    getKey(arg) { return getEnumKey(arg, this) },
    getValue(arg) { return getEnumValue(arg, this) },
    getEntries() { return getEnumEntries(this) },
}

// does the person own or rent their place of residence, or something else altogether
export const OwnershipStatus = {
    owned: {
        value: 1,
        label: "Owned"
    },
    rented: {
        value: 2,
        label: "Rented"
    },
    other: {
        value: 99,
        label: "Other"
    },
    getLabel(arg) { return getEnumLabel(arg, this) },
    getKey(arg) { return getEnumKey(arg, this) },
    getValue(arg) { return getEnumValue(arg, this) },
    getEntries() { return getEnumEntries(this) },
}

export const IDType = {
    social: {
        value: 1,
        label: "Social Security"
    },
    drivers: {
        value: 2,
        label: "Driver's Licence"
    },
    passport: {
        value: 3,
        label: "Passport"
    },
    national: {
        value: 4,
        label: "National ID"
    },
    getLabel(arg) { return getEnumLabel(arg, this) },
    getKey(arg) { return getEnumKey(arg, this) },
    getValue(arg) { return getEnumValue(arg, this) },
    getEntries() { return getEnumEntries(this) },
}

export const EmploymentType = {
    employed: {
        value: 1,
        label: "Employed"
    },
    selfEmployed: {
        value: 2,
        label: "Self-Employed"
    },
    both: {
        value: 99,
        label: "Both"
    },
    getLabel(arg) { return getEnumLabel(arg, this) },
    getKey(arg) { return getEnumKey(arg, this) },
    getValue(arg) { return getEnumValue(arg, this) },
    getEntries() { return getEnumEntries(this) },
}

export const ReferenceType = {
    nearestRelative: {
        value: 1,
        label: "Nearest Relative (not living with you)"
    },
    nextOfKin: {
        value: 2,
        label: "Next of Kin"
    },
    getLabel(arg) { return getEnumLabel(arg, this) },
    getKey(arg) { return getEnumKey(arg, this) },
    getValue(arg) { return getEnumValue(arg, this) },
    getEntries() { return getEnumEntries(this) },
}

// how account should be designated to beneficiaries
export const DesignationType = {
    pod: {
        value: 1,
        label: "Payable on Death (POD) / Trust Account"
    },
    all: {
        value: 2,
        label: "All Accounts"
    },
    specific: {
        value: 3,
        label: "Specific Accounts"
    },
    getLabel(arg) { return getEnumLabel(arg, this) },
    getKey(arg) { return getEnumKey(arg, this) },
    getValue(arg) { return getEnumValue(arg, this) },
    getEntries() { return getEnumEntries(this) },
}

// how the applicant will pay for their purchased shares
export const SettlementMethod = {
    cash: {
        value: 1,
        label: "Cash"
    },
    cheque: {
        value: 2,
        label: "Cheque"
    },
    savings: {
        value: 3,
        label: "Savings Account"
    },
    other: {
        value: 99,
        label: "Other"
    },
    getLabel(arg) { return getEnumLabel(arg, this) },
    getKey(arg) { return getEnumKey(arg, this) },
    getValue(arg) { return getEnumValue(arg, this) },
    getEntries() { return getEnumEntries(this) },
}

// freeze the objects so that no on-the-fly updates can be made
Object.freeze(MaritalStatus)
Object.freeze(OwnershipStatus)
Object.freeze(IDType)
Object.freeze(EmploymentType)
Object.freeze(ReferenceType)
Object.freeze(DesignationType)
Object.freeze(SettlementMethod)