import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
//eslint-disable-next-line
import { Select, MenuItem, TextField, Button, Grid, FormControl, InputLabel } from '@mui/material'
import { OwnershipStatus } from "../../../scripts/enums";
import { useState } from "react";

const stepTwoValidationSchema = Yup.object({
	street: Yup.string().required("Required").label("Street"),
	city: Yup.string().required("Required").label("City"),
	state: Yup.string().required("Required").label("State"),
	zip: Yup.string().required("Required").label("Zip"),
	time: Yup.string().required("Required").label("Years at residence"),
	ownership: Yup.string().required("Required").label("Ownership status"),
	// island: Yup.string().required().label("Island"),
});

// const OwnershipStatusOptions = Object.keys(OwnershipStatus);
let MenuItems = [];//<MenuItem key="28" value="">Choose status</MenuItem>
OwnershipStatus.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})
// for (let index = 0; index < OwnershipStatusOptions.length; index++) {
// 	let label = OwnershipStatusOptions[index].charAt(0).toUpperCase() + OwnershipStatusOptions[index].slice(1);
// 	MenuItems.push(
// 		<MenuItem key={index} value={OwnershipStatus[OwnershipStatusOptions[index]]}>{label}</MenuItem>
// 	)
// }

const Address = (props) => {
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});
	
	const handleSubmit = async (values) => {
		await sleep(100);
		props.next({ physicalAddress: values });
	};

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if (props.completed) {
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0) {
				if (type === 'prev') props.prev({ physicalAddress: values }); //go to previous step
				else props.next({ physicalAddress: values }, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for (const field in validated) formikProps.setFieldTouched(field, true, true); 
				setformSubmitting(false);
			}
		} else if (type === 'prev') props.prev({ physicalAddress: values });
	}

	const btnColumnSize = props.completed ? 4 : 6;

	const _handleFieldChange = (event, formikProps) => {
		// run user input through our sanitization logic to safeguard against XSS attacks
		const fieldValue = event.target.value;
		const fieldName = event.target.id;
		const cleanValue = props.sanitizeInput({ fieldValue });

		// console.log({fieldValue, fieldName: event.target})
		// update field with clean value, then run formik's change handler to carry on regular processing (such as validation)
		formikProps.setFieldValue(fieldName, cleanValue.fieldValue).then(resp => formikProps.handleBlur(event));
	}

	return (
		<Formik enableReinitialize={true} validationSchema={stepTwoValidationSchema} initialValues={props.data} onSubmit={handleSubmit} >
			{({ values, handleChange, handleBlur, touched, errors, setFieldValue, validateForm, setFieldTouched, isSubmitting }) => (
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Field onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
								error={touched.street && errors.street ? true : false}
								fullWidth component={TextField} name="street" id="street" label="Street Address" onChange={handleChange} value={values.street} />
							<ErrorMessage className="errMsg" component="span" name="street" />
						</Grid>

						<Grid item xs={12} md={4}>
							<Field onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
								error={touched.city && errors.city ? true : false}
								fullWidth component={TextField} name="city" id="city" label="City" onChange={handleChange} value={values.city} />
							<ErrorMessage className="errMsg" component="span" name="city" />
						</Grid>

						<Grid item xs={12} md={4}>
							<Field onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
								error={touched.state && errors.state ? true : false}
								fullWidth component={TextField} name="state" id="state" label="State" onChange={handleChange} value={values.state} />
							<ErrorMessage className="errMsg" component="span" name="state" />
						</Grid>
						<Grid item xs={12} md={4}>
							<Field onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
								error={touched.zip && errors.zip ? true : false}
								fullWidth component={TextField} label="Zip" name="zip" id="zip" onChange={handleChange} value={values.zip} />
							<ErrorMessage className="errMsg" component="span" name="zip" />
						</Grid>
						<Grid item xs={6} md={6}>
							{/* <p>Years at residence</p> */}
							<Field
								error={touched.time && errors.time ? true : false}
								fullWidth component={TextField} label="Years at Residence" name="time" id="time" onChange={handleChange} value={values.time} type="number" />
							<ErrorMessage className="errMsg" component="span" name="time" />
						</Grid>
						<Grid item xs={6} md={6}>
							{/* <p>Ownership Status</p> */}
							{/* the reason why handlechange is like this, there is a issue with formik and material ui select. it does provide the name of the target when updating */}
							<FormControl fullWidth>
								<InputLabel shrink htmlFor="maritalStatus">
									Ownership Status
								</InputLabel>
								<Field
									error={touched.ownership && errors.ownership ? true : false}
									fullWidth component={Select} name="ownership" id="ownership" value={values.ownership} onChange={(e) => handleChange({ target: { id: "ownership", name: "ownership", value: e.target.value } })} onBlur={handleBlur} label="Ownership Status" >
									{MenuItems}
								</Field>
							</FormControl>
							<ErrorMessage className="errMsg" component="span" name="ownership" />
						</Grid>

						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, { validateForm, setFieldTouched })}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
								Next
							</Button>
						</Grid>
						{
							props.completed &&
							<Grid item xs={btnColumnSize}>
								<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, { validateForm, setFieldTouched })} >
									Review
								</Button>
							</Grid>
						}
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Address