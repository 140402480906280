import footerLogo from '../images/footer-logo.png'
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";

import '../styles/Footer.css';
import { Link } from 'react-router-dom';

const styles = {
    logoContainer: {
        textAlign: { xs: 'center', md: 'left' },
        mb: { xs: '3rem', md: 0 }
    },
    detailsContainer: {
        margin: '0 auto',
        maxWidth: { xs: '100%', sm: '75%', md: 'unset' }
    }
}

const Footer = () => {
    return (
        <>
            <Box className="main-footer">
                <Container>
                    <Grid container item>
                        <Grid item xs={12} md={3} sx={styles.logoContainer}>
                            <Link to="/"><img src={footerLogo} alt="footer-logo" className="logo" /></Link>
                        </Grid>
                        <Grid item xs={12} md={9} sx={styles.detailsContainer}>
                            <Grid container marginBottom={3}><h3 className="heading">Contact Us</h3></Grid>
                            <Grid container item spacing={{ xs: 3, md: 2 }}>
                                <Grid item xs={12} md={6}>
                                    <p>P.O.Box 713 New Street/Bladen Commercial Development,<br />Basseterre St. Kitts</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p><strong>Email:</strong> info@skcu.com</p>
                                    <p><strong>Phone:</strong> +1 869-465-2272/9014-2571/466-8218</p>
                                    <p><strong>Fax:</strong> (869) 446-8991/465-9296</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item paddingTop={10}>
                        <p className="footnote">© 2022 St. Kitts Credit Union, All Rights Reserved</p>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default Footer;