import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

import { Link, useLocation } from "react-router-dom";

function SubmissionSuccess(){
  const location = useLocation()
  const successMessage = location.state || ""

  return (
    <>
      <CssBaseline />
      <Container>
        <Box>
            <h1 className="page-title">Application successfully submitted!</h1>
            
            {successMessage !== "" && <Typography sx={{ marginBottom: '2rem' }}>{successMessage}</Typography>}

            <Button component={Link} to="/services" variant="contained">Go to Services</Button>
        </Box>
      </Container>
    </>
  );
}

export default SubmissionSuccess;