// import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Grid, LinearProgress, Alert, AlertTitle, Typography, Collapse } from '@mui/material'
import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const Expenses = (props) => {
	const [expensesValue, setExpenses] = useState(null);
	const [total, settotal] = useState(0);
	const [showError, setError] = useState({ error: false, msg: '' });
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});

	const handleSubmit = async (values) => {
		await sleep(100);
		if (!validateOtherExpenses(true)) {
			if (showError.error) setError({ error: false, msg: '' });
			props.next({ expenses: expensesValue });
		} else setformSubmitting(false);
	}

	const handleCustomSubmit = async (type, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if (!validateOtherExpenses(true)) {
					if (showError.error) setError({ error: false, msg: '' });
					if(type === 'prev') props.prev({ expenses: expensesValue }); //go to previous step
					else props.next({ expenses: expensesValue }, true); // go to review step
				} else setformSubmitting(false);
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true); 
				setformSubmitting(false);
			}
		} else if(type === 'prev') props.prev({ expenses: expensesValue });
	}

	function validateOtherExpenses(showAlert = false) {
		let foundEmpty = false;

		//check if any field is empty
		if (expensesValue.length > 0) {
			expensesValue.forEach(liability => {
				if (liability.expenses === '') foundEmpty = true;
				if (liability.particulars === '') foundEmpty = true;
				if (liability.amount < 1) foundEmpty = true;
			})
		}

		if (foundEmpty && showAlert) setError({ error: true, msg: 'Please make sure all fields populated before proceeding' });
		return foundEmpty;
	}

	function addOtherExpense() {
		// let foundEmpty = validateOtherExpenses();

		// if (!foundEmpty) {
		//set new blank data into otherIncome array field
		let tempExpense = expensesValue;
		tempExpense.push({ expense: '', particulars: '', amount: 0 });
		setExpenses(() => [...tempExpense]);
		// setshowNewField(true);
		// } else alert('Please make sure all fields populated before adding another expense');
	}

	function removeOtherExpense(index) {
		let tempExpense = expensesValue;
		tempExpense = tempExpense.filter((liability, i) => {
			return index !== i && liability
		});

		setExpenses(() => tempExpense)
	}

	//Formik only updates the initialvalue from the field name and the global cache isnt set up with all fields on first level
	function handleOtherExpense(e) {
		let tempFields = expensesValue;

		let targetOptions = e.target.id.split("_");
		let field = targetOptions[0], id = parseInt(targetOptions[1]);
		tempFields[id][field] = props.sanitizeInput({ fieldValue: e.target.value }).fieldValue;

		setExpenses(() => [...tempFields]);
	}

	//everytime the data change from the props is changes the state
	useEffect(() => {
		console.log('setting fieldval 1st', props.data);
		if(props.data.length === 0) props.data.push({ expense: '', particulars: '', amount: 0 });
		setExpenses(props.data);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (expensesValue !== null && expensesValue.length > 0) {
			let total = 0;
			expensesValue.forEach(income => {
				if (parseFloat(income.amount) > 0) total += parseFloat(income.amount);
			});

			settotal(total.toFixed(2));
		} else settotal(0.00);
	}, [expensesValue]);

	const btnColumnSize = props.completed ? 4 : 6;

	if (expensesValue === null) return <LinearProgress />
	// return <Nav
	return (
		<Formik enableReinitialize={true} initialValues={expensesValue} onSubmit={handleSubmit} >
			{({ values, handleChange, touched, errors, validateForm, isSubmitting, setFieldTouched }) => (
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Collapse in={showError.error}>
								<Alert severity="error" >
									<AlertTitle>Error</AlertTitle>
									{showError.msg}
								</Alert>
							</Collapse>
						</Grid>
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Expense</TableCell>
											<TableCell align="right">Particulars</TableCell>
											<TableCell align="right">Amount (EC $)</TableCell>
											<TableCell align="right">Monthly Payment</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											values.length > 0 &&
											values.map((data, index) => {
												return (
													<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
														<TableCell component="th" scope="row">
															<Field value={data.expense} onChange={handleOtherExpense} fullWidth component={TextField} name={"expense_" + index} id={"expense_" + index} />
														</TableCell>
														<TableCell align="right">
															<Field value={data.particulars} onChange={handleOtherExpense} fullWidth component={TextField} name={"particulars_" + index} id={"particulars_" + index} />
														</TableCell>
														<TableCell align="right">
															<Field value={data.amount} onChange={handleOtherExpense} fullWidth component={TextField} type="number" name={"amount_" + index} id={"amount_" + index} />
														</TableCell>
														<TableCell align="right">
															<Button variant="text" startIcon={<RemoveCircleIcon />} onClick={() => removeOtherExpense(index)}></Button>
														</TableCell>
													</TableRow>
												)
											})
										}
										<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
											{/* <TableCell component="th" scope="row">
												Add new expense
											</TableCell> */}
											<TableCell component="th" scope="row" colSpan={3}>
												<Typography align="right">EC ${total}</Typography>
											</TableCell>
											<TableCell align="right" colSpan={4}>
												<Button variant="text" startIcon={<AddCircleIcon />} onClick={() => addOtherExpense()}></Button>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', {validateForm, setFieldTouched})}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" type="submit" >
								Next
							</Button>
						</Grid>
						{
							props.completed &&
								<Grid item xs={btnColumnSize}>
									<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', {validateForm, setFieldTouched})} >
										Review
									</Button>
								</Grid>
						}
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Expenses