// import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Grid, MenuItem, Select, LinearProgress, FormControl, InputLabel } from '@mui/material'
import { IDType } from "../../../scripts/enums";
import { useEffect, useState } from "react";

const validation = values => {
	let errors = [];
	const nowTime = new Date().getTime();

	for (let index = 0; index <= 1; index++) {
		let temp = {};

		const issueFieldValue = values[index].issueDate;
		const numberFieldValue = values[index].number;
		const typeFieldValue = values[index].type;
		const expFieldValue = values[index].expiryDate;

		if(numberFieldValue === '') temp.number = 'Required';
		if(typeFieldValue === '') temp.type = 'Required';
		else{
			//check for duplicate id type
			// console.log({errors});
			let typeToCheck = index === 1 ? 0 : 1;
			if(values.length > 1) if(parseInt(typeFieldValue) === parseInt(values[typeToCheck].type)) temp.type = 'No duplicate ID type allowed';
		}
		
		if(issueFieldValue === null){
			temp.issueDate = 'Required';
		} else{
			let issueTime = new Date(issueFieldValue).getTime();
			let expTime = new Date(expFieldValue).getTime();
			// console.log({issueTime, expTime})
			if(issueTime > nowTime) temp.issueDate = 'Issue date can\'t be a future date';
			if(issueTime > expTime && expTime > 0) temp.issueDate = 'Issue date can\'t be a past the expiry date';
		}

		if(expFieldValue === null){
			temp.expiryDate = 'Required';
		} else{
			let issueTime = new Date(issueFieldValue).getTime();
			let expTime = new Date(expFieldValue).getTime();
			if(expTime < nowTime) temp.expiryDate = 'Expiry date can\'t be a past date';
			if(expTime < issueTime) temp.expiryDate = 'Expiry date can\'t be earlier than the issue date';
		}

		//i
		//if any field was invalid add to the error array
		errors.push(temp);
		
		// 
	}

	//this is because there are empty objects in the errors array to determine exactly which field is invalid
	//and if both are empty (all fields validated) it would still return populated error array
	if(errors.length === 2){
		let empty = 0;
		errors.forEach(error => { if(Object.keys(error).length === 0) empty++ });
		if(empty === 2) errors = [];
	}

	// console.log('validated', errors)
	return errors;
};

// const IDTypeOptions = Object.keys(IDType);
let MenuItems = [];//<MenuItem key="28" value="">Choose status</MenuItem>
IDType.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})
// for (let index = 0; index < IDTypeOptions.length; index++) {
// 	let label = IDTypeOptions[index].charAt(0).toUpperCase() + IDTypeOptions[index].slice(1);
//     MenuItems.push(
//         <MenuItem key={index} value={IDType[IDTypeOptions[index]]}>{label}</MenuItem>
//     )
// }

const Identification = (props) => {
	const [govIDs, setGovIDs] = useState(null);
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});
	
	const handleSubmit = async (values) => {
		await sleep(100);
		props.next({govIDs: govIDs});
	};

	const handleCustomSubmit = async (type, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev({govIDs: govIDs}); //go to previous step
				else props.next({govIDs: govIDs}, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true); 
				setformSubmitting(false);
			}
		} else if(type === 'prev') props.prev({govIDs: govIDs});
	}

	const handleChange = (e) => {
		// console.log('manageChange', e);
		let tempFields = govIDs;
		//Determine what to update based on field name
		let targetOptions = e.target.id.split("_");
		let	field = targetOptions[0], id = parseInt(targetOptions[1]);
			tempFields[id][field] = e.target.value;
		//Set to state
		setGovIDs(() => [...tempFields]);
	}

	//everytime the data change from the props is changes the state
	useEffect(() => {
		let defaultData = props.data.length > 0 ? props.data : 
		[
			{
				type: IDType.national.value,
				number: '',
				issueDate: null,
				expiryDate: null
			},
			{
				type: IDType.passport.value,
				number: '',
				issueDate: null,
				expiryDate: null
			},
		];
		setGovIDs(defaultData);
		//eslint-disable-next-line
	}, []);
	const btnColumnSize = props.completed ? 4 : 6;

	if(govIDs === null) return <LinearProgress />
	
	return (
		<Formik enableReinitialize={true} validate={validation} initialValues={govIDs} onSubmit={handleSubmit} >
		{({values, touched, errors, validateForm, setFieldTouched, isSubmitting}) => (
			<Form autoComplete="off">
				
				<Grid container spacing={2}>
					<Grid item xs={12}>
					{
						values.length > 0 && 
						values.map((govID, index) => {
							//console.log({touched, errors, values, govID});
							let error = errors[index] !== undefined ? {
								type: errors[index].type !== undefined ? true : false,
								number: errors[index].number !== undefined ? true : false,
								issueDate: errors[index].issueDate !== undefined ? true : false,
								expiryDate: errors[index].expiryDate !== undefined ? true : false,
							} : false;
							let z = index + 1;
							return(
								<Grid container spacing={2} key={index} marginBottom={2}>
									<Grid item xs={12}>
										<p><b>ID #{z}</b></p>
									</Grid>
									<Grid item xs={12} md={6}>
										{/* <p>ID Type</p> */}
										<FormControl fullWidth>
											<InputLabel shrink htmlFor={"type_" + index}>ID Type</InputLabel>
											<Field label="ID Type" error={error && error.type ? true : false} value={govID.type}
												fullWidth component={Select} onChange={(e) => handleChange({target:{id: "type_" + index, value: e.target.value}})}
												id={"type_" + index} name={"type_" + index} >
												{ MenuItems }
											</Field>
											{  error && error.type && <span className="errMsg">{errors[index].type}</span> }
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										{/* <p>ID Number</p> */}
										<Field 
											error={error && error.number ? true : false}
											value={govID.number}
											fullWidth component={TextField} label="ID Number" type="number" name={"number_" + index} id={"number_" + index} onChange={handleChange} />
											{ error && error.number && <span className="errMsg">{errors[index].number}</span> }
									</Grid>
									<Grid item xs={12} md={6}>
										{/* <p>Issue Date</p> */}
										<Field 
											error={error && error.issueDate ? true : false}
											value={govID.issueDate !== null ? govID.issueDate : ''}
											fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Issue Date" type="date" name={"issueDate_" + index} id={"issueDate_" + index} onChange={handleChange} />
											{/* <ErrorMessage className="errMsg" component="span" name={"issueDate_" + index} /> */}
											{ error && error.issueDate && <span className="errMsg">{errors[index].issueDate}</span> }
									</Grid>
									<Grid item xs={12} md={6}>
										{/* <p>Expiry Date</p> */}
										
										<Field 
											error={error && error.expiryDate ? true : false}
											value={govID.expiryDate !== null ? govID.expiryDate : ''}
											fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Expiry Date" type="date" name={"expiryDate_" + index} id={"expiryDate_" + index} onChange={handleChange} />
											{ error && error.expiryDate && <span className="errMsg">{errors[index].expiryDate}</span> }
									</Grid>
								</Grid>
							)
							
						})
						
					}
					</Grid>
					
					<Grid item xs={btnColumnSize}>
						<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', {validateForm, setFieldTouched})}>
							Previous
						</Button>
					</Grid>
					<Grid item xs={btnColumnSize}>
						<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
							Next
						</Button>
					</Grid>
					{
						props.completed &&
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', {validateForm, setFieldTouched})} >
								Review
							</Button>
						</Grid>
					}
				</Grid>
			</Form>
		)}
		</Formik>
	);
};



export default Identification