import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from 'react-router-dom';

const Home = () => {
	return (
		<>
			<CssBaseline />
			<Container>
				<Box>
					<h1 className="page-title">Welcome to the SKCU OAP</h1>
					<Stack direction="row">
						<Button component={Link} to="/apply/success" state={`Custom success message here`} variant="contained">
							Submission Confirmation page
						</Button>
					</Stack>
				</Box>
			</Container>
		</>
	);
}

export default Home;