import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
//eslint-disable-next-line
import { TextField, Button, Grid, MenuItem, Select, LinearProgress, FormControl, InputLabel } from '@mui/material'
import { EmploymentType } from "../../../scripts/enums";
import { useEffect, useState } from "react";
// calculate 18 years old date
let now = new Date();
let minDate = now.setFullYear(now.getFullYear() - 60);

const stepTwoValidationSchema = Yup.object({
	employerAddress: Yup.string().required("Required").label("Employer address"),
	businessType: Yup.string().required("Required").label("Business type"),
	nowJobTitle: Yup.string().required("Required").label("Current job title"),
	nowEmployer: Yup.string().required("Required").label("Current employer"),
	timeAtEmployer: Yup.string().required("Required").label("Years at employer"),
	supervisor: Yup.string().required("Required").label("Supervisor Name"),
	prevEmployer: Yup.string().when('timeAtEmployer', {
		is: data => data < 5,
		then: Yup.string().required("Required"),
		otherwise: Yup.string()
	}).label("Previous employer"),
	address: Yup.string().when('timeAtEmployer', {
		is: data => data < 5,
		then: Yup.string().required("Required"),
		otherwise: Yup.string()
	}).label("Previous employment address"),
	startDate: Yup.date().when('timeAtEmployer', {
		is: data => data < 5,
		then: Yup.date().min(new Date(minDate)).max(new Date(), 'Date can\'t be in the future').required("Invalid")
			.test({
				name: 'max',
				exclusive: false,
				params: {},
				message: 'Invalid',
				test: function (value) {
					// You can access the price field with `this.parent`.
					// let dateCompare = dates.compare(a,b);
					if (value > new Date()) return false
					return value < this.parent.endDate
				},
			}),
		otherwise: Yup.date()
	}).label("Start date"),
	endDate: Yup.date().when('timeAtEmployer', {
		is: data => data < 5,
		then: Yup.date().min(new Date(minDate)).required("Invalid")
			.test({
				name: 'max',
				exclusive: false,
				params: {},
				message: 'Invalid',
				test: function (value) {
					// You can access the price field with `this.parent`.
					if (value > new Date()) return false
					return value > this.parent.startDate
				},
			}),
		otherwise: Yup.date()
	}).label("End date"),
	prevJobTitle: Yup.string().when('timeAtEmployer', {
		is: data => data < 5,
		then: Yup.string().required("Required"),
		otherwise: Yup.string()
	}).label("Previous job title")
});

const Employment = (props) => {
	const [fieldValues, setfieldValues] = useState(null);
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});

	function setExportData(values) {
		let { employmentType, businessType, employerAddress, nowJobTitle, nowEmployer, timeAtEmployer, supervisor } = values;
		let { address, startDate, endDate, prevJobTitle, prevEmployer } = values;
		let dataToSend = {
			currentEmployment: { employmentType, businessType, employer: nowEmployer, employerAddress, jobTitle: nowJobTitle, timeAtEmployer, supervisor },
			previousEmployment: { employer: timeAtEmployer < 5 ? prevEmployer : '', address: timeAtEmployer < 5 ? address : '', startDate: timeAtEmployer < 5 ? startDate : null, endDate: timeAtEmployer < 5 ? endDate : null, jobTitle: timeAtEmployer < 5 ? prevJobTitle : '' }
		}

		return dataToSend
	}

	const handleSubmit = async (values) => {
		await sleep(100);
		// Rearrange data
		let dataToSend = setExportData(values);

		// console.log({ values, completed })
		props.next(dataToSend);
	};

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				let dataToSend = setExportData(values);
				if(type === 'prev') props.prev(dataToSend); //go to previous step
				else props.next(dataToSend, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true); 
				setformSubmitting(false);
			}
		} else if(type === 'prev') props.prev(setExportData(values));
	}

	const _handleFieldChange = (event, formikProps) => {
		// run user input through our sanitization logic to safeguard against XSS attacks
		const fieldValue = event.target.value;
		const fieldName = event.target.id;
		const cleanValue = props.sanitizeInput({ fieldValue });

		// console.log({fieldValue, fieldName: event.target})
		// update field with clean value, then run formik's change handler to carry on regular processing (such as validation)
		formikProps.setFieldValue(fieldName, cleanValue.fieldValue).then(resp => formikProps.handleBlur(event));
	}

	//everytime the data change from the props is changes the state
	useEffect(() => {
		const { currentEmployment, previousEmployment } = props.data;
		setfieldValues({
			...currentEmployment,
			...previousEmployment,
			nowJobTitle: currentEmployment.jobTitle,
			nowEmployer: currentEmployment.employer,
			prevJobTitle: previousEmployment.jobTitle,
			prevEmployer: previousEmployment.employer,
			startDate: previousEmployment.startDate !== null ? previousEmployment.startDate : '',
			endDate: previousEmployment.endDate !== null ? previousEmployment.endDate : ''
		});
		// console.log(new Date(previousEmployment.startDate), new Date(null))
		//eslint-disable-next-line
	}, []);

	const btnColumnSize = props.completed ? 4 : 6;

	// const EmploymentTypeOptions = Object.keys(EmploymentType);
	let MenuItems = [];//<MenuItem key="28" value="">Choose status</MenuItem>
	EmploymentType.getEntries().entries.forEach((entry, index) => {
		MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
	})
	// for (let index = 0; index < EmploymentTypeOptions.length; index++) {
	// 	let label = EmploymentTypeOptions[index].charAt(0).toUpperCase() + EmploymentTypeOptions[index].slice(1);
	// 	let rawName = label.match(/[A-Z][a-z]+/g);
	// 	let getLabelName = (rawName) => {
	// 		let name = '';
	// 		rawName.forEach(z => {
	// 			name += z + ' ';
	// 		});
	// 		return name
	// 	}
	// 	MenuItems.push(
	// 		<MenuItem key={index} value={EmploymentType[EmploymentTypeOptions[index]]}>{getLabelName(rawName)}</MenuItem>
	// 	)
	// }

	//Show loading progress if data isnt on state yet
	if (fieldValues === null) return <LinearProgress />
	return (
		<Formik enableReinitialize={true} validationSchema={stepTwoValidationSchema} initialValues={fieldValues} onSubmit={handleSubmit} >
			{({ values, handleChange, handleBlur, touched, errors, setFieldValue, isSubmitting, validateForm, setFieldTouched }) =>
			(
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<FormControl fullWidth>
								<InputLabel shrink htmlFor="employmentType">Employment Type</InputLabel>
								<Field error={errors && errors.employmentType ? true : false} value={values.employmentType} onChange={(e) => handleChange({ target: { id: "employmentType", name: "employmentType", value: e.target.value } })}
									fullWidth component={Select} id="employmentType"
									name="employmentType" label="Employment Type" >
									{MenuItems}
								</Field>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={6}>
							<Field error={errors && errors.businessType ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })} value={values.businessType} label="Business Type" onChange={handleChange} fullWidth component={TextField} name="businessType" id="businessType" />
							<ErrorMessage className="errMsg" component="span" name="businessType" />
						</Grid>
						<Grid item xs={12} md={6}>
							<Field error={errors && errors.nowEmployer ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })} value={values.nowEmployer} label="Employer" onChange={handleChange} fullWidth component={TextField} name="nowEmployer" id="nowEmployer" />
							<ErrorMessage className="errMsg" component="span" name="nowEmployer" />
						</Grid>
						<Grid item xs={12} md={6}>
							<Field error={errors && errors.employerAddress ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })} value={values.employerAddress} label="Employer Address" onChange={handleChange} fullWidth component={TextField} name="employerAddress" id="employerAddress" />
							<ErrorMessage className="errMsg" component="span" name="employerAddress" />
						</Grid>
						<Grid item xs={12} md={6}>
							<Field error={errors && errors.nowJobTitle ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })} value={values.nowJobTitle} label="Job Title / Grade" onChange={handleChange} fullWidth component={TextField} name="nowJobTitle" id="nowJobTitle" />
							<ErrorMessage className="errMsg" component="span" name="nowJobTitle" />
						</Grid>
						<Grid item xs={12} md={6}>
							<Field error={errors && errors.timeAtEmployer ? true : false} value={values.timeAtEmployer} label="Years at Employer" type="number" onChange={handleChange} fullWidth component={TextField} name="timeAtEmployer" id="timeAtEmployer" />
							<ErrorMessage className="errMsg" component="span" name="timeAtEmployer" />
						</Grid>
						<Grid item xs={12}>
							<Field error={errors && errors.supervisor ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })} value={values.supervisor} label="Supervisor's Name" onChange={handleChange} fullWidth component={TextField} name="supervisor" id="supervisor" />
							<ErrorMessage className="errMsg" component="span" name="supervisor" />
						</Grid>
						{
							values.timeAtEmployer < 5 &&
							<>
								<Grid item xs={12}>
									<p><b>Previous employer details</b></p>
								</Grid>
								<Grid item xs={12}>
									<Field error={errors && errors.prevEmployer ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })} value={values.prevEmployer} label="Employer" onChange={handleChange} fullWidth component={TextField} name="prevEmployer" id="prevEmployer" />
									<ErrorMessage className="errMsg" component="span" name="prevEmployer" />
								</Grid>
								<Grid item xs={12} md={6}>
									<Field error={errors && errors.address ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })} value={values.address} label="Address" onChange={handleChange} fullWidth component={TextField} name="address" id="address" />
									<ErrorMessage className="errMsg" component="span" name="address" />
								</Grid>
								<Grid item xs={12} md={6}>
									<Field error={errors && errors.prevJobTitle ? true : false} onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })} value={values.prevJobTitle} label="Job Title / Grade" onChange={handleChange} fullWidth component={TextField} name="prevJobTitle" id="prevJobTitle" />
									<ErrorMessage className="errMsg" component="span" name="prevJobTitle" />
								</Grid>
								<Grid item xs={12} md={6}>
									<Field error={errors && errors.startDate ? true : false}
										fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Start Date" type="date" name="startDate" id="startDate" onChange={handleChange} value={values.startDate !== null ? values.startDate : ''} />
									<ErrorMessage className="errMsg" component="span" name="startDate" />
								</Grid>
								<Grid item xs={12} md={6}>
									<Field error={errors && errors.endDate ? true : false}
										fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="End Date" type="date" name="endDate" id="endDate" onChange={handleChange} value={values.endDate !== null ? values.endDate : ''} />
									<ErrorMessage className="errMsg" component="span" name="endDate" />
								</Grid>
							</>
						}
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, {validateForm, setFieldTouched})}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
								Next
							</Button>
						</Grid>
						{
							props.completed &&
							<Grid item xs={btnColumnSize}>
								<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
									Review
								</Button>
							</Grid>
						}

					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Employment