import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {  MenuItem, TextField, Button, Grid, Alert } from '@mui/material'
import { OwnershipStatus } from "../../../scripts/enums";
import { useState } from "react";

const formValidationSchema = Yup.object({
	street: Yup.string().required("Required"),
	city: Yup.string().required("Required"),
	state: Yup.string().required("Required"),
	zip: Yup.string().required("Required")
});

// set up dropdown list values
let MenuItems = [];
OwnershipStatus.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})

const Address = (props) => {
	const { sanitizeInput } = props;
	const [formSubmitting, setformSubmitting] = useState(false);

	const handleCustomSubmit = async (type, values, formikProps) => {
		setformSubmitting(true)

		if(type === 'next' || props.completed){
			let validated = await formikProps.validateForm(); //Validate form

			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev({ physicalAddress: values }); //go to previous step
				else props.next({ physicalAddress: values }, type === 'review'); // go to next or review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, false);
				setformSubmitting(false);
			} 
		} else if(type === 'prev') props.prev({ physicalAddress: values });
	}

	const btnColumnSize = props.completed ? 4 : 6;

	return (
		<Formik enableReinitialize={true} validationSchema={formValidationSchema} initialValues={props.data} >
			{({ values, handleChange, handleBlur, touched, errors, setFieldValue, validateForm, setFieldTouched }) => (
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Alert severity="info" sx={{ mb: 2 }}>
								You are required to provide proof of address in the form of a utility bill, tenant lease agreement, or any other valid document. Note the following:
								<ol type="i" style={{ marginTop: '0.625rem' }}>
									<li style={{ marginBottom: '0.625rem' }}>Your utility bill must be <strong>no older than 90 days</strong>, and may be your electricity, cable, home line, etc. bill.</li>
									<li>You may opt to provide a letter from the individual with whom you live that confirms your living arrangements. The individual writing this letter must also provide a copy of a valid ID.</li>
								</ol>
							</Alert>
						</Grid>
						<Grid item xs={12}>
							<Field
								error={touched.street && errors.street ? true : false}
								onBlur={(_e) => setFieldValue('street', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
								fullWidth component={TextField} name="street" id="street" label="Street Address" onChange={handleChange} value={values.street} />
							<ErrorMessage className="errMsg" component="span" name="street" />
						</Grid>

						<Grid item xs={12} md={4}>
							<Field
								error={touched.city && errors.city ? true : false}
								onBlur={(_e) => setFieldValue('city', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
								fullWidth component={TextField} name="city" id="city" label="City" onChange={handleChange} value={values.city} />
							<ErrorMessage className="errMsg" component="span" name="city" />
						</Grid>

						<Grid item xs={12} md={4}>
							<Field
								error={touched.state && errors.state ? true : false}
								onBlur={(_e) => setFieldValue('state', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
								fullWidth component={TextField} name="state" id="state" label="State" onChange={handleChange} value={values.state} />
							<ErrorMessage className="errMsg" component="span" name="state" />
						</Grid>
						<Grid item xs={12} md={4}>
							<Field
								error={touched.zip && errors.zip ? true : false}
								onBlur={(_e) => setFieldValue('zip', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
								fullWidth component={TextField} label="Zip" name="zip" id="zip" onChange={handleChange} value={values.zip} />
							<ErrorMessage className="errMsg" component="span" name="zip" />
						</Grid>

						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, { validateForm, setFieldTouched })}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('next', values, { validateForm, setFieldTouched })}>
								Next
							</Button>
						</Grid>
						{
							props.completed &&
							<Grid item xs={btnColumnSize}>
								<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, { validateForm, setFieldTouched })} >
									Review
								</Button>
							</Grid>
						}
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Address