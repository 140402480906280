import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Select, MenuItem, TextField, Button, Grid, InputLabel, FormControl, Stack, Typography, Switch, IconButton, LinearProgress, Tooltip } from '@mui/material'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { IDType, MaritalStatus } from "../../../scripts/enums";
import { useEffect, useState } from "react";
import { membershipInit } from "../../../scripts/initFormData";
import { cloneDeep } from 'lodash';

// calculate 18 years old date
let now = new Date();
let minAge = now.setFullYear(now.getFullYear() - 18);

// set up dropdown list values
let MenuItems = [];
MaritalStatus.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})

let MenuItemsIds = [];
IDType.getEntries().entries.forEach((entry, index) => {
	MenuItemsIds.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})

// need an easy reference to a single joint owner object from the main init data object
// deep clone it to avoid reference issues where necessary
const initJointOwner = membershipInit.jointOwners[0]

// nested objects in the joint owner array need special handling when updating the corresponding field values; these arrays aid that
const physicalAddressFields = Object.keys(initJointOwner.physicalAddress)
const phoneFields = Object.keys(initJointOwner.phone)

const Ownership = (props) => {
	const { sanitizeInput } = props;
	const [fieldValues, setfieldValues] = useState(null);
	const [formErrors, setFormErrors] = useState([]);
	const [formSubmitting, setformSubmitting] = useState(false);

	const jointOwnersValidation = Yup.array(Yup.object().shape({
		firstName: Yup.string().required("Required"),
		lastName: Yup.string().required("Required"),
		email: Yup.string().required("Required").email("Invalid"),
		dob: Yup.date("Invalid").max(new Date(minAge), 'Like you, joint owners must be 18 years or older').nullable().required("Required"),
		maritalStatus: Yup.string().required("Required"),
		motherMaidenName: Yup.string().required("Required"),
		physicalAddress: Yup.object({
			street: Yup.string().required("Required"),
			city: Yup.string().required("Required"),
			state: Yup.string().required("Required"),
			zip: Yup.string().required("Required")
		}),
		phone: Yup.object({
			home: Yup.string().digitsOnly("Invalid"),
			mobile: Yup.string().required("Required").digitsOnly("Invalid"),
			work: Yup.string().required("Required").digitsOnly("Invalid"),
			other: Yup.string().digitsOnly("Invalid"),
		}),
		govIDs: Yup.array(Yup.object().shape({
			type: Yup.string().required("Required").test({
				name: 'duplicateID',
				message: 'Select different types of IDs',
				test: function (value) {
					// grab the index of the parent array
					const jointOwnerIndex = parseInt(this.options.path.split(".")[0].replace(/\D/g, ''))

					const idsWithValue = fieldValues.jointOwners[jointOwnerIndex].govIDs.filter(govID => parseInt(govID.type) === parseInt(value))
					return idsWithValue.length > 1 ? false : true
				},
			}),
			number: Yup.string().required("Required"),
			issueDate: Yup.date("Invalid").nullable().required("Required").test({
				name: 'issueDate',
				message: 'Invalid',
				test: function (value) {
					let issueTime = new Date(value).getTime();
					let expTime = new Date(this.parent.expiryDate).getTime();

					if(issueTime > new Date().getTime()) return this.createError({ message: 'This date has not come yet' });
					if(issueTime > expTime && expTime > 0) return this.createError({ message: 'This must be before the expiry date' });

					return true
				},
			}),
			expiryDate: Yup.date("Invalid").nullable().required("Required").test({
				name: 'expiryDate',
				message: 'Invalid',
				test: function (value) {
					let issueTime = new Date(this.parent.issueDate).getTime()
					let expTime = new Date(value).getTime();

					if(expTime < new Date().getTime()) return this.createError({ message: 'This ID appears to be expired' });
					if(expTime < issueTime) return this.createError({ message: 'This must be after the issue date' });

					return true
				},
			}),
		}))
	}));

	const handleCustomSubmit = async (type, values, formikProps) => {
		setformSubmitting(true)
		let formValid = true, errors = []

		// validate joint owners fields
		if(!values.isSoleOwner && (type === 'next' || props.completed)){
			errors = await formikProps.validateForm(values.jointOwners)
			formValid = Object.keys(errors).length === 0

			console.log('errors: ', errors)
			console.log('formValid: ', formValid)
		}

		if (formValid) {
			let dataToSend = {
				isSoleOwner: values.isSoleOwner,
				jointOwners: values.isSoleOwner ? [] : values.jointOwners
			}
	
			if(type === 'prev') props.prev(dataToSend);
			else props.next(dataToSend, type === 'review');
		} else {
			setFormErrors(errors)
			setformSubmitting(false);
		}
	}

	function addJointOwner() {
		let temp = fieldValues.jointOwners;
		temp.push(cloneDeep(initJointOwner));
		setfieldValues((prev) => ({ ...prev, jointOwners: temp }));
	}

	function removeJointOwner(index) {
		let temp = fieldValues.jointOwners;
		temp = temp.filter((jointOwner, i) => {
			return index !== i && jointOwner
		});

		setfieldValues((prev) => ({ ...prev, jointOwners: temp }))
	}

	// Formik's handleChange only updates top level field names in its `values` object (whether they exist already or not)
	// since our data is nested inside an array, we need our own custom change handler
	function handleJointOwnerChange(e, doSanitize = false) {
		let tempFields = fieldValues.jointOwners;

		let targetOptions = e.target.id.split("_");
		let field = targetOptions[0], index = parseInt(targetOptions[1]);

		// the handler is called both onChange and onBlur, but only clean the field onBlur
		const newFieldValue = doSanitize ? sanitizeInput(e.target.value) : e.target.value

		if (targetOptions.length === 3) {
			// joint owner IDs have an extra `_index` on the end; for example: number_0_1
			tempFields[index]["govIDs"][targetOptions[2]][field] = newFieldValue;
		} else {
			if (physicalAddressFields.indexOf(field) >= 0)
				tempFields[index]["physicalAddress"][field] = newFieldValue
			else if (phoneFields.indexOf(field) >= 0)
				tempFields[index]["phone"][field] = newFieldValue
			else 
				tempFields[index][field] = newFieldValue;
		}
		
		setfieldValues((prev) => ({ ...prev, jointOwners: tempFields }));
	}

	// using this in place of Formik's handleChange so that our state can be kept in sync when changes are made by beneficiary fields
	function handleCustomChange(e) {
		let thisField = e.target.id
		let fieldValue = e.target.type && e.target.type === 'checkbox' ? e.target.checked : e.target.value

		setfieldValues((prev) => ({ ...prev, [thisField]: fieldValue }))
	}

	// push an empty joint owner object if array empty
	useEffect(() => {
		if(props.data.jointOwners && props.data.jointOwners.length === 0) props.data.jointOwners.push(cloneDeep(initJointOwner));

		setfieldValues(props.data);
		//eslint-disable-next-line
	}, []);

	const btnColumnSize = props.completed ? 4 : 6;

	if (fieldValues === null) return <LinearProgress />
	return (
		<Formik enableReinitialize={true} validationSchema={jointOwnersValidation} initialValues={fieldValues} >
			{
				({ values, validateForm, setFieldTouched }) => (
					<Form autoComplete="off">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<p>Will you be the sole owner of the account?</p>
								<Stack direction="row" spacing={1} alignItems="center">
									<Typography>No</Typography>
									<Field component={Switch} name="isSoleOwner" id="isSoleOwner" onChange={handleCustomChange} checked={values.isSoleOwner ? true : false} />
									<Typography>Yes</Typography>
								</Stack>
							</Grid>
							{
								!values.isSoleOwner &&
								<>
									{
										values.jointOwners.length > 0 &&
										values.jointOwners.map((jointOwner, index) => {
											// define which fields have errors
											let hasErrors = formErrors[index] && Object.keys(formErrors[index]).length > 0

											// nested object errors (govIDs are handled separately below)
											let addressErrors = hasErrors && formErrors[index].physicalAddress ? formErrors[index].physicalAddress : {}
											let phoneErrors = hasErrors && formErrors[index].phone ? formErrors[index].phone : {}

											return (
												<Grid container item spacing={2} key={index} marginBottom={2}>
													<Grid item xs={12}>
														<Stack direction="row" spacing={1} alignItems="center">
															<h2>Joint Owner {values.jointOwners.length > 1 && `#${index + 1}`}</h2>
															{
																values.jointOwners.length > 1 &&
																<Tooltip title="Remove Joint Owner" placement="top">
																	<IconButton aria-label="remove joint owner" component="span" size="large" color="error" onClick={() => removeJointOwner(index)}><GroupRemoveIcon sx={{ fontSize: '1.3rem' }} /></IconButton>
																</Tooltip>
															}
														</Stack>
													</Grid>
													<Grid container item spacing={2} marginBottom={1}>
														<Grid item xs={12} sm={6} md={3}>
															<Field 
																error={hasErrors && formErrors[index].firstName ? true : false}
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.firstName}
																fullWidth size="small" component={TextField} label="First Name" name={"firstName_" + index} id={"firstName_" + index}
															/>
															{ hasErrors && formErrors[index].firstName && <span className="errMsg">{formErrors[index].firstName}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field 
																error={hasErrors && formErrors[index].lastName ? true : false}
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.lastName}
																fullWidth size="small" component={TextField} label="Last Name" name={"lastName_" + index} id={"lastName_" + index}
															/>
															{ hasErrors && formErrors[index].lastName && <span className="errMsg">{formErrors[index].lastName}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field 
																error={hasErrors && formErrors[index].email ? true : false}
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.email}
																fullWidth size="small" component={TextField} label="Email" name={"email_" + index} id={"email_" + index}
															/>
															{ hasErrors && formErrors[index].email && <span className="errMsg">{formErrors[index].email}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && formErrors[index].dob ? true : false}
																onChange={handleJointOwnerChange}
																value={jointOwner.dob !== null ? jointOwner.dob : ''}
																fullWidth size="small" InputLabelProps={{ shrink: true }} component={TextField} label="Date of Birth (dd/mm/yyyy)" type="date" name={"dob_" + index} id={"dob_" + index}
															/>
															{ hasErrors && formErrors[index].dob && <span className="errMsg">{formErrors[index].dob}</span> }
														</Grid>

														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && formErrors[index].motherMaidenName ? true : false}
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.motherMaidenName}
																fullWidth size="small" component={TextField} label="Mother's Maiden Name" name={"motherMaidenName_" + index} id={"motherMaidenName_" + index}
															/>
															{ hasErrors && formErrors[index].motherMaidenName && <span className="errMsg">{formErrors[index].motherMaidenName}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<FormControl fullWidth>
																<InputLabel shrink htmlFor={"maritalStatus_" + index}>Marital Status</InputLabel>
																<Field 
																	onChange={(e) => handleJointOwnerChange({target:{id: "maritalStatus_" + index, value: e.target.value}})}
																	value={jointOwner.maritalStatus}
																	fullWidth size="small" component={Select} label="Marital Status" name={"maritalStatus_" + index} id={"maritalStatus_" + index} >
																	{ MenuItems }
																</Field>
															</FormControl>
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.employer}
																fullWidth size="small" component={TextField} label="Employer" name={"employer_" + index} id={"employer_" + index}
															/>
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.occupation}
																fullWidth size="small" component={TextField} label="Occupation" name={"occupation_" + index} id={"occupation_" + index}
															/>
														</Grid>
													</Grid>

													<Grid container item spacing={2} marginBottom={1}>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && addressErrors.street ? true : false}
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.physicalAddress.street}
																fullWidth size="small" component={TextField} label="Street Address" name={"street_" + index} id={"street_" + index}
															/>
															{ hasErrors && addressErrors.street && <span className="errMsg">{addressErrors.street}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && addressErrors.city ? true : false}
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.physicalAddress.city}
																fullWidth size="small" component={TextField} label="City" name={"city_" + index} id={"city_" + index}
															/>
															{ hasErrors && addressErrors.city && <span className="errMsg">{addressErrors.city}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && addressErrors.state ? true : false}
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.physicalAddress.state}
																fullWidth size="small" component={TextField} label="State" name={"state_" + index} id={"state_" + index}
															/>
															{ hasErrors && addressErrors.state && <span className="errMsg">{addressErrors.state}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && addressErrors.zip ? true : false}
																onBlur={(_e) => handleJointOwnerChange(_e, true)}
																onChange={handleJointOwnerChange}
																value={jointOwner.physicalAddress.zip}
																fullWidth size="small" component={TextField} label="Zip" name={"zip_" + index} id={"zip_" + index}
															/>
															{ hasErrors && addressErrors.zip && <span className="errMsg">{addressErrors.zip}</span> }
														</Grid>
													</Grid>
													
													<Grid container item spacing={2} marginBottom={1}>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && phoneErrors.home ? true : false}
																onChange={handleJointOwnerChange}
																value={jointOwner.phone.home}
																fullWidth size="small" component={TextField} label="Home phone (optional)" name={"home_" + index} id={"home_" + index}
															/>
															{ hasErrors && phoneErrors.home && <span className="errMsg">{phoneErrors.home}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && phoneErrors.mobile ? true : false}
																onChange={handleJointOwnerChange}
																value={jointOwner.phone.mobile}
																fullWidth size="small" component={TextField} label="Mobile phone" name={"mobile_" + index} id={"mobile_" + index}
															/>
															{ hasErrors && phoneErrors.mobile && <span className="errMsg">{phoneErrors.mobile}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && phoneErrors.work ? true : false}
																onChange={handleJointOwnerChange}
																value={jointOwner.phone.work}
																fullWidth size="small" component={TextField} label="Work / Business phone" name={"work_" + index} id={"work_" + index}
															/>
															{ hasErrors && phoneErrors.work && <span className="errMsg">{phoneErrors.work}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={hasErrors && phoneErrors.other ? true : false}
																onChange={handleJointOwnerChange}
																value={jointOwner.phone.other}
																fullWidth size="small" component={TextField} label="Other phone" name={"other_" + index} id={"other_" + index}
															/>
															{ hasErrors && phoneErrors.other && <span className="errMsg">{phoneErrors.other}</span> }
														</Grid>
													</Grid>
													{
														jointOwner.govIDs.length > 0 &&
														jointOwner.govIDs.map((govID, idIndex) => {
															let idErrors = {}
															// test if we have form errors for the IDs section, and specifically the current one in focus
															if (hasErrors && formErrors[index].govIDs && formErrors[index].govIDs[idIndex])
																idErrors = formErrors[index].govIDs[idIndex]

															return (
																<Grid container item spacing={2} key={`${index} - ${idIndex}`} marginBottom={1}>
																	<Grid item xs={12}>
																		<h3>ID #{idIndex + 1}</h3>
																	</Grid>
																	<Grid item xs={12} sm={6} md={3}>
																		<FormControl fullWidth>
																			<InputLabel shrink htmlFor={`type_${index}_${idIndex}`}>ID Type</InputLabel>
																			<Field 
																				error={hasErrors && idErrors.type ? true : false}
																				onChange={(e) => handleJointOwnerChange({target:{id: `type_${index}_${idIndex}`, value: e.target.value}})}
																				value={govID.type}
																				fullWidth size="small" component={Select} label="ID Type" name={`type_${index}_${idIndex}`} id={`type_${index}_${idIndex}`} >
																				{ MenuItemsIds }
																			</Field>
																			{ hasErrors && idErrors.type && <span className="errMsg">{idErrors.type}</span> }
																		</FormControl>
																	</Grid>
																	<Grid item xs={12} sm={6} md={3}>
																		<Field
																			error={hasErrors && idErrors.number ? true : false}
																			onChange={handleJointOwnerChange}
																			value={govID.number}
																			fullWidth size="small" component={TextField} label="ID Number" name={`number_${index}_${idIndex}`} id={`number_${index}_${idIndex}`}
																		/>
																		{ hasErrors && idErrors.number && <span className="errMsg">{idErrors.number}</span> }
																	</Grid>
																	<Grid item xs={12} sm={6} md={3}>
																		<Field
																			error={hasErrors && idErrors.issueDate ? true : false}
																			onChange={handleJointOwnerChange}
																			value={govID.issueDate !== null ? govID.issueDate : ''}
																			fullWidth size="small" InputLabelProps={{ shrink: true }} component={TextField} label="Issue Date (dd/mm/yyyy)" type="date" name={`issueDate_${index}_${idIndex}`} id={`issueDate_${index}_${idIndex}`}
																		/>
																		{ hasErrors && idErrors.issueDate && <span className="errMsg">{idErrors.issueDate}</span> }
																	</Grid>
																	<Grid item xs={12} sm={6} md={3}>
																		<Field
																			error={hasErrors && idErrors.expiryDate ? true : false}
																			onChange={handleJointOwnerChange}
																			value={govID.expiryDate !== null ? govID.expiryDate : ''}
																			fullWidth size="small" InputLabelProps={{ shrink: true }} component={TextField} label="Expiry Date (dd/mm/yyyy)" type="date" name={`expiryDate_${index}_${idIndex}`} id={`expiryDate_${index}_${idIndex}`}
																		/>
																		{ hasErrors && idErrors.expiryDate && <span className="errMsg">{idErrors.expiryDate}</span> }
																	</Grid>
																</Grid>
															)
														})
													}
												</Grid>
											)
										})
									}
									<Grid item xs={12} sx={{ mb: 2 }}>
										<Grid container direction="column" alignItems="center" justifyContent="center">
											<Tooltip title="Add Joint Owner" placement="top">
												<IconButton aria-label="add joint owner" component="span" size="large" color="success" onClick={addJointOwner}><GroupAddIcon sx={{ fontSize: '2.5rem' }} /></IconButton>
											</Tooltip>
										</Grid>
									</Grid>
								</>
							}
							<Grid item xs={btnColumnSize}>
								<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, {validateForm, setFieldTouched})}>
									Previous
								</Button>
							</Grid>
							<Grid item xs={btnColumnSize}>
								<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('next', values, {validateForm, setFieldTouched})}>
									Next
								</Button>
							</Grid>
							{
								props.completed &&
								<Grid item xs={btnColumnSize}>
									<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
										Review
									</Button>
								</Grid>
							}
						</Grid>
					</Form>
				)
			}
		</Formik>
	);
};

export default Ownership