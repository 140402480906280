import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { memberLoanInit, emergencyLoanInit, membershipInit, sharesInit } from './initFormData'

/**
 * A helpful note about the levels of state management in this app:
 * 1. Local state persists until the component is unmounted, for example, if the user navigates away from the page
 * 2. Global state persists until the application resets, for example, if the browser is refreshed or the environment restarts
 * 3. We also have the global state persisting to localStorage so that the state persists across resets and refreshes, but 
 *    the state will disappear if the browser is closed completely
 */

let formDataStore = (set) => ({
	membership: membershipInit,
	memberLoan: memberLoanInit,
	emergencyLoan: emergencyLoanInit,
	shares: sharesInit,
	manageData: (data, service = 'memberLoan') => {
		switch(service) {
			case 'membership':
				return set({ membership: data })
			case 'memberLoan':
				return set({ memberLoan: data })
			case 'shares':
				return set({ shares: data })
			default:
		}
	}
})

formDataStore = devtools(formDataStore);
formDataStore = persist(formDataStore, {name: 'skcu-oap-dataStore'});

export const useDataStore = create(formDataStore)