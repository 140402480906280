//This Application form uses Zustand state management
import { useState, useEffect, useCallback } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
//eslint-disable-next-line
import { Container, Box, Stepper, StepLabel, Step, Typography, Grid, CircularProgress, Card, CardContent, CardActions, Button, CardHeader } from '@mui/material'
import { FormGroup, FormControlLabel, Checkbox, Alert, AlertTitle, Collapse } from '@mui/material'
//eslint-disable-next-line
import { useDataStore } from '../scripts/formStore'
//eslint-disable-next-line
import { memberLoanInit } from "../scripts/initFormData";
import apiReq from '../scripts/apiReq'
import Personal from '../components/forms/MemberLoan/Personal'
import Loan from '../components/forms/MemberLoan/Loan'
import Address from '../components/forms/MemberLoan/Address'
import Contact from '../components/forms/MemberLoan/Contact'
import Identification from '../components/forms/MemberLoan/Identification'
import Employment from '../components/forms/MemberLoan/Employment'
import Income from '../components/forms/MemberLoan/Income'
import Liabilities from '../components/forms/MemberLoan/Liabilities'
import Expenses from '../components/forms/MemberLoan/Expenses'
import Assets from '../components/forms/MemberLoan/Assets'
import Legal from '../components/forms/MemberLoan/Legal'
import References from '../components/forms/MemberLoan/References'
import { useLocation, useNavigate } from 'react-router-dom'
import { MaritalStatus, OwnershipStatus, IDType, EmploymentType, ReferenceType } from '../scripts/enums'
import moment from 'moment'

import sanitizeInput from '../scripts/sanitizeInput'
import { formatMoney } from "../scripts/utilities";

// let defaultFormData = memberLoanInit;
// let apiRequest = new apiReq(); // Intitilize request class

export default function App() {
	let defaultData = useDataStore((state) => state.memberLoan);
	const manageData = useDataStore((state) => state.manageData);
	const [showError, setError] = useState({ error: false, msg: '' });

	// capture application data passed to the form, then clear location state to prevent data being used again on reloads
	const appData = useLocation().state
	if (appData) {
		defaultData = (appData.newApp) ? memberLoanInit : appData
		window.history.replaceState({}, document.title)
	}

	let [data, setData] = useState(defaultData);
	const DefaultStep = defaultData.step !== undefined ? defaultData.step : 0; //check if previous step is already available
	const DefaultCompleted = defaultData.reviewStep !== undefined ? defaultData.reviewStep : false; //check if form has already reach review step
	let [currentStep, setCurrentStep] = useState(DefaultStep);
	let [completed, setCompleted] = useState(DefaultCompleted);
	const [formLabels, setformLabels] = useState([]);

	//disable number field scroll issue
	document.addEventListener("wheel", function (event) {
		if (document.activeElement.type === "number") {
			document.activeElement.blur();
		}
	});

	useEffect(() => {
		// code to run on component mount
		//get data here
		checkForData();
		formatAPIDates();
		steps.forEach((child) => {
			formLabels.push(child.props.label);
			setformLabels(formLabels);
		})
		// eslint-disable-next-line
	}, []);


	//every time data / currentstep state change set the global local cache
	useEffect(() => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		// console.log('data changed', data);
		// let step = currentStep + 1;
		setToLocalStorage(data, currentStep);
		//eslint-disable-next-line
	}, [data, currentStep]);

	// make sure dates are in the right format for date fields
	// once the date field has a truthy value, try to format it
	const formatAPIDates = () => {
		const dateFormat = "YYYY-MM-DD"
		let formattingDone = false

		if (data.dob) {
			data.dob = moment(data.dob).format(dateFormat)
			formattingDone = true
		}

		data.govIDs.forEach(id => {
			if (id.expiryDate) {
				id.expiryDate = moment(id.expiryDate).format(dateFormat)
				formattingDone = true
			}

			if (id.issueDate) {
				id.issueDate = id.issueDate !== '' ? moment(id.issueDate).format(dateFormat) : '';
				formattingDone = true
			}
		})

		if (data.previousEmployment) {
			if (data.previousEmployment.startDate) {
				data.previousEmployment.startDate = moment(data.previousEmployment.startDate).format(dateFormat)
				formattingDone = true
			}

			if (data.previousEmployment.endDate) {
				data.previousEmployment.endDate = moment(data.previousEmployment.endDate).format(dateFormat)
				formattingDone = true
			}
		}

		if (formattingDone) setData(prev => ({ ...prev, ...data }))
	}

	//eslint-disable-next-line
	const formSucess = useNavigate();

	const makeRequest = (formData) => {
		//Attach id to data so API knows to update
		let existingEntry = data.id !== undefined && data.id !== "";
		if (existingEntry) formData.id = data.id;

		formData.lastCompletedStep = data.step;

		if(completed) formData.VisitedReviewStep = true;
		else formData.VisitedReviewStep = false;

		//if whole data  is sent 
		if (formData.isComplete !== undefined && formData.isComplete) {
			delete formData.reviewStep;
			delete formData.step;
			delete formData.updated;
		}
		delete formData.confirmEmail;

		console.log('sending this to API', { formData, existingEntry });
		apiReq.post('MemberLoan/update', formData).then(data => {
			console.log("makeRequest: Response from API", data);
			if (formData.isComplete !== undefined && formData.isComplete) {
				manageData(memberLoanInit);
				formSucess('/apply/success', { state: 'Form submitted' });
				return
			}
			if (data === "Email Already Exisits") {
				setCurrentStep((prev) => prev - 1);
				setError({ error: true, msg: 'This email address already exists!' });
				setData((prev) => ({ ...prev, confirmEmail: '' }));
				return
			}
			//This is to check if it was updated or created
			if (typeof data === 'object' && data !== null) setData((prev) => ({ ...prev, ...data }));
			else {
				if (!existingEntry) setData((prev) => ({ ...prev, id: data }));
			}
			// setCurrentStep((prev) => prev + 1);
			setError({ error: false, msg: '' });
		}).catch(resp => {
			console.log("Error in API \n", resp);
			setError({ error: true, msg: 'There was a problem retrieving your application. Please try again, or contact us for further assistance.' })
		});
	}

	// Set to the global local cache
	const setToLocalStorage = useCallback((data, step) => {
		console.log('setting local storage');
		if (completed) data.reviewStep = true;
		else data.reviewStep = false;
		data.step = step;
		data.updated = new Date().getTime();
		manageData(data);
	}, [manageData, completed]);

	const handleNextStep = (newData, final = false) => {
		console.log('Handling next step', newData);
		if (newData !== undefined) {
			if(final === true) newData.visitedReviewStep = true;
			setData((prev) => ({ ...prev, ...newData }));
			makeRequest(newData);
		}
		//if form completed
		if (final === true) {
			setCompleted(true);
			setCurrentStep(steps.length);
			// makeRequest(newData);
			return;
		}

		setCurrentStep((prev) => prev + 1);
		// if(newData !== undefined) setToLocalStorage(newData, currentStep + 1);
	};

	const handlePrevStep = (newData) => {
		if (newData !== undefined) {
			setData((prev) => ({ ...prev, ...newData }));
			if (completed) makeRequest(newData);
			setToLocalStorage(newData, currentStep - 1);
		}
		setCurrentStep((prev) => prev - 1);
	};

	const legalData = {
		hasOutstandingJudgments: data.hasOutstandingJudgments,
		isPartyInLawsuit: data.isPartyInLawsuit,
		hadForeclosure: data.hadForeclosure,
		isGuarantor: data.isGuarantor,
		guarantorFor: data.guarantorFor
	}

	const loanData = {
		memberID: data.memberID,
		loanPurpose: data.loanPurpose,
		loanAmount: data.loanAmount,
		accountNumber: data.accountNumber,
	}

	const personalData = {
		firstName: data.firstName,
		lastName: data.lastName,
		dependents: data.dependents,
		email: data.email,
		confirmEmail: data.confirmEmail === undefined ? '' : data.confirmEmail,
		maritalStatus: data.maritalStatus,
		dob: data.dob,
		hasCoApplicant: data.hasCoApplicant,
	}

	const employmentData = { currentEmployment: data.currentEmployment, previousEmployment: data.previousEmployment }

	//Put the steps in array to switch between them during render
	const steps = [
		<Personal sanitizeInput={sanitizeInput} next={handleNextStep} data={personalData} label="Personal" completed={completed} />,
		<Loan sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={loanData} label="Loan" completed={completed} />,
		<Address sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={data.physicalAddress} label="Address" completed={completed} />,
		<Contact next={handleNextStep} prev={handlePrevStep} data={data.phone} label="Contact" completed={completed} />,
		<Identification next={handleNextStep} prev={handlePrevStep} data={data.govIDs} label="Identification" completed={completed} />,
		<Employment sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={employmentData} label="Employment" completed={completed} />,
		<Income sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={data.income} label="Income" completed={completed} />,
		<Liabilities sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={data.liabilities} label="Liabilities" completed={completed} />,
		<Expenses sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={data.expenses} label="Expenses" completed={completed} />,
		<Assets sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={data.assets} label="Assets" completed={completed} />,
		<Legal sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={legalData} label="Legal" completed={completed} />,
		<References sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={data.references} label="References" completed={completed} />,
	];

	// Check for user data in the begin if have to get by api call
	const checkForData = () => {
		let day = 86400 * 1000;
		let thirtyDays = day * 30;
		let timePassed = new Date().getTime() - data.updated;
		if (data.updated !== null && timePassed < thirtyDays){
			if(data.step > 0) setCurrentStep(data.step + 1);
		}
		else{
			setData(memberLoanInit);
			setCurrentStep(0);
		}
	}

	function MyStepper(steps) {
		return (
			<Stepper alternativeLabel activeStep={currentStep}>
				{
					steps.steps.map((child, index) => {
						return (
							<Step key={child.props.label} completed={completed || currentStep > index}>
								<StepLabel>{child.props.label}</StepLabel>
							</Step>
						)
					})
				}
			</Stepper>
		)
	}

	return (
		<Container>
			<Box><h1 className="page-title center">Member Loan Application</h1></Box>
			<Box>
				<MyStepper steps={steps} />
			</Box>
			<Box>
				<Collapse in={showError.error} style={{ marginTop: '1.5rem' }}>
					<Alert severity="error">
						<AlertTitle>Error</AlertTitle>
						{showError.msg}
					</Alert>
				</Collapse>
			</Box>
			{
				currentStep < steps.length && completed &&
				<Box paddingTop={6}>
					<Typography align="center" variant="h5" style={{ textAlign: 'center' }}>{steps[currentStep].props.label}</Typography>
				</Box>
			}
			<Box paddingTop={6}>
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						{currentStep < steps.length ? steps[currentStep] : <StepReview data={data} formLabels={formLabels} prev={handlePrevStep} setCurrentStep={setCurrentStep} makeRequest={makeRequest} />}
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}


const StepReview = ({ data, prev, formLabels, setCurrentStep, makeRequest }) => {
	const [submitDisabled, setSubtmitBtn] = useState(true);

	// console.log('Step review', data);
	// const labelLocater = (object, value) => {
	// 	for(const key in object){
	// 		if(key === value) value++
	// 	}
	// 	return 
	// }

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography align="center" variant="h4" component="div">Review information here</Typography>
						{/* <Typography variant="span" color="text.secondary">Only showing populated values</Typography> */}
					</CardContent>

				</Card>
			</Grid>
			{/* <ReviewDetails data={data.physicalAddress} step="2" Title="Address" setCurrentStep={setCurrentStep} />
				<ReviewDetails data={data.phone} step="3" Title="Contact" setCurrentStep={setCurrentStep} />
				<ReviewDetails data={data.income} step="3" Title="Income" setCurrentStep={setCurrentStep} /> */}
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Personal" />
					<CardContent>
						<Container>First Name: <Typography variant="span" color="text.secondary">{data.firstName}</Typography></Container>
						<Container>Last Name: <Typography variant="span" color="text.secondary">{data.lastName}</Typography></Container>
						<Container>Email: <Typography variant="span" color="text.secondary">{data.email}</Typography></Container>
						<Container>Date of Birth: <Typography variant="span" color="text.secondary">{moment(data.dob).format('Do MMM YYYY')}</Typography></Container>
						<Container>Marital Status: <Typography variant="span" color="text.secondary">{MaritalStatus.getLabel(data.maritalStatus)}</Typography></Container>
						<Container>Dependants: <Typography variant="span" color="text.secondary">{data.dependents}</Typography></Container>
						<Container>Has co applicant: <Typography variant="span" color="text.secondary">{data.hasCoApplicant ? 'Yes' : 'No'}</Typography></Container>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(0))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Loan" />
					<CardContent>
						{
							data.memberID !== '' && data.memberID !== 0 &&
							<Container>MemberID: <Typography variant="span" color="text.secondary">{data.memberID}</Typography></Container>
						}
						{
							data.accountNumber !== '' && data.accountNumber !== 0 &&
							<Container>Account Number: <Typography variant="span" color="text.secondary">{data.accountNumber}</Typography></Container>
						}
						<Container>Loan Purpose: <Typography variant="span" color="text.secondary">{data.loanPurpose}</Typography></Container>
						<Container>Loan Amount: <Typography variant="span" color="text.secondary">{formatMoney(data.loanAmount)}</Typography></Container>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(1))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Address" />
					<CardContent>
						<Container>Street: <Typography variant="span" color="text.secondary">{data.physicalAddress.street}</Typography></Container>
						<Container>City: <Typography variant="span" color="text.secondary">{data.physicalAddress.city}</Typography></Container>
						<Container>State: <Typography variant="span" color="text.secondary">{data.physicalAddress.state}</Typography></Container>
						<Container>Zip: <Typography variant="span" color="text.secondary">{data.physicalAddress.zip}</Typography></Container>
						<Container>Years: <Typography variant="span" color="text.secondary">{data.physicalAddress.time}</Typography></Container>
						<Container>Ownership: <Typography variant="span" color="text.secondary">{OwnershipStatus.getLabel(data.physicalAddress.ownership)}</Typography></Container>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(2))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Contact" />
					<CardContent>
						<Container>Mobile: <Typography variant="span" color="text.secondary">{data.phone.mobile}</Typography></Container>
						{
							data.phone.home !== '' &&
							<Container>Home: <Typography variant="span" color="text.secondary">{data.phone.home}</Typography></Container>
						}
						{
							data.phone.work !== '' &&
							<Container>Work: <Typography variant="span" color="text.secondary">{data.phone.work}</Typography></Container>
						}
						{
							data.phone.other !== '' &&
							<Container>Other: <Typography variant="span" color="text.secondary">{data.phone.other}</Typography></Container>
						}
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(3))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Identification" />
					<CardContent>
						{
							data.govIDs.length !== 0 &&
							<Grid container>
								{
									data.govIDs.map((govID, index) => {
										return (
											<Grid item xs={6} key={index} sx={{ marginBottom: 1 }}>
												<Container>Type: <Typography variant="span" color="text.secondary">{IDType.getLabel(govID.type)}</Typography></Container>
												<Container>Number: <Typography variant="span" color="text.secondary">{govID.number}</Typography></Container>
												<Container>Issue Date: <Typography variant="span" color="text.secondary">{moment(govID.issueDate).format('Do MMMM YYYY')}</Typography></Container>
												<Container>Expiry Date: <Typography variant="span" color="text.secondary">{moment(govID.expiryDate).format('Do MMMM YYYY')}</Typography></Container>
											</Grid>
										)
									})
								}
							</Grid>
						}

					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(4))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Employment" />
					<CardContent>
						<Grid container>
							<Grid item xs={12} md={6}>
								<Container>Employment Type: <Typography variant="span" color="text.secondary">{EmploymentType.getLabel(data.currentEmployment.employmentType)}</Typography></Container>
								<Container>Business Type: <Typography variant="span" color="text.secondary">{data.currentEmployment.businessType}</Typography></Container>
								<Container>Employer: <Typography variant="span" color="text.secondary">{data.currentEmployment.employer}</Typography></Container>
								<Container>Employer Address: <Typography variant="span" color="text.secondary">{data.currentEmployment.employerAddress}</Typography></Container>
								<Container>Job Title: <Typography variant="span" color="text.secondary">{data.currentEmployment.jobTitle}</Typography></Container>
								<Container>Time at Employer: <Typography variant="span" color="text.secondary">{data.currentEmployment.timeAtEmployer} Years</Typography></Container>
								<Container>Supervisor: <Typography variant="span" color="text.secondary">{data.currentEmployment.supervisor}</Typography></Container>
							</Grid>
							<Grid item xs={12} md={6}>
								{
									data.currentEmployment.timeAtEmployer < 5 &&
									<>
										<Container>Previous job title: <Typography variant="span" color="text.secondary">{data.previousEmployment.jobTitle}</Typography></Container>
										<Container>Previous Employer: <Typography variant="span" color="text.secondary">{data.previousEmployment.employer}</Typography></Container>
										<Container>Previous Employer Address: <Typography variant="span" color="text.secondary">{data.previousEmployment.address}</Typography></Container>
										<Container>Start Date: <Typography variant="span" color="text.secondary">{moment(data.previousEmployment.startDate).format('Do MMM YYYY')}</Typography></Container>
										<Container>End Date: <Typography variant="span" color="text.secondary">{moment(data.previousEmployment.endDate).format('Do MMM YYYY')}</Typography></Container>
									</>
								}
							</Grid>
						</Grid>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(5))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader title="Income" />
					<CardContent>
						<Container>Gross: <Typography variant="span" color="text.secondary">{formatMoney(data.income.gross)}</Typography></Container>
						<Container>Social Deduction: <Typography variant="span" color="text.secondary">{formatMoney(data.income.socialDeduction)}</Typography></Container>
						<Container>Net Salary: <Typography variant="span" color="text.secondary">{formatMoney(data.income.net)}</Typography></Container>
						<Container>Other Income:
							<Grid container>
								{
									data.income.otherIncome.length !== 0 ?
										data.income.otherIncome.map((income, index) => {
											return (
												<Grid item xs={4} key={index} sx={{ marginBottom: 1 }}>
													<Container>#{index + 1}</Container>
													<Container>Source: <Typography variant="span" color="text.secondary">{income.source}</Typography></Container>
													<Container>Amount: <Typography variant="span" color="text.secondary">{formatMoney(income.amount)}</Typography></Container>
												</Grid>
											)
										})
										:
										<div>
											No other income added
										</div>
								}
							</Grid>
						</Container>

					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(6))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Liabilities" />
					<CardContent>
						<Grid container>
							{
								data.liabilities.length !== 0 ?
									data.liabilities.map((liability, index) => {
										return (
											<Box key={index} sx={{ marginBottom: 1 }}>
												<Container>#{index + 1}</Container>
												<Container>Liability: <Typography variant="span" color="text.secondary">{liability.liability}</Typography></Container>
												<Container>Particular: <Typography variant="span" color="text.secondary">{liability.particulars}</Typography></Container>
												<Container>Present Balance: <Typography variant="span" color="text.secondary">{formatMoney(liability.presentBalance)}</Typography></Container>
												<Container>Monthly Payment: <Typography variant="span" color="text.secondary">{formatMoney(liability.monthlyPayment)}</Typography></Container>
											</Box>
										)
									})
									:
									<div>
										No liabilities added
									</div>
							}
						</Grid>

					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(7))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Expenses" />
					<CardContent>
						<Grid container>
							{
								data.expenses.length !== 0 ?
									data.expenses.map((expense, index) => {
										return (
											<Grid item key={index} sx={{ marginBottom: 1 }}>
												<Container>#{index + 1}</Container>
												<Container>Expense: <Typography variant="span" color="text.secondary">{expense.expense}</Typography></Container>
												<Container>Particular: <Typography variant="span" color="text.secondary">{expense.particulars}</Typography></Container>
												<Container>Amount: <Typography variant="span" color="text.secondary">{formatMoney(expense.amount)}</Typography></Container>
											</Grid>
										)
									})
									:
									<div>
										No expenses added
									</div>
							}
						</Grid>

					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(8))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Assets" />
					<CardContent>
						<Grid container>
							{
								data.assets.length !== 0 ?
									data.assets.map((assets, index) => {
										return (
											<Grid item xs={6} sx={{ marginBottom: 1 }} key={index}>
												<Container>#{index + 1}</Container>
												<Container>Expense: <Typography variant="span" color="text.secondary">{assets.asset}</Typography></Container>
												<Container>Particular: <Typography variant="span" color="text.secondary">{assets.particulars}</Typography></Container>
												<Container>Market Value: <Typography variant="span" color="text.secondary">{formatMoney(assets.marketValue)}</Typography></Container>
												<Container>Pledged: <Typography variant="span" color="text.secondary">{assets.pledged ? 'Yes' : 'No'}</Typography></Container>
											</Grid>
										)
									})
									:
									<div>
										No assets added
									</div>
							}
						</Grid>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(9))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Legal" />
					<CardContent>
						<Container>Outstanding Judgments: <Typography variant="span" color="text.secondary">{data.outstandingJudgments ? 'Yes' : 'No'}</Typography></Container>
						<Container>Party In Lawsuit: <Typography variant="span" color="text.secondary">{data.partyInLawsuit ? 'Yes' : 'No'}</Typography></Container>
						<Container>Had Foreclosure: <Typography variant="span" color="text.secondary">{data.hadForeclosure ? 'Yes' : 'No'}</Typography></Container>
						<Container>Is Guarantor: <Typography variant="span" color="text.secondary">{data.isGuarantor ? 'Yes' : 'No'}</Typography></Container>
						{
							data.isGuarantor &&
							<Container>Guarantor For: <Typography variant="span" color="text.secondary">{data.guarantorFor}</Typography></Container>
						}
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(10))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader title="References" />
					<CardContent>
						<Grid container>
							{
								data.references.map((memref, index) => {
									return (
										<Grid item xs={6} sx={{ marginBottom: 1 }} key={index}>
											<Container>#{index + 1}</Container>
											<Container>Type: <Typography variant="span" color="text.secondary">{ReferenceType.getLabel(memref.type)}</Typography></Container>
											<Container>Name: <Typography variant="span" color="text.secondary">{memref.name}</Typography></Container>
											<Container>Relationship: <Typography variant="span" color="text.secondary">{memref.relationship}</Typography></Container>
											<Container>Address: <Typography variant="span" color="text.secondary">{memref.address}</Typography></Container>
											<Container>Contact: <Typography variant="span" color="text.secondary">{memref.contactNumber}</Typography></Container>
										</Grid>
									)
								})
							}
						</Grid>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(11))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Disclaimer Agreement" />
					<CardContent>
						<Grid container>
							<Grid item xs={12}>
								<Alert severity="info">You promise that everything you have stated in this application is correct to the best of your knowledge and that the above information is a complete listing of what you owe.
									If there are any important changes you will notify us in writing immediately. You authorize the Credit Union to obtain credit
									reports in connection with this application for credit and for any update, increase, renewal, extension or collection of the credit received. You understand that the
									Credit Union will rely on the information in this application and your credit report to make its decision. If you request, the Credit Union will tell you the name and address of any credit bureau
									from which it received a credit report on you. It is a crime to willfully and deliberately provide incomplete or incorrect information
								</Alert>
							</Grid>
							<Grid item xs={12} alignContent="center" justifyContent={"center"}>
								<FormGroup>
									<FormControlLabel control={<Checkbox onClick={() => setSubtmitBtn(submitDisabled ? false : true)} />} label="I promise" />
								</FormGroup>
							</Grid>
						</Grid>
					</CardContent>
					<CardActions>
						<Button size="small" variant="contained" color="primary" disabled={submitDisabled} onClick={() => makeRequest({ isComplete: true, ...data })}>
							Submit
						</Button>
					</CardActions>
				</Card>
			</Grid>
		</Grid>
	);
};

// this is to show form data in review section should take data and step info to show review data
// still in progress
//eslint-disable-next-line
function ReviewDetails({ data, step, Title, setCurrentStep }) {
	const getFieldName = (rawName) => {
		let fieldName = rawName.charAt(0).toUpperCase() + rawName.substring(1, rawName.length);
		let fieldNameMatch = fieldName.match(/[A-Z][a-z]+|[0-9]+/g);
		fieldName = fieldNameMatch === null ? fieldName : fieldNameMatch.join(" ");
		return fieldName;
	}

	let finalData = [];
	for (const info in data) {
		// console.log('info',{info, data});
		if (data[info] !== '' && [info] !== 'step' && [info !== 'updated']) {
			let fieldName = getFieldName(info);

			let mydata = null;
			switch (info) {
				case 'otherIncome':
					// if(data[info].length > 0){
					// 	let raw = [];
					// 	for (let index = 0; index < data[info].length; index++) {
					// 		let nestData = data[info][index];
					// 		for(const eachData in nestData){
					// 			console.log({eachData , data: nestData})
					// 			let nestFieldName = getFieldName(eachData);
					// 			raw.push(<div key={'eachData'+index}>{nestFieldName}: <Typography variant="span" color="text.secondary">{nestData[eachData]}</Typography></div>);
					// 		}
					// 	}
					// 	mydata = <>{fieldName} : { raw.map(data => { return data }) } </>
					// }
					break;
				case 'liabilities':
					break;
				default:
					mydata = <>{fieldName}: <Typography variant="span" color="text.secondary">{data[info]}</Typography></>
					break;
			}
			finalData.push(
				<Container key={info}>
					{mydata}
				</Container>
			)
		}
	}
	return (
		<Box paddingTop={2} paddingBottom={2}>
			<Card>
				<CardHeader title={Title} />
				<CardContent>{finalData}</CardContent>
				<CardActions>
					<Button
						size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(step))}>
						Edit
					</Button>
				</CardActions>
			</Card>
		</Box>
	)
}