import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Select, MenuItem, TextField, Button, Grid, InputLabel, FormControl, Alert, RadioGroup, FormLabel, FormControlLabel, Radio } from '@mui/material'
import { MaritalStatus } from "../../../scripts/enums";
import { useState } from "react";
// import { MININUM_MEMBER_SHARES } from "../../../scripts/constants";

// calculate 18 years old date
let now = new Date();
let minAge = now.setFullYear(now.getFullYear() - 18);

// the Personal component is imported before other step components; see ordering in parent component
// consequently, custom Yup method will be defined globally and therefore accessible in other step components that need it
Yup.addMethod(Yup.string, 'digitsOnly', function (msg) {
	return this.matches(/^\d+$/, msg)
})

const formValidationSchema = Yup.object().shape({
	firstName: Yup.string().required("Required"),
	lastName: Yup.string().required("Required"),
	email: Yup.string().required("Required").email("Invalid"),
	confirmEmail: Yup.string().required("Required").email("Invalid").test({
		name: 'matches',
		exclusive: false,
		params: {},
		message: 'The emails you entered do not match',
		test: function (value) {
			let emailEmpty = this.parent.email === "" || this.parent.email === undefined;
			let emailValue = this.parent.email;
			if(!emailEmpty && value !== emailValue) return false;
			return true;
		},
	}),
	dob: Yup.date("Invalid").max(new Date(minAge), 'You must be 18 years or older to apply').nullable().required("Required"),
	gender: Yup.string().required("Required"),
	maritalStatus: Yup.string().required("Required"),
	motherMaidenName: Yup.string().required("Required"),
	// sharesRequested: Yup.string().required("Required").digitsOnly("Invalid").test({
	// 	name: 'minimumShares',
	// 	exclusive: false,
	// 	params: {},
	// 	message: `You need at least ${MININUM_MEMBER_SHARES} shares to become a member`,
	// 	test: function (value) {
	// 		return parseInt(value) >= MININUM_MEMBER_SHARES;
	// 	},
	// })
});

// set up dropdown list values
let MenuItems = [];
MaritalStatus.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})

const Personal = (props) => {
	const { sanitizeInput } = props;
	const [formSubmitting, setformSubmitting] = useState(false);

	const handleCustomSubmit = async (type, values, formikProps) => {
		setformSubmitting(true)
		
		if(type === 'next' || props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			
			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev(values); //go to previous step
				else props.next(values, type === 'review'); // go to next or review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, false);
				setformSubmitting(false);
			}
		} else if(type === 'prev') props.prev(values);
	}

	return (
		<Formik enableReinitialize={true} validationSchema={formValidationSchema} initialValues={props.data}>
			{
				({ values, handleChange, handleBlur, touched, errors, setFieldValue, validateForm, setFieldTouched }) => (
					<Form autoComplete="off">
						<Grid container spacing={2}>
							{/* <Grid item xs={12}>
								<h2>Personal Details</h2>
							</Grid> */}
							<Grid item xs={12} md={4}>
								<Field
									error={touched.firstName && errors.firstName ? true : false}
									onBlur={(_e) => setFieldValue('firstName', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
									fullWidth component={TextField} label="First Name" name="firstName" id="firstName" onChange={handleChange} value={values.firstName} />
								<ErrorMessage className="errMsg" component="span" name="firstName" />
							</Grid>
							<Grid item xs={12} md={4}>
								<Field
									error={touched.lastName && errors.lastName ? true : false}
									onBlur={(_e) => setFieldValue('lastName', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
									fullWidth component={TextField} label="Last Name" name="lastName" id="lastName" onChange={handleChange} value={values.lastName} />
								<ErrorMessage className="errMsg" component="span" name="lastName" />
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl fullWidth>
									<FormLabel id="gender-label" error={touched.gender && errors.gender ? true : false}>Gender</FormLabel>
									<RadioGroup
										aria-labelledby="gender-label"
										id="gender"
										name="gender"
										value={values.gender}
										row
										onChange={(e) => handleChange({ target: { id: "gender", name: "gender", value: e.target.value } })}
									>
										<FormControlLabel value="male" control={<Radio />} label="Male" checked={values.gender === 'male'} />
										<FormControlLabel value="female" control={<Radio />} label="Female" checked={values.gender === 'female'} />
									</RadioGroup>
									<ErrorMessage className="errMsg" component="span" name="gender" />
								</FormControl>
							</Grid>

							<Grid item xs={12} md={6}>
								<Field
									error={touched.email && errors.email ? true : false}
									disabled={props.data.email !== ''}
									helperText={props.data.email !== '' ? "For security, your email address can no longer be changed" : ""}
									onBlur={(_e) => setFieldValue('email', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
									fullWidth component={TextField} label="Email" name="email" id="email" onChange={handleChange} value={values.email} />
								<ErrorMessage className="errMsg" component="span" name="email" />
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									error={touched.confirmEmail && errors.confirmEmail ? true :  false}
									disabled={props.data.email !== ''}
									helperText={!errors.confirmEmail || !touched.confirmEmail ? "This helps to avoid typos in your email address" : ""}
									onBlur={(_e) => setFieldValue('confirmEmail', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
									fullWidth component={TextField} label="Confirm Email" name="confirmEmail" id="confirmEmail" onChange={handleChange} value={values.confirmEmail} />
								<ErrorMessage className="errMsg" component="span" name="confirmEmail" />
							</Grid>

							<Grid item xs={12}>
								<Alert severity="info" sx={{ mb: 2 }}>
									For your convenience, we automatically save your application progress. You are free to come back anytime within <strong>30 days</strong> of your last update to this application.&nbsp;
									<br /><br />
									{ props.data.email === '' && <span>Once you click <strong>Next</strong>, we will send you an email with your access details. For this reason, your email address cannot be updated afterwards.</span>}
									{ props.data.email !== '' && <span>You should have received your access details via email. If you have trouble finding this email, verify it is not in your junk / spam folders, or contact us for further assistance.</span>}
								</Alert>
							</Grid>

							<Grid item xs={12} md={4}>
								<Field
									error={touched.dob && errors.dob ? true : false}
									fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Date of Birth (dd/mm/yyyy)" type="date" name="dob" id="dob" onChange={handleChange} value={values.dob !== null ? values.dob : ''} />
								<ErrorMessage className="errMsg" component="span" name="dob" />
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl fullWidth>
									<InputLabel shrink htmlFor="maritalStatus">Marital Status</InputLabel>
									<Field
										onChange={event => {event.target.name = 'maritalStatus'; handleChange(event);}}
										fullWidth component={Select} label="Marital Status" name="maritalStatus" id="maritalStatus" value={values.maritalStatus}>
										{MenuItems}
									</Field>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={4}>
								<Field
									error={touched.motherMaidenName && errors.motherMaidenName ? true : false}
									onBlur={(_e) => setFieldValue('motherMaidenName', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
									fullWidth component={TextField} label="Mother's Maiden Name" name="motherMaidenName" id="motherMaidenName" onChange={handleChange} value={values.motherMaidenName} />
								<ErrorMessage className="errMsg" component="span" name="motherMaidenName" />
							</Grid>

							<Grid item xs={12} md={6}>
								<Field
									onBlur={(_e) => setFieldValue('employer', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
									fullWidth component={TextField} label="Employer" name="employer" id="employer" onChange={handleChange} value={values.employer} />
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									onBlur={(_e) => setFieldValue('occupation', sanitizeInput(_e.target.value)).then(resp => handleBlur(_e))}
									fullWidth component={TextField} label="Occupation" name="occupation" id="occupation" onChange={handleChange} value={values.occupation} />
							</Grid>

							{/* <Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<h2>Membership Shares</h2>
									</Grid>
									<Grid item xs={12} md={6}>
										<Field
											error={touched.sharesRequested && errors.sharesRequested ? true : false}
											onBlur={handleBlur}
											helperText={!errors.sharesRequested || !touched.sharesRequested ? `A minimum of ${MININUM_MEMBER_SHARES} shares is required to become a member` : ""}
											fullWidth component={TextField} label="Shares Requested" name="sharesRequested" id="sharesRequested" onChange={handleChange} value={values.sharesRequested} />
										<ErrorMessage className="errMsg" component="span" name="sharesRequested" />
									</Grid>
								</Grid>
							</Grid> */}
							
							<Grid item xs={6}>
								<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('next', values, {validateForm, setFieldTouched})}>
									Next
								</Button>
							</Grid>
							{
								props.completed &&
								<Grid item xs={6}>
									<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})}>
										Review
									</Button>
								</Grid>
							}
						</Grid>
					</Form>
				)
			}
		</Formik>
	);
};

export default Personal