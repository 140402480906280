// this is where we begin our development. Think of this as being similar to application.cfc in CF or main() in Java
// from here all other top level components will be referenced

// components
import Header from './components/Header';
import Footer from './components/Footer';

// pages
import Home from './pages/Home';
import Services from './pages/Services';
import ServicesDetails from './pages/ServicesDetails';
import AppFormStart from './pages/AppFormStart';
import SubmissionSuccess from './pages/SubmissionSuccess';
import Custom404 from './pages/Custom404';

//Hanldles the routing
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Handles the styling
import './styles/App.css';

function App() {
    return (
    <Router>
        <div className="App">
            <div className="content-flex">
                <Header />

                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/services" exact element={<Services />} />
                    <Route path="/services/:urlSlug" element={<ServicesDetails />} />
                    <Route path="/apply" exact element={<AppFormStart/>} />
                    <Route path="/apply/:urlSlug" element={<AppFormStart/>} />
                    <Route path="/apply/success" element={<SubmissionSuccess />} />
                    
                    <Route path="*" exact element={<Custom404 />} />
                </Routes>
            </div>
            <div className="footer-flex">
                <Footer/>
            </div>
        </div>
    </Router>
    );
}

export default App;
