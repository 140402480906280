//This Application form uses Zustand state management
import { useState, useEffect, useCallback } from "react";
import { Container, Box, Stepper, StepLabel, Step, Typography, Grid, Card, CardContent, CardActions, Button, CardHeader, Collapse, Alert } from '@mui/material'
import { useDataStore } from '../scripts/formStore'
import { membershipInit } from "../scripts/initFormData";
import apiReq from '../scripts/apiReq'
import Personal from '../components/forms/Membership/Personal'
import Address from '../components/forms/Membership/Address'
import Contact from '../components/forms/Membership/Contact'
import Identification from '../components/forms/Membership/Identification'
import Ownership from '../components/forms/Membership/Ownership'
import Beneficiaries from '../components/forms/Membership/Beneficiaries'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { MaritalStatus, IDType, DesignationType } from '../scripts/enums'
import moment from 'moment'

import sanitizeInput from '../scripts/sanitizeInput'
//eslint-disable-next-line
import { formatMoney } from '../scripts/utilities'
//eslint-disable-next-line
import { deepCompare } from "../scripts/utilities";
//eslint-disable-next-line
import { MININUM_MEMBER_SHARES, SESSION_LIMIT_MINS } from "../scripts/constants";

const errorNoticeDefault = { error: false, msg: '' }

export default function MembershipApp() {
	let defaultData = useDataStore((state) => state.membership);
	const manageData = useDataStore((state) => state.manageData)
	
	const navigate = useNavigate();
	
	// capture application data passed to the form, then clear location state to prevent data being used again on reloads
	const appData = useLocation().state
	if (appData) {
		defaultData = (appData.newApp) ? membershipInit : appData
		window.history.replaceState({}, document.title)
	}

	let [data, setData] = useState(defaultData);
	let [currentStep, setCurrentStep] = useState(defaultData.step);
	let [completed, setCompleted] = useState(defaultData.reviewStep);
	const [showError, setError] = useState(errorNoticeDefault);

	//disable number field scroll issue
	document.addEventListener("wheel", function(event){
		if(document.activeElement.type === "number"){
			document.activeElement.blur();
		}
	});

	// code to run on component mount
	useEffect(() => {
		// console.log("Global Data (on mount): ", defaultData)
		// console.log("Local Data (on mount): ", data, "Default step: ", currentStep)

		validateGlobalState()
		formatAPIDates()
		// eslint-disable-next-line
	}, []);

	//every time data / currentstep state change set the global local cache
	useEffect(() => {
		// console.log("Data or currentStep changed (useEffect): ", data, "Step:", currentStep)

		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
		
		setToLocalStorage(data, currentStep);
		//eslint-disable-next-line
	}, [data, currentStep]);

	// reset expired global state to default
	const validateGlobalState = () => {
		if (data.updated !== null) {
			console.log('got global cache data', data);

			let timePassed = new Date().getTime() - data.updated;
			let allowedInterval = SESSION_LIMIT_MINS * 60 * 1000; // milliseconds

			// reset state if expired
			if (timePassed > allowedInterval) {
				setCompleted(false)
				setCurrentStep(0)
				setData(membershipInit)
			}
		}
	}

	// Set to the global local cache
	// useCallback ensures this function is only recreated when its dependencies change instead of on each render
	const setToLocalStorage = useCallback((data, step) => {
		// console.log("Update global state (useCallback)", data, "Step:", step)
		
		if (completed) data.reviewStep = true;
		else data.reviewStep = false;

		data.reviewStep = completed;
		data.step = step;
		data.updated = new Date().getTime();

		manageData(data, 'membership');
		//eslint-disable-next-line
	}, [manageData, completed]);

	// make sure dates are in the right format for date fields
	// once the date field has a truthy value, try to format it
	const formatAPIDates = () => {
		const dateFormat = "YYYY-MM-DD"
		let formattingDone = false
		
		if(data.dob) {
			data.dob = moment(data.dob).format(dateFormat)
			formattingDone = true
		}

		data.govIDs.forEach(id => {
			if (id.expiryDate) {
				id.expiryDate = moment(id.expiryDate).format(dateFormat)
				formattingDone = true
			}
			
			if (id.issueDate) {
				id.issueDate = moment(id.issueDate).format(dateFormat)
				formattingDone = true
			}
		})

		if (data.jointOwners) {
			data.jointOwners.forEach(jointOwner => {
				jointOwner.govIDs.forEach(id => {
					if (id.expiryDate) {
						id.expiryDate = moment(id.expiryDate).format(dateFormat)
						formattingDone = true
					}
					
					if (id.issueDate) {
						id.issueDate = moment(id.issueDate).format(dateFormat)
						formattingDone = true
					}
				})
			})
		}

		if (formattingDone) setData(prev => ({ ...prev, ...data }))
	}

	const makeRequest = (formData) => {
		// attach support fields to every API request

		//Attach id to data so API knows to update
		let existingEntry = data.id !== undefined && data.id !== "";
		if (existingEntry) formData.id = data.id;

		// keep track of the last completed step in the database
		formData.step = currentStep
		formData.reviewStep = completed || currentStep === steps.length - 1
		
		if (formData.isComplete !== undefined && formData.isComplete) {
			delete formData.updated;
		}
		delete formData.confirmEmail;

		console.log('sending this to API', { formData, existingEntry });
		apiReq.post('membership/update', formData).then(data => {
			console.log("makeRequest: Response from API", data);
			// final submission completed; navigate to confirmation page
			if(formData.isComplete !== undefined && formData.isComplete){
				manageData(membershipInit, 'membership');
				navigate('/apply/success', { state: 'Form submitted' });
				return
			}

			// duplicate in progress application
			if (data.duplicate) {
				setCurrentStep((prev) => prev - 1);
				setError({ error: true, msg: <p>It appears you may have already started an application. You may enter your access details to <Link to="#" onClick={() => console.log('showing in progress form...')}>continue where you left off</Link>, or <Link to="#" onClick={() => console.log('starting over...')}>start over</Link>.</p> })
			}

			//This is to check if it was updated or created
			if (typeof data === 'object' && data !== null) setData((prev) => ({ ...prev, ...data }));
			else if (!existingEntry) setData((prev) => ({ ...prev, id: data }));
			// setCurrentStep((prev) => prev + 1);
		});
	}

	//eslint-disable-next-line
	const testAPI = () => {
		console.clear()
		const data = {
			firstName: 'Asquil'
		}

		console.log(JSON.stringify(membershipInit));

		// console.log('data: ', data)

		makeRequest(data)
	}

	// const testCompare = () => {
	// 	console.clear()
	// 	console.log("Strings: ", deepCompare('fishs', 'fish'))
	// 	console.log("Dates: ", deepCompare(new Date('2022/04/19 18:01:26'), new Date('2022/04/19 18:01:25')))
	// 	console.log("Numbers: ", deepCompare(123451, 12345))
	// 	console.log("Booleans: ", deepCompare(false, true))
	// 	console.log("Null or undefined: ", deepCompare(123, undefined))

	// 	const newData = {
	// 		memberID: "12345",
	// 		loanPurpose: "Need funds",
	// 		loanAmount: 1000000,
	// 		accountNumber: 12345,
	// 		hasCoApplicant: false
	// 	}
		
	// 	const oldData = {
	// 		memberID: "12345",
	// 		loanPurpose: "Need funds",
	// 		loanAmount: 1000000,
	// 		accountNumber: 12345,
	// 		hasCoApplicant: false
	// 	}
	// 	console.log("Objects: ", deepCompare(newData, oldData))

		
	// 	const newArr = {
	// 		liabilities: [
	// 			{
	// 				id: "600249aed7cc796e1625db7a5ad883fa",
	// 				liability: "Big",
	// 				particulars: "Big one",
	// 				presentBalance: 100000,
	// 				monthlyPayment: 100
	// 			},
	// 			{
	// 				id: "600a37fa001f1878cbea2c823e9d4ea8",
	// 				liability: "Small",
	// 				particulars: "Small one",
	// 				presentBalance: 10000,
	// 				monthlyPayment: 10
	// 			}
	// 		]
	// 	}
		
	// 	const oldArr = {
	// 		liabilities: [
	// 			{
	// 				id: "600249aed7cc796e1625db7a5ad883fa",
	// 				liability: "Big",
	// 				particulars: "Big one",
	// 				presentBalance: 100000,
	// 				monthlyPayment: 100
	// 			},
	// 			{
	// 				id: "6005bcddb27881631b68037e85f7e6bd",
	// 				liability: "Medium",
	// 				particulars: "Medium one",
	// 				presentBalance: 50000,
	// 				monthlyPayment: 50
	// 			},
	// 			{
	// 				id: "600a37fa001f1878cbea2c823e9d4ea8",
	// 				liability: "Small",
	// 				particulars: "Small one",
	// 				presentBalance: 10000,
	// 				monthlyPayment: 10
	// 			}
	// 		]
	// 	}
	// 	console.log("Arrays: ", deepCompare(newArr, oldArr))
	// }

	const handleNextStep = (newData, final = false) => {
		console.log('Handling next step', newData);
		if (newData !== undefined) {
			// if(final === true) newData.isCompleted = true;
			setData((prev) => ({ ...prev, ...newData }));
			if (makeRequest(newData) === false)
				return
		}
		//if form completed
		if (final === true) {
			setCompleted(true);
			setCurrentStep(steps.length);
			return;
		}

		setCurrentStep((prev) => prev + 1);
	};

	const handlePrevStep = (newData) => {
		if (newData !== undefined) {
			setData((prev) => ({ ...prev, ...newData }));
			if(completed) makeRequest(newData);
		}
		setCurrentStep((prev) => prev - 1);
	};

	/* ---------------------- const handleNextStep = (newData = null, goToReview = false) => {
		console.clear()
		console.log("Going to next step...", newData)
		doStepSubmission(newData, 1, goToReview)
	};

	const handlePrevStep = (newData = null) => {
		console.clear()
		console.log("Going to previous step...", newData)
		doStepSubmission(newData, -1)
	};

	const doStepSubmission = async (newData, direction = 1, goToReview = false) => {
		// console.log(data)
		// compare submitted form data with previous data for differences
		if(newData && !deepCompare(newData, steps[currentStep].props.data)) {
			console.log("data change detected")

			// update local state (triggers hook that updates global state)
			setData((prev) => ({ ...prev, ...newData }));

			// plug supplementary data values
			let existingEntry = data.id !== "";
			if (existingEntry) formData.id = data.id;

			// keep track of the last completed step in the database
			formData.step = currentStep
			formData.reviewStep = completed || currentStep === steps.length - 1

			console.log('sending this to API', { formData, existingEntry });
	
			// update in progress application via API
			apiReq.post('membership/update', newData).then(response => {
				console.log("API response: ", response)
				// go to the review step if the flag is true, otherwise, go to next or previous step
				if (goToReview) {
					setCompleted(true)
					setCurrentStep(steps.length)
				} else {
					setCurrentStep((prev) => prev + direction)
				}
			})
		} else {
			console.log("no data passed OR no change detected")
			// allow moving between steps even if no data passed
			// go to the review step if the flag is true, otherwise, go to next or previous step
			if (goToReview) {
				setCompleted(true)
				setCurrentStep(steps.length)
			} else {
				setCurrentStep((prev) => prev  direction)
			}
		}
	}

	const handleFinalSubmission = (service = 'memberLoan', appID = '') => {
		apiReq.post('membership/update', {id: data.id, isComplete: true}).then(response => {
			console.log(response)
			// remove just the application in focus from local storage
			// localStorage.removeItem('skcu-oap-dataStore')
			// rather than remove the key from localStorage, we should just nullify the memberLoan object inside the key
			// manageData(membershipInit)
			
			// navigate('/apply/success', {
			//     state: {
			//         message: 'Thanks for applying for a Member Loan',
			//         data
			//     }
			// })
		});
	} ---------------------- */
	
	const personalData = {
		firstName: data.firstName,
		lastName: data.lastName,
		email: data.email,
		confirmEmail: data.email,
		maritalStatus: data.maritalStatus,
		dob: data.dob,
		gender: data.gender,
		motherMaidenName: data.motherMaidenName,
		employer: data.employer,
		occupation: data.occupation,
		// sharesRequested: data.sharesRequested
	}

	// const personalData = {
	// 	firstName: 'Asquil',
	// 	lastName: 'Ferlance',
	// 	email: 'asquil.ferlance@gmail.com',
	// 	confirmEmail: 'asquil.ferlance@gmail.com',
	// 	maritalStatus: 2,
	// 	dob: '1993-09-07',
	// 	gender: 'male',
	// 	motherMaidenName: 'Williams',
	// 	employer: 'OPEN Interactive, Inc.',
	// 	occupation: 'Development Manager',
	// 	// sharesRequested: MININUM_MEMBER_SHARES
	// }

	// console.log(JSON.stringify(personalData));
	
	// data.physicalAddress = {
	// 	street: '30 Stadium View',
    //     city: 'Sandy Point',
    //     state: 'KN',
    //     zip: '00265'
    // }
	
	// console.log(JSON.stringify(data.physicalAddress));
	
	// data.phone = {
	// 	home: '',
    //     mobile: '18696621001',
    //     work: '18696621001',
    //     other: ''
    // }
	
	// data.govIDs = [
	// 	{
	// 		type: IDType.national.value,
	// 		number: '12345',
	// 		issueDate: '2021-09-07',
	// 		expiryDate: '2022-09-07'
	// 	},
	// 	{
	// 		type: IDType.passport.value,
	// 		number: '67890',
	// 		issueDate: '1993-09-07',
	// 		expiryDate: '2022-09-07'
	// 	}
	// ]
	
	const ownershipData = {
		isSoleOwner: data.isSoleOwner,
		jointOwners: data.jointOwners
	}
	
	// const ownershipData = {
	// 	isSoleOwner: false,
	// 	jointOwners: [
	// 		{
	// 			firstName: 'John',
	// 			lastName: 'Doe',
	// 			email: 'john.doe@gmail.com',
	// 			gender: 'male',
	// 			dob: '1990-09-07',
	// 			motherMaidenName: 'Doe',
	// 			maritalStatus: MaritalStatus.married.value,
	// 			employer: '',
	// 			occupation: '',
	// 			physicalAddress: {
	// 				street: 'Main Street',
	// 				city: 'Sandy Point',
	// 				state: 'KN',
	// 				zip: '00265'
	// 			},
	// 			phone: {
	// 				home: '',
	// 				mobile: '18696620000',
	// 				work: '18696620000',
	// 				other: ''
	// 			},
	// 			govIDs: [
	// 				{
	// 					type: IDType.national.value,
	// 					number: '09876',
	// 					issueDate: '1990-09-07',
	// 					expiryDate: '2023-09-07'
	// 				},
	// 				{
	// 					type: IDType.passport.value,
	// 					number: '54321',
	// 					issueDate: '1990-09-07',
	// 					expiryDate: '2023-09-07'
	// 				},
	// 			]
	// 		}
	// 	]
	// }

	const beneficiariesData = {
		hasBeneficiaries: data.hasBeneficiaries,
		designationType: data.designationType,
		beneficiaries: data.beneficiaries
	}

	// const beneficiariesData = {
	// 	hasBeneficiaries: true,
	// 	designationType: 2,
	// 	beneficiaries: [
	// 		{
	// 			name: 'The Wife',
	// 			contactNumber: '18696620000',
	// 			percentage: 80,
	// 			physicalAddress: {
	// 				street: 'Stadium View',
	// 				city: 'Sandy Point',
	// 				state: 'KN',
	// 				zip: '00265'
	// 			},
	// 		},
	// 		{
	// 			name: 'The Kid',
	// 			contactNumber: '',
	// 			percentage: 20,
	// 			physicalAddress: {
	// 				street: 'Stadium View',
	// 				city: 'Sandy Point',
	// 				state: 'KN',
	// 				zip: '00265'
	// 			},
	// 		}
	// 	],
	// }
	
	//Put the steps in array to switch between them during render
	const steps = [
		<Personal sanitizeInput={sanitizeInput} next={handleNextStep} data={personalData} label="Personal" completed={completed} />,
		<Address sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={data.physicalAddress} label="Address" completed={completed} />,
		<Contact next={handleNextStep} prev={handlePrevStep} data={data.phone} label="Contact" completed={completed} />,
		<Identification next={handleNextStep} prev={handlePrevStep} data={data.govIDs} label="Identification" completed={completed} />,
		<Ownership sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={ownershipData} label="Ownership" completed={completed} />,
		<Beneficiaries sanitizeInput={sanitizeInput} next={handleNextStep} prev={handlePrevStep} data={beneficiariesData} label="Beneficiaries" completed={completed} />
	];

	return (
		<Container>
			<Box><h1 className="page-title center">Become a Member</h1></Box>
			<Box>
				<Stepper alternativeLabel activeStep={currentStep}>
					{
						steps.map((child, index) => {
							return (
								<Step key={child.props.label} completed={completed || currentStep > index}>
									<StepLabel>{child.props.label}</StepLabel>
								</Step>
							)
						})
					}
				</Stepper>
				{/* <Button onClick={testAPI}>Test API</Button> */}
			</Box>
			<Box>
				<Collapse in={showError.error} style={{ marginTop: '1.5rem' }}>
					<Alert severity="error">
						{showError.msg}
					</Alert>
				</Collapse>
			</Box>
			{/* <Box>
			{
				currentStep < steps.length && completed &&
				<Typography align="center" variant="h5" style={{ textAlign: 'center' }}>{steps[currentStep].props.label}</Typography>
			}
			</Box> */}
			<Box paddingTop={6}>
				<Grid container rowSpacing={2}>
					<Grid item xs={12}>
						{currentStep < steps.length ? steps[currentStep] : <StepReview data={data} prev={handlePrevStep} setCurrentStep={setCurrentStep} makeRequest={makeRequest} />}
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}


const StepReview = ({ data, prev, setCurrentStep, makeRequest }) => {
	const reviewDateFormat = 'Do MMMM, YYYY'
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<Typography align="center" variant="h4" component="div">Review information here</Typography>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Personal" />
					<CardContent>
						<Container>First Name: <Typography variant="span" color="text.secondary">{data.firstName}</Typography></Container>
						<Container>Last Name: <Typography variant="span" color="text.secondary">{data.lastName}</Typography></Container>
						<Container>Email: <Typography variant="span" color="text.secondary">{data.email}</Typography></Container>
						<Container>Gender: <Typography variant="span" color="text.secondary">{data.gender}</Typography></Container>
						<Container>Date of Birth: <Typography variant="span" color="text.secondary">{moment(data.dob).format('Do MMM YYYY')}</Typography></Container>
						<Container>Marital Status: <Typography variant="span" color="text.secondary">{MaritalStatus.getLabel(data.maritalStatus)}</Typography></Container>
						<Container>Mother's Maiden Name: <Typography variant="span" color="text.secondary">{data.motherMaidenName}</Typography></Container>
						<Container>Employer: <Typography variant="span" color="text.secondary">{data.employer}</Typography></Container>
						<Container>Occupation <Typography variant="span" color="text.secondary">{data.occupation}</Typography></Container>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(0))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Address" />
					<CardContent>
						<Container>Street: <Typography variant="span" color="text.secondary">{data.physicalAddress.street}</Typography></Container>
						<Container>City: <Typography variant="span" color="text.secondary">{data.physicalAddress.city}</Typography></Container>
						<Container>State: <Typography variant="span" color="text.secondary">{data.physicalAddress.state}</Typography></Container>
						<Container>Zip: <Typography variant="span" color="text.secondary">{data.physicalAddress.zip}</Typography></Container>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(1))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Contact" />
					<CardContent>
						<Container>Home: <Typography variant="span" color="text.secondary">{data.phone.home ? data.phone.home : 'none provided'}</Typography></Container>
						<Container>Mobile: <Typography variant="span" color="text.secondary">{data.phone.mobile}</Typography></Container>
						<Container>Work: <Typography variant="span" color="text.secondary">{data.phone.mobile}</Typography></Container>
						<Container>Other: <Typography variant="span" color="text.secondary">{data.phone.other ? data.phone.other : 'none provided'}</Typography></Container>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(2))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Identification" />
					<CardContent>
						{
							data.govIDs.length !== 0 &&
							<Grid container>
								{
									data.govIDs.map((govID, index) => {
										return (
											<Grid item xs={6} key={index} sx={{ marginBottom: 1 }}>
												<Container>Type: <Typography variant="span" color="text.secondary">{IDType.getLabel(govID.type)}</Typography></Container>
												<Container>Number: <Typography variant="span" color="text.secondary">{govID.number}</Typography></Container>
												<Container>Issue Date: <Typography variant="span" color="text.secondary">{moment(govID.issueDate).format(reviewDateFormat)}</Typography></Container>
												<Container>Expiry Date: <Typography variant="span" color="text.secondary">{moment(govID.expiryDate).format(reviewDateFormat)}</Typography></Container>
											</Grid>
										)
									})
								}
							</Grid>
						}

					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(3))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Ownership" />
					<CardContent>
						<Container>
							<Typography variant="span" color="text.secondary">
								{data.isSoleOwner ? "You are the sole owner of the account" : "You and the individuals below have joint ownership of the account"}
							</Typography>
						</Container>
						<Grid container marginTop={2}>
							{
								data.jointOwners.map((jointOwner, index) => {
									return (
										<Grid item xs={6} sx={{ marginBottom: 1 }} key={index}>
											<Container><h3>#{index + 1}</h3></Container>
											<Container>First Name: <Typography variant="span" color="text.secondary">{jointOwner.firstName}</Typography></Container>
											<Container>Last Name: <Typography variant="span" color="text.secondary">{jointOwner.lastName}</Typography></Container>
											<Container>Email: <Typography variant="span" color="text.secondary">{jointOwner.email}</Typography></Container>
											<Container>Date of Birth: <Typography variant="span" color="text.secondary">{moment(jointOwner.dob).format('Do MMM YYYY')}</Typography></Container>
											<Container>Mother's Maiden Name: <Typography variant="span" color="text.secondary">{jointOwner.motherMaidenName}</Typography></Container>
											<Container>Marital Status: <Typography variant="span" color="text.secondary">{MaritalStatus.getLabel(jointOwner.maritalStatus)}</Typography></Container>
											<Container>Employer: <Typography variant="span" color="text.secondary">{jointOwner.employer}</Typography></Container>
											<Container>Occupation <Typography variant="span" color="text.secondary">{jointOwner.occupation}</Typography></Container>
											<Container>Street: <Typography variant="span" color="text.secondary">{jointOwner.physicalAddress.street}</Typography></Container>
											<Container>City: <Typography variant="span" color="text.secondary">{jointOwner.physicalAddress.city}</Typography></Container>
											<Container>State: <Typography variant="span" color="text.secondary">{jointOwner.physicalAddress.state}</Typography></Container>
											<Container>Zip: <Typography variant="span" color="text.secondary">{jointOwner.physicalAddress.zip}</Typography></Container>
											<Container>Home: <Typography variant="span" color="text.secondary">{jointOwner.phone.home ? jointOwner.phone.home : 'none provided'}</Typography></Container>
											<Container>Mobile: <Typography variant="span" color="text.secondary">{jointOwner.phone.mobile}</Typography></Container>
											<Container>Work: <Typography variant="span" color="text.secondary">{jointOwner.phone.mobile}</Typography></Container>
											<Container sx={{ marginBottom: 1 }}>Other: <Typography variant="span" color="text.secondary">{jointOwner.phone.other ? jointOwner.phone.other : 'none provided'}</Typography></Container>
											<Grid container>
											{
												jointOwner.govIDs.map((govID, idIndex) => {
													return (
														<Grid item xs={6} key={`${index}_${idIndex}`} sx={{ marginBottom: 1 }}>
															<Container><h4>ID #{idIndex + 1}</h4></Container>
															<Container>Type: <Typography variant="span" color="text.secondary">{IDType.getLabel(govID.type)}</Typography></Container>
															<Container>Number: <Typography variant="span" color="text.secondary">{govID.number}</Typography></Container>
															<Container>Issue Date: <Typography variant="span" color="text.secondary">{moment(govID.issueDate).format(reviewDateFormat)}</Typography></Container>
															<Container>Expiry Date: <Typography variant="span" color="text.secondary">{moment(govID.expiryDate).format(reviewDateFormat)}</Typography></Container>
														</Grid>
													)
												})
											}
											</Grid>
										</Grid>
									)
								})
							}
						</Grid>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(4))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Beneficiaries" />
					<CardContent>
						{
							data.hasBeneficiaries && 
							<Container sx={{ mb: 2 }}>Designation Type: <Typography variant="span" color="text.secondary">{DesignationType.getLabel(data.designationType)}</Typography></Container>
						}
						<Container>
							<Typography variant="span" color="text.secondary">
								{data.hasBeneficiaries ? "The individuals below are designated as beneficiaries to your account" : "There are no beneficiaries on your account"}
							</Typography>
						</Container>
						<Grid container marginTop={2}>
							{
								data.beneficiaries.map((beneficiary, index) => {
									return (
										<Grid item xs={6} sx={{ marginBottom: 1 }} key={index}>
											<Container><h3>#{index + 1}</h3></Container>
											<Container>Name: <Typography variant="span" color="text.secondary">{beneficiary.name}</Typography></Container>
											<Container>Contact Number: <Typography variant="span" color="text.secondary">{beneficiary.contactNumber ? beneficiary.contactNumber : 'None provided'}</Typography></Container>
											<Container>Percentage: <Typography variant="span" color="text.secondary">{beneficiary.percentage}%</Typography></Container>
											<Container>Street: <Typography variant="span" color="text.secondary">{beneficiary.physicalAddress.street}</Typography></Container>
											<Container>City: <Typography variant="span" color="text.secondary">{beneficiary.physicalAddress.city}</Typography></Container>
											<Container>State: <Typography variant="span" color="text.secondary">{beneficiary.physicalAddress.state}</Typography></Container>
											<Container>Zip: <Typography variant="span" color="text.secondary">{beneficiary.physicalAddress.zip}</Typography></Container>
										</Grid>
									)
								})
							}
						</Grid>
					</CardContent>
					<CardActions>
						<Button
							size="small" variant="contained" color="primary" onClick={() => setCurrentStep(parseInt(5))}>
							Edit
						</Button>
					</CardActions>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardActions>
						<Button size="small" variant="contained" color="primary" onClick={() => makeRequest({ isComplete: true, ...data })}>
							Submit
						</Button>
					</CardActions>
				</Card>
			</Grid>
		</Grid>
	);
};