import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField, Button, Grid, LinearProgress, Typography, Collapse, Alert, AlertTitle } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { useState, useEffect } from "react";

const stepTwoValidationSchema = Yup.object({
	gross: Yup.number().min(1, 'Required').required("Required").label("Gross salary"),
	// socialDeduction: Yup.number().min(1, 'Invalid').required().label("Social Security deduction"),
	socialDeduction: Yup.number().min(1, 'Required').required('Required')
		.test({
			name: 'max',
			exclusive: false,
			params: {},
			message: 'Can\'t be greater than Gross Salary',
			test: function (value) {
				// You can access the price field with `this.parent`.
				return parseFloat(value) <= parseFloat(this.parent.gross)
			},
		}),
	net: Yup.number().min(0, 'Invalid')
	// island: Yup.string().required().label("Island"),
});

const Income = (props) => {
	const [fieldValues, setfieldValues] = useState(null);
	const [totalOther, settotalOther] = useState(0);
	const [showError, setError] = useState({ error: false, msg: '' });
	const [netIncome, setNetIncome] = useState(0);
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});

	const handleSubmit = async (values) => {
		await sleep(100);
		//values extra income before submit
		if (!validateOtherIncome(true)) {
			if (showError.error) setError({ error: false, msg: '' });
			if (netIncome === 0){
				setError({ error: true, msg: 'Social Security Deductions can\'t be same as Gross Salary' });
				setformSubmitting(false);
			}
			else {
				values.net = netIncome;
				props.next({ income: values });
			}
		} else setformSubmitting(false);
	}

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if (!validateOtherIncome(true)) {
					if (showError.error) setError({ error: false, msg: '' });
					if (netIncome === 0){
						setError({ error: true, msg: 'Social Security Deductions can\'t be same as Gross Salary' });
						setformSubmitting(false);
					}
					else {
						values.net = netIncome;
						if(type === 'prev') props.prev({ income: values }); //go to previous step
						else props.next({ income: values }, true); // go to review step
					}
				} else setformSubmitting(false);
			} else {
				for(const field in validated) formikProps.setFieldTouched(field, true, true); //found errors so loop through object and set errors
				setformSubmitting(false);
			} 
		} else if(type === 'prev') props.prev({ income: values });
	}

	function validateOtherIncome(showAlert = false) {
		let foundEmpty = false;

		//check if any field is empty
		if (fieldValues.otherIncome.length > 0) {
			fieldValues.otherIncome.forEach(income => {
				if (income.amount < 1) foundEmpty = true;
				if (income.source === '') foundEmpty = true;
			})
		}

		if (foundEmpty && showAlert) setError({ error: true, msg: 'Please make sure all fields populated before proceeding' });
		return foundEmpty;
	}

	function addOtherIncome() {
		//set new blank data into otherIncome array field
		let tempIncome = fieldValues.otherIncome;
		tempIncome.push({ amount: 0, source: '' });
		setfieldValues((prev) => ({
			...prev,
			otherIncome: tempIncome
		}));
	}

	function removeOtherIncome(index) {
		let tempIncome = fieldValues.otherIncome;
		tempIncome = tempIncome.filter((income, i) => {
			return index !== i && income
		});

		setfieldValues((prev) => ({ ...prev, otherIncome: tempIncome }))
	}

	//Since im taking the formStore.income data setting it as state and using it for initial values
	// I edit in that format and update state
	function handleOtherIncome(e) {
		let tempFields = fieldValues.otherIncome;

		let targetOptions = e.target.id.split("_");
		let field = targetOptions[0], id = parseFloat(targetOptions[1]);
		tempFields[id][field] = props.sanitizeInput({ fieldValue: e.target.value }).fieldValue;

		setfieldValues((prev) => ({ ...prev, otherIncome: tempFields }));
	}

	function handleIncome(e) {
		let thisField = e.target.id,
			fieldValue = e.target.value === null || e.target.value === '' ? 0 : parseFloat(e.target.value);

		// console.log({thisField, fieldValue});
		if (fieldValue !== null) setfieldValues((prev) => ({ ...prev, [thisField]: fieldValue }));
	}

	//everytime the data change from the props is changes the state
	useEffect(() => {
		console.log('setting fieldval 1st', props.data);
		if(props.data.otherIncome && props.data.otherIncome.length === 0) props.data.otherIncome.push({ amount: 0, source: '' });
		setfieldValues(props.data);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (fieldValues !== null) {
			let gross = fieldValues.gross !== null ? fieldValues.gross : 0;
			let social = fieldValues.socialDeduction !== null ? fieldValues.socialDeduction : 0;

			let net = gross - social;
			// console.log({net});
			if (net !== netIncome) setNetIncome(net);
		}
		if (fieldValues !== null && fieldValues.otherIncome.length > 0) {
			let total = 0;
			fieldValues.otherIncome.forEach(income => {
				if (parseFloat(income.amount) > 0) total += parseFloat(income.amount);
			});

			settotalOther(total.toFixed(2));
		} else settotalOther(0.00);
	}, [fieldValues, netIncome]);

	const btnColumnSize = props.completed ? 4 : 6;

	if (fieldValues === null) return <LinearProgress />
	return (
		<Formik enableReinitialize={true} validationSchema={stepTwoValidationSchema} initialValues={fieldValues} onSubmit={handleSubmit} >
			{({ values, touched, errors, setFieldValue, handleBlur, validateForm, setFieldTouched, isSubmitting }) => (
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Collapse in={showError.error}>
								<Alert severity="error" >
									<AlertTitle>Error</AlertTitle>
									{showError.msg}
								</Alert>
							</Collapse>
						</Grid>
						<Grid item xs={12}>
							<p><b>Main income</b></p>
						</Grid>
						<Grid item xs={12} md={4}>
							<Field error={errors && errors.gross ? true : false} value={values.gross} label="Gross (EC $)" onChange={handleIncome} fullWidth component={TextField} type="number" name="gross" id="gross" />
							<ErrorMessage className="errMsg" component="span" name="gross" />
						</Grid>
						<Grid item xs={12} md={4}>
							<Field error={errors && errors.socialDeduction ? true : false} value={values.socialDeduction} label="Social Security Deductions (EC $)" onChange={handleIncome} fullWidth component={TextField} type="number" name="socialDeduction" id="socialDeduction" />
							<ErrorMessage className="errMsg" component="span" name="socialDeduction" />
						</Grid>
						<Grid item xs={12} md={4}>
							{/* <p>Net Income (EC $) {values.net}</p> */}
							<Field disabled value={netIncome !== null ? netIncome : 0} label="Net (EC $)" fullWidth component={TextField} type="number" name="net" id="net" />
							<ErrorMessage className="errMsg" component="span" name="net" />
						</Grid>
						<Grid item xs={12}>
							<p><b>Other income (optional)</b></p>
						</Grid>
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Source</TableCell>
											<TableCell align="right">Amount (EC $)</TableCell>
											<TableCell align="right">Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											values.otherIncome.length > 0 &&
											values.otherIncome.map((data, index) => {
												return (
													<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
														<TableCell component="th" scope="row">
															<Field value={data.source} onChange={handleOtherIncome} fullWidth component={TextField} name={"source_" + index} id={"source_" + index} />
														</TableCell>
														<TableCell align="right">
															<Field value={data.amount} onChange={handleOtherIncome} fullWidth component={TextField} type="number" name={"amount_" + index} id={"amount_" + index} />
														</TableCell>
														<TableCell align="right">
															<Button variant="text" startIcon={<RemoveCircle />} onClick={() => removeOtherIncome(index)}></Button>
														</TableCell>
													</TableRow>
												)
											})
										}
										<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
											{/* <TableCell component="th" scope="row">
												Total
											</TableCell> */}
											<TableCell component="th" scope="row" colSpan={2}>
												<Typography align="right">EC ${totalOther}</Typography>
											</TableCell>
											<TableCell align="right" colSpan={2}>
												<Button variant="text" startIcon={<AddCircle />} onClick={() => addOtherIncome()}></Button>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, {validateForm, setFieldTouched})}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" type="submit" >
								Next
							</Button>
						</Grid>
						{
							props.completed &&
							<Grid item xs={btnColumnSize}>
								<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
									Review
								</Button>
							</Grid>
						}
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Income