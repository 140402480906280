import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Grid, LinearProgress, Collapse, Alert, AlertTitle, Typography } from '@mui/material'
import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const stepTwoValidationSchema = Yup.object({
	address: Yup.string().required().label("Home Address"),
	city: Yup.string().required().label("City"),
	state: Yup.string().required().label("State"),
	// island: Yup.string().required().label("Island"),
});

const Liabilities = (props) => {
	const [liabilityValues, setLiabilities] = useState(null);
	const [total, settotal] = useState(0);
	const [showError, setError] = useState({ error: false, msg: '' });
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});

	const handleSubmit = async (values) => {
		await sleep(100);
		if (!validateOtherIncome(true)) {
			if (showError.error) setError({ error: false, msg: '' });
			props.next({ liabilities: liabilityValues });
		} else setformSubmitting(false);
	};

	const handleCustomSubmit = async (type, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if (!validateOtherIncome(true)) {
					if (showError.error) setError({ error: false, msg: '' });
					if(type === 'prev') props.prev({ liabilities: liabilityValues }); //go to previous step
					else props.next({ liabilities: liabilityValues }, true); // go to review step
				} else setformSubmitting(false);
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true); 
				setformSubmitting(false);
			}
		} else if(type === 'prev') props.prev({ liabilities: liabilityValues });
	}

	function validateOtherIncome(showAlert = false) {
		let foundEmpty = false;

		//check if any field is empty
		if (liabilityValues.length > 0) {
			liabilityValues.forEach(liability => {
				if (liability.liability === '') foundEmpty = true;
				if (liability.particulars === '') foundEmpty = true;
				if (liability.presentBalance < 1) foundEmpty = true;
				if (liability.monthlyPayment < 1) foundEmpty = true;
			})
		}

		if (foundEmpty && showAlert) setError({ error: true, msg: 'Please make sure all fields populated before proceeding' });
		return foundEmpty;
	}

	function addOtherLiability() {
		// let foundEmpty = validateOtherIncome();

		// if (!foundEmpty) {
		//set new blank data into otherIncome array field
		let tempLiability = liabilityValues;
		tempLiability.push({ liability: '', particulars: '', presentBalance: 0, monthlyPayment: 0 });
		setLiabilities(() => [...tempLiability]);
		// setshowNewField(true);
		// } else alert('Please make sure all fields populated before adding another liability');
	}

	function removeOtherLiability(index) {
		let tempLiability = liabilityValues;
		tempLiability = tempLiability.filter((liability, i) => {
			return index !== i && liability
		});

		setLiabilities(() => tempLiability)
	}

	//Formik only updates the initialvalue from the field name and the global cache isnt set up with all fields on first level
	function handleOtherLiability(e) {
		let tempFields = liabilityValues;

		let targetOptions = e.target.id.split("_");
		let field = targetOptions[0], id = parseInt(targetOptions[1]);
		tempFields[id][field] = props.sanitizeInput({ fieldValue: e.target.value }).fieldValue;

		setLiabilities(() => [...tempFields]);
	}

	//everytime the data change from the props is changes the state
	useEffect(() => {
		console.log('setting fieldval 1st', props.data);
		if(props.data.length === 0) props.data.push({ liability: '', particulars: '', presentBalance: 0, monthlyPayment: 0 });
		setLiabilities(props.data);
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (liabilityValues !== null && liabilityValues.length > 0) {
			let total = 0;
			liabilityValues.forEach(income => {
				if (parseFloat(income.monthlyPayment) > 0) total += parseFloat(income.monthlyPayment);
			});

			settotal(total.toFixed(2));
		} else settotal(0.00);
	}, [liabilityValues]);

	const btnColumnSize = props.completed ? 4 : 6;

	if (liabilityValues === null) return <LinearProgress />
	return (
		<Formik enableReinitialize={true} validationSchemaz={stepTwoValidationSchema} initialValues={liabilityValues} onSubmit={handleSubmit} >
			{({ values, handleChange, touched, errors, validateForm, setFieldTouched, isSubmitting }) => (
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Collapse in={showError.error}>
								<Alert severity="error" >
									<AlertTitle>Error</AlertTitle>
									{showError.msg}
								</Alert>
							</Collapse>
						</Grid>
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Liability</TableCell>
											<TableCell align="right">Particulars</TableCell>
											<TableCell align="right">Present Balance (EC $)</TableCell>
											<TableCell align="right">Monthly Payment (EC $)</TableCell>
											<TableCell align="right">Action</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											values.length > 0 &&
											values.map((data, index) => {
												return (
													<TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
														<TableCell component="th" scope="row">
															<Field value={data.liability} onChange={handleOtherLiability} fullWidth component={TextField} name={"liability_" + index} id={"liability_" + index} />
														</TableCell>
														<TableCell align="right">
															<Field value={data.particulars} onChange={handleOtherLiability} fullWidth component={TextField} name={"particulars_" + index} id={"particulars_" + index} />
														</TableCell>
														<TableCell align="right">
															<Field value={data.presentBalance} onChange={handleOtherLiability} fullWidth component={TextField} type="number" name={"presentBalance_" + index} id={"presentBalance_" + index} />
														</TableCell>
														<TableCell align="right">
															<Field value={data.monthlyPayment} onChange={handleOtherLiability} fullWidth component={TextField} type="number" name={"monthlyPayment_" + index} id={"monthlyPayment_" + index} />
														</TableCell>
														<TableCell align="right">
															<Button variant="text" startIcon={<RemoveCircleIcon />} onClick={() => removeOtherLiability(index)}></Button>
														</TableCell>
													</TableRow>
												)
											})
										}
										<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
											{/* <TableCell component="th" scope="row">
												Add new liability
											</TableCell> */}
											<TableCell component="th" scope="row" colSpan={4}>
												<Typography align="right">EC ${total}</Typography>
											</TableCell>
											<TableCell align="right" colSpan={4}>
												<Button variant="text" startIcon={<AddCircleIcon />} onClick={() => addOtherLiability()}></Button>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', {validateForm, setFieldTouched})}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" type="submit" >
								Next
							</Button>
						</Grid>
						{
							props.completed &&
							<Grid item xs={btnColumnSize}>
								<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', {validateForm, setFieldTouched})} >
									Review
								</Button>
							</Grid>
						}
						
					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default Liabilities