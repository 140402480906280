import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Select, TextField, Button, Grid, InputLabel, FormControl, Stack, Typography, Switch, IconButton, LinearProgress, Tooltip, MenuItem, Collapse, Alert } from '@mui/material'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import { DesignationType } from "../../../scripts/enums";
import { useEffect, useState } from "react";
import { membershipInit } from "../../../scripts/initFormData";
import { cloneDeep } from 'lodash';

// set up dropdown list values
let MenuItems = [<MenuItem key="empty" value="0">Choose Type</MenuItem>];
DesignationType.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})

// need an easy reference to a single beneficiaries object from the main init data object
// deep clone it to avoid reference issues where necessary
const initBeneficiary = membershipInit.beneficiaries[0]

// nested objects in the beneficiaries array need special handling when updating the corresponding field values; these arrays aid that
const physicalAddressFields = Object.keys(initBeneficiary.physicalAddress)

const percentageErrorDefault = { error: false, msg: '' }

const Beneficiaries = (props) => {
	const { sanitizeInput } = props;
	const [fieldValues, setfieldValues] = useState(null);
	const [formErrors, setFormErrors] = useState({});
	const [percentageError, setPercentageError] = useState(percentageErrorDefault);
	const [formSubmitting, setformSubmitting] = useState(false);

	const beneficiariesValidation = Yup.object().shape({
		designationType: Yup.number().min(1, "Required").required("Required"),
		beneficiaries: Yup.array(Yup.object().shape({
			name: Yup.string().required("Required"),
			contactNumber: Yup.string().digitsOnly("Invalid"),
			percentage: Yup.number().min(0.01, "Enter a value between 0 and 100").max(100, "Enter a value between 0 and 100").required("Required"),
			physicalAddress: Yup.object({
				street: Yup.string().required("Required"),
				city: Yup.string().required("Required"),
				state: Yup.string().required("Required"),
				zip: Yup.string().required("Required")
			})
		}))
	});

	const validatePercentageTotal = () => {
		let totalPercentage = 0
		// tally percentages
		const valid = fieldValues.beneficiaries.every(beneficiary => {
			totalPercentage += parseFloat(beneficiary.percentage)
			// console.log('running percentage', totalPercentage)
			return totalPercentage <= 100
		})

		// show / hide error notice
		if (!valid) setPercentageError({ error: true, msg: 'Combined percentages for beneficiaries exceed 100%' })
		else if (percentageError.error) setPercentageError(percentageErrorDefault)

		return valid
	}

	const handleCustomSubmit = async (type, values, formikProps) => {
		setformSubmitting(true)
		let formValid = true, errors = {}

		// validate beneficiaries fields
		if(values.hasBeneficiaries && (type === 'next' || props.completed)){
			errors = await formikProps.validateForm()
			formValid = Object.keys(errors).length === 0

			// if percentages are not valid, prevent going to next step
			if (!validatePercentageTotal())
				formValid = false

			// console.log('errors: ', errors)
			// console.log('formValid: ', formValid)
		}
		
		if (formValid) {
			let dataToSend = {
				hasBeneficiaries: values.hasBeneficiaries,
				designationType: values.hasBeneficiaries ? values.designationType : 0,
				beneficiaries: values.hasBeneficiaries ? values.beneficiaries : []
			}
	
			if(type === 'prev') props.prev(dataToSend);
			else props.next(dataToSend, true);
		} else {
			setFormErrors(errors)
			setformSubmitting(false);
		}
	}

	function addBeneficiary() {
		let temp = fieldValues.beneficiaries;
		temp.push(cloneDeep(initBeneficiary));
		setfieldValues((prev) => ({ ...prev, beneficiaries: temp }))
	}
	
	function removeBeneficiary(index) {
		let temp = fieldValues.beneficiaries;
		temp = temp.filter((beneficiary, i) => {
			return index !== i && beneficiary
		});

		// remove corresponding error messages if they exist so they don't "transfer down"
		// Eg: removing a block that hadif the first block had errors while the second, removing the first one shows its errors on the second which has now taken its place
		let errTemp = formErrors.beneficiaries;

		// process just indexes that are within the bounds of the errors array
		if (errTemp && index < errTemp.length) {
			errTemp = errTemp.filter((error, i) => {
				return index !== i
			});

			setFormErrors((prev) => ({ ...prev, beneficiaries: errTemp }))
		}

		setfieldValues((prev) => ({ ...prev, beneficiaries: temp }))
	}

	// Formik's handleChange only updates top level field names in its `values` object (whether they exist already or not)
	// since our data is nested inside an array, we need our own custom change handler
	function handleBeneficiaryChange(e, doSanitize = false) {
		let temp = fieldValues.beneficiaries;

		let targetOptions = e.target.id.split("_");
		let field = targetOptions[0], index = parseInt(targetOptions[1]);

		// the handler is called both onChange and onBlur, but only clean the field onBlur
		const newFieldValue = doSanitize ? sanitizeInput(e.target.value) : e.target.value

		if (physicalAddressFields.indexOf(field) >= 0)
			temp[index]["physicalAddress"][field] = newFieldValue
		else 
			temp[index][field] = newFieldValue;
		
		setfieldValues((prev) => ({ ...prev, beneficiaries: temp }));
	}

	// using this in place of Formik's handleChange so that our state can be kept in sync when changes are made by beneficiary fields
	function handleCustomChange(e) {
		let thisField = e.target.id
		let fieldValue = e.target.type && e.target.type === 'checkbox' ? e.target.checked : e.target.value

		setfieldValues((prev) => ({ ...prev, [thisField]: fieldValue }))
	}

	// push an empty beneficiary object if array empty
	useEffect(() => {
		if(props.data.beneficiaries && props.data.beneficiaries.length === 0) props.data.beneficiaries.push(cloneDeep(initBeneficiary));
		setfieldValues(props.data);
		//eslint-disable-next-line
	}, []);

	const btnColumnSize = props.completed ? 4 : 6;

	if (fieldValues === null) return <LinearProgress />
	return (
		<Formik enableReinitialize={true} validationSchema={beneficiariesValidation} initialValues={fieldValues} >
			{
				({ values, validateForm, setFieldTouched }) => (
					<Form autoComplete="off">
						<Grid container spacing={2}>
							<Grid item xs={12} md={values.hasBeneficiaries ? 6 : 12}>
								<p>Do you wish to designate any beneficiaries to your account?</p>
								<Stack direction="row" spacing={1} alignItems="center">
									<Typography>No</Typography>
									<Field
										component={Switch} name="hasBeneficiaries" id="hasBeneficiaries" onChange={handleCustomChange} checked={values.hasBeneficiaries ? true : false} />
									<Typography>Yes</Typography>
								</Stack>
							</Grid>
							{
								values.hasBeneficiaries &&
								<>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth>
											<InputLabel shrink htmlFor="designationType">Designation Type</InputLabel>
											<Field
												error={formErrors.designationType ? true : false}
												onChange={(e) => handleCustomChange({target:{id: 'designationType', name: 'designationType', value: e.target.value}})}
												fullWidth component={Select} label="Designation Type" name="designationType" id="designationType" value={values.designationType}>
												{MenuItems}
											</Field>
											{ formErrors.designationType && <span className="errMsg">{formErrors.designationType}</span> }
										</FormControl>
									</Grid>
									<Grid item xs={12}>
										<Collapse in={percentageError.error}>
											<Alert severity="error">{percentageError.msg}</Alert>
										</Collapse>
									</Grid>
									{
										values.beneficiaries.length > 0 &&
										values.beneficiaries.map((beneficiary, index) => {
											// define which fields have errors
											let beneficiaryErrors = formErrors.beneficiaries ? formErrors.beneficiaries[index] : undefined

											// nested object errors (govIDs are handled separately below)
											let addressErrors = beneficiaryErrors ? beneficiaryErrors.physicalAddress : undefined

											return (
												<Grid container item spacing={2} key={index} marginBottom={2}>
													<Grid item xs={12}>
														<Stack direction="row" spacing={1} alignItems="center">
															<h2>Beneficiary {values.beneficiaries.length > 1 && `#${index + 1}`}</h2>
															{
																values.beneficiaries.length > 1 &&
																<Tooltip title="Remove Beneficiary" placement="top">
																	<IconButton aria-label="remove beneficiary" component="span" size="large" color="error" onClick={() => removeBeneficiary(index)}><GroupRemoveIcon sx={{ fontSize: '1.3rem' }} /></IconButton>
																</Tooltip>
															}
														</Stack>
													</Grid>
													<Grid container item spacing={2} marginBottom={1}>
														<Grid item xs={12} md={4}>
															<Field 
																error={beneficiaryErrors && beneficiaryErrors.name ? true : false}
																onBlur={(_e) => handleBeneficiaryChange(_e, true)}
																onChange={handleBeneficiaryChange}
																value={beneficiary.name}
																fullWidth size="small" component={TextField} label="Name" name={"name_" + index} id={"name_" + index}
															/>
															{ beneficiaryErrors && beneficiaryErrors.name && <span className="errMsg">{beneficiaryErrors.name}</span> }
														</Grid>
														<Grid item xs={12} md={4}>
															<Field
																error={beneficiaryErrors && beneficiaryErrors.contactNumber ? true : false}
																onChange={handleBeneficiaryChange}
																value={beneficiary.contactNumber}
																fullWidth size="small" component={TextField} label="Contact Number (optional)" name={"contactNumber_" + index} id={"contactNumber_" + index}
															/>
															{ beneficiaryErrors && beneficiaryErrors.contactNumber && <span className="errMsg">{beneficiaryErrors.contactNumber}</span> }
														</Grid>
														<Grid item xs={12} md={4}>
															<Field
																error={beneficiaryErrors && beneficiaryErrors.percentage ? true : false}
																onChange={handleBeneficiaryChange}
																value={beneficiary.percentage}
																fullWidth size="small" component={TextField} label="Percentage (%)" type="number" name={"percentage_" + index} id={"percentage_" + index}
															/>
															{ beneficiaryErrors && beneficiaryErrors.percentage && <span className="errMsg">{beneficiaryErrors.percentage}</span> }
														</Grid>
													</Grid>

													<Grid container item spacing={2} marginBottom={1}>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={addressErrors && addressErrors.street ? true : false}
																onBlur={(_e) => handleBeneficiaryChange(_e, true)}
																onChange={handleBeneficiaryChange}
																value={beneficiary.physicalAddress.street}
																fullWidth size="small" component={TextField} label="Street Address" name={"street_" + index} id={"street_" + index}
															/>
															{ addressErrors && addressErrors.street && <span className="errMsg">{addressErrors.street}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={addressErrors && addressErrors.city ? true : false}
																onBlur={(_e) => handleBeneficiaryChange(_e, true)}
																onChange={handleBeneficiaryChange}
																value={beneficiary.physicalAddress.city}
																fullWidth size="small" component={TextField} label="City" name={"city_" + index} id={"city_" + index}
															/>
															{ addressErrors && addressErrors.city && <span className="errMsg">{addressErrors.city}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={addressErrors && addressErrors.state ? true : false}
																onBlur={(_e) => handleBeneficiaryChange(_e, true)}
																onChange={handleBeneficiaryChange}
																value={beneficiary.physicalAddress.state}
																fullWidth size="small" component={TextField} label="State" name={"state_" + index} id={"state_" + index}
															/>
															{ addressErrors && addressErrors.state && <span className="errMsg">{addressErrors.state}</span> }
														</Grid>
														<Grid item xs={12} sm={6} md={3}>
															<Field
																error={addressErrors && addressErrors.zip ? true : false}
																onBlur={(_e) => handleBeneficiaryChange(_e, true)}
																onChange={handleBeneficiaryChange}
																value={beneficiary.physicalAddress.zip}
																fullWidth size="small" component={TextField} label="Zip" name={"zip_" + index} id={"zip_" + index}
															/>
															{ addressErrors && addressErrors.zip && <span className="errMsg">{addressErrors.zip}</span> }
														</Grid>
													</Grid>
												</Grid>
											)
										})
									}
									<Grid item xs={12} sx={{ mb: 2 }}>
										<Grid container direction="column" alignItems="center" justifyContent="center">
											<Tooltip title="Add Beneficiary" placement="top">
												<IconButton aria-label="add beneficiary" component="span" size="large" color="success" onClick={addBeneficiary}><GroupAddIcon sx={{ fontSize: '2.5rem' }} /></IconButton>
											</Tooltip>
										</Grid>
									</Grid>
								</>
							}
							<Grid item xs={btnColumnSize}>
								<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, {validateForm, setFieldTouched})}>
									Previous
								</Button>
							</Grid>
							<Grid item xs={btnColumnSize}>
								<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('next', values, {validateForm, setFieldTouched})}>
									Next
								</Button>
							</Grid>
							{
								props.completed &&
								<Grid item xs={btnColumnSize}>
									<Button disabled={formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
										Review
									</Button>
								</Grid>
							}
						</Grid>
					</Form>
				)
			}
		</Formik>
	);
};

export default Beneficiaries