import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField, Button, Grid, Switch, Stack, Typography, Alert } from '@mui/material'
import { useState } from "react";

const stepOneValidationSchema = Yup.object().shape({
	firstName: Yup.string().required("Required").label("Invalid"),
	lastName: Yup.string().required("Required").label("Invalid"),
	email: Yup.string().required("Required").email("Invalid").label("Email Address"),
	shareAccountNumber: Yup.number().when('isMember', {
		is: true,
		then: Yup.number().min(1, "Required").required("Required"),
		otherwise: Yup.number()
	}).label("Share Account Number")
});

export const Personal = (props) => {
	const { sanitizeInput } = props;
	const [formSubmitting, setformSubmitting] = useState(false);
	// const [emailDisabled, setemailDisabled] = useState(false);

	// 
	// useEffect(() => {
	// 	console.log('personal', props.data)
	// 	if(props.data.email !== '' && props.data.email === props.data.confirmEmail) setemailDisabled(true);
	// 	else setemailDisabled(false);
	// }, [props.data])
	

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});
	
	const handleSubmit = async (values) => {
		await sleep(100);
		// delete values.confirmEmail;
		props.next(values);
	};

	/**
  * Custom field change handler. Sanitizes input before Formik does its thing
  * @param {*} event the DOM event is needed for `formikProps.handleBlur`
  * @param {*} formikProps `{ setFieldValue, handleBlur }`
  */
	const _handleFieldChange = (event, formikProps) => {
		// run user input through our sanitization logic to safeguard against XSS attacks
		const fieldValue = event.target.value;
		const fieldName = event.target.id;
		const cleanValue = sanitizeInput({ fieldValue });

		// console.log({fieldValue, fieldName: event.target})
		// update field with clean value, then run formik's change handler to carry on regular processing (such as validation)
		formikProps.setFieldValue(fieldName, cleanValue.fieldValue).then(resp => formikProps.handleBlur(event));
	}

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev(values); //go to previous step
				else props.next(values, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true);
				setformSubmitting(false);
			} 
		} else if(type === 'prev') props.prev(values);
	}

	return (
		<Formik enableReinitialize={true} validationSchema={stepOneValidationSchema} initialValues={props.data} onSubmit={handleSubmit} >
			{
				({ values, handleChange, handleBlur, touched, errors, setFieldValue, isSubmitting, validateForm, setFieldTouched }) => (
					<Form autoComplete="off">
					
						<Grid container spacing={2}>
							<Grid item xs={12} md={4}>
								<Field
									error={touched.firstName && errors.firstName ? true : false}
									onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="First Name" name="firstName" id="firstName" onChange={handleChange} value={values.firstName} />
								<ErrorMessage className="errMsg" component="span" name="firstName" />
							</Grid>
							<Grid item xs={12} md={4}>
								<Field
									error={touched.lastName && errors.lastName ? true : false}
									onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="Last Name" name="lastName" id="lastName" onChange={handleChange} value={values.lastName} />
								<ErrorMessage className="errMsg" component="span" name="lastName" />
							</Grid>
							<Grid item xs={12} md={4}>
								<Field 
									error={touched.email && errors.email ? true : false}
									onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="Email" name="email" id="email" onChange={handleChange} value={values.email} />
								<ErrorMessage className="errMsg" component="span" name="email" />
							</Grid>
							{/* <Grid item xs={6}>
								<Field disabled={emailDisabled}
									error={touched.confirmEmail && errors.confirmEmail ? true : false}
									onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="Confirm Email" name="confirmEmail" id="confirmEmail" onChange={handleChange} value={values.confirmEmail} />
								<ErrorMessage className="errMsg" component="span" name="confirmEmail" />
							</Grid> */}
							<Grid item xs={12}>
								<Alert severity="info">For your convenience, we automatically save your application progress. You are free to come back anytime within 30 days of your last update to this application. Once you click Next, we will send you an email with your access details.</Alert>
							</Grid>
							<Grid item xs={6}>
								<p>Are you an existing member?</p>
								<Stack direction="row" spacing={1} alignItems="center">
									<Typography>No</Typography>
									<Field
										component={Switch} name="isMember" id="isMember" onChange={handleChange} checked={values.isMember ? true : false} />
									<Typography>Yes</Typography>
								</Stack>
							</Grid>
							{
								!values.isMember ?
								<Grid item xs={6}>
									<p>Do you have a Co-Applicant?</p>
									<Stack direction="row" spacing={1} alignItems="center">
										<Typography>No</Typography>
										<Field
											component={Switch} name="hasCoApplicant" id="hasCoApplicant" onChange={handleChange} checked={values.hasCoApplicant ? true : false} />
										<Typography>Yes</Typography>
									</Stack>
								</Grid>
								:
								<Grid item xs={12} md={4}>
									<Field
										error={touched.shareAccountNumber && errors.shareAccountNumber ? true : false}
										fullWidth component={TextField} type="number" label="Share Account Number" name="shareAccountNumber" id="shareAccountNumber" onChange={handleChange} value={values.shareAccountNumber} />
									<ErrorMessage className="errMsg" component="span" name="shareAccountNumber" />
								</Grid>
							}
							<Grid item xs={6}>
								<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
									Next
								</Button>
							</Grid>
							{
								props.completed &&
								<Grid item xs={6}>
									<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
										Review
									</Button>
								</Grid>
							}
						</Grid>
					</Form>
				)
			}
		</Formik>
	);
};

export default Personal