import React from "react";
import {  Navigate, useNavigate, useParams } from "react-router-dom";
import Membership from './Membership'
import MemberLoan from './MemberLoan'
import Shares from './Shares'

const AppFormStart = () => {
    const { urlSlug } = useParams()
    const navigate = useNavigate()

    // route to services if no slug exists
    React.useEffect(() => {
        if (urlSlug == null) {
            navigate('/services')
            return null
        }
        // eslint-disable-next-line
    }, [])

    // render the appropriate application form
    switch(urlSlug) {
        case 'membership':
            return <Membership />
        case 'member-loan':
            return <MemberLoan />
        case 'shares':
            return <Shares />
        default:
            return <Navigate to="/apply/membership" />
    }
}

export default AppFormStart;