// this file consists of initialized application form data for each service
// these objects are useful for giving a sense of the shape of the data, resetting the forms to default value, and so forth

import { MaritalStatus, OwnershipStatus, IDType, EmploymentType, ReferenceType, SettlementMethod, DesignationType } from "./enums";
//eslint-disable-next-line
import { COST_PER_SHARE, MININUM_MEMBER_SHARES } from "./constants";

export const membershipInit = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    dob: null,
    motherMaidenName: '',
    maritalStatus: MaritalStatus.single.value,
    employer: '',
    occupation: '',
    // shares: {
    //     amount: MININUM_MEMBER_SHARES
    // },
    // sharesRequested: MININUM_MEMBER_SHARES,
    physicalAddress: {
        street: '',
        city: '',
        state: '',
        zip: ''
    },
    phone: {
        home: '',
        mobile: '',
        work: '',
        other: ''
    },
    govIDs: [
        {
            type: IDType.national.value,
            number: '',
            issueDate: null,
            expiryDate: null
        },
        {
            type: IDType.passport.value,
            number: '',
            issueDate: null,
            expiryDate: null
        },
    ],
    isSoleOwner: true,
    jointOwners: [
        {
            firstName: '',
            lastName: '',
            email: '',
            dob: null,
            motherMaidenName: '',
            maritalStatus: MaritalStatus.single.value,
            employer: '',
            occupation: '',
            physicalAddress: {
                street: '',
                city: '',
                state: '',
                zip: ''
            },
            phone: {
                home: '',
                mobile: '',
                work: '',
                other: ''
            },
            govIDs: [
                {
                    type: IDType.national.value,
                    number: '',
                    issueDate: null,
                    expiryDate: null
                },
                {
                    type: IDType.passport.value,
                    number: '',
                    issueDate: null,
                    expiryDate: null
                },
            ]
        }
    ],
    hasBeneficiaries: false,
    designationType: DesignationType.pod.value,
    beneficiaries: [
        {
            name: '',
            contactNumber: '',
            percentage: 1,
            physicalAddress: {
                street: '',
                city: '',
                state: '',
                zip: ''
            },
        }
    ],
    services: [],
    reviewStep: false,
    step: 0,
    updated: null
}

export const memberLoanInit = {
    id: '',
    memberID: '',
    accountNumber: 0,
    loanPurpose: '',
    loanAmount: 0,
    hasCoApplicant: false,
    firstName: '',
    lastName: '',
    email: '',
    dob: null,
    maritalStatus: MaritalStatus.single.value,
    dependents: 0,
    physicalAddress: {
        street: '',
        city: '',
        state: '',
        zip: '',
        time: 1,
        ownership: OwnershipStatus.owned.value
    },
    phone: {
        home: '',
        mobile: '',
        work: '',
        other: ''
    },
    govIDs: [
        {
            type: IDType.national.value,
            number: '',
            issueDate: null,
            expiryDate: null
        },
        {
            type: IDType.passport.value,
            number: '',
            issueDate: null,
            expiryDate: null
        },
    ],
    currentEmployment: {
        employmentType: EmploymentType.employed.value,
        businessType: '',
        employer: '',
        employerAddress: '',
        jobTitle: '',
        timeAtEmployer: 1,
        supervisor: ''
    },
    previousEmployment: {
        employer: '',
        address: '',
        startDate: null,
        endDate: null,
        jobTitle: ''
    },
    income: {
        gross: 0,
        socialDeduction: 0,
        net: 0,
        otherIncome: [
            {
                amount: 0,
                source: ''
            }
        ]
    },
    liabilities: [
        {
            liability: '',
            particulars: '',
            presentBalance: 0,
            monthlyPayment: 0
        }
    ],
    expenses: [
        {
            expense: '',
            particulars: '',
            amount: 0
        }
    ],
    assets: [
        {
            asset: '',
            particulars: '',
            marketValue: 0,
            pledgedAsCollateral: false
        }
    ],
    hasOutstandingJudgments: false,
    isPartyInLawsuit: false,
    hadForeclosure: false,
    isGuarantor: false,
    guarantorFor: '',
    references: [
        {
            type: ReferenceType.nearestRelative.value,
            name: '',
            address: '',
            contactNumber: '',
            relationship: ''
        },
        {
            type: ReferenceType.nextOfKin.value,
            name: '',
            address: '',
            contactNumber: '',
            relationship: ''
        },
    ],
    reviewStep: false,
    step: 0,
    updated: null
}

export const emergencyLoanInit = {
    id: '',
    firstName: '',
}

export const sharesInit = {
    id: '',
    isMember: false,
    shareAccountNumber: 0,
    hasCoApplicant: false,
    firstName: '',
    lastName: '',
    email: '',
    sharesRequested: 1,
    purchaseTotal: COST_PER_SHARE,
    settlementMethod: SettlementMethod.cash.value,
    savingsAccountNumber: 0,
    physicalAddress: {
        street: '',
        city: '',
        state: '',
        zip: ''
    },
    nationality: '',
    reviewStep: false,
    step: 0,
    updated: null
}