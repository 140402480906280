import * as Yup from "yup";
//eslint-disable-next-line
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField, Button, Grid } from '@mui/material'
import { useState } from "react";

const stepOneValidationSchema = Yup.object().shape({
	memberID: Yup.string().required("Required").label("Membership ID"),
	accountNumber: Yup.number()
		.test({
			name: 'max',
			exclusive: false,
			params: {},
			message: 'Required',
			test: function (value) {
				let idEmpty = this.parent.memberID === "" || this.parent.memberID === 0 || this.parent.memberID === undefined;
				let numberEmpty = value === "" || value === 0 || value === undefined;
				if (idEmpty) return true;
				else {
					if (numberEmpty) return false;
				}
				// console.log({idEmpty, numberEmpty, value});
				return true;
			},
		}),
	loanPurpose: Yup.string().required("Required").label("Loan Purpose"),
	loanAmount: Yup.number().required("Required").min(100, 'Minimum loan amount: EC $100')
});

export const Loan = (props) => {
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});
	
	const handleSubmit = async (values) => {
		await sleep(100);
		props.next(values);
	};

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev(values); //go to previous step
				else props.next(values, true); // go to review step
			} else{
				for(const field in validated) formikProps.setFieldTouched(field, true, true); //found errors so loop through object and set errors
				setformSubmitting(false);
			} 
		} else if(type === 'prev') props.prev(values);
	}

	const btnColumnSize = props.completed ? 4 : 6;

	const _handleFieldChange = (event, formikProps) => {
		// run user input through our sanitization logic to safeguard against XSS attacks
		const fieldValue = event.target.value;
		const fieldName = event.target.id;
		const cleanValue = props.sanitizeInput({ fieldValue });

		// console.log({fieldValue, fieldName: event.target})
		// update field with clean value, then run formik's change handler to carry on regular processing (such as validation)
		formikProps.setFieldValue(fieldName, cleanValue.fieldValue).then(resp => formikProps.handleBlur(event));
	}

	return (
		<Formik enableReinitialize={true} validationSchema={stepOneValidationSchema} initialValues={props.data} onSubmit={handleSubmit} >
			{
				({ values, handleChange, touched, errors, setFieldValue, handleBlur, validateForm, isValidating, setErrors, setFieldError, setFieldTouched, isSubmitting }) => (
					<Form autoComplete="off">
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Field error={touched.memberID && errors.memberID ? true : false}
								onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="Member ID" name="memberID" id="memberID" onChange={handleChange} value={values.memberID !== 0 ? values.memberID : ''} />
								<ErrorMessage className="errMsg" component="span" name="memberID" />
							</Grid>
							{
								values.memberID !== 0 && values.memberID !== '' &&
								<Grid item xs={12} md={6}>
									<Field
										error={touched.accountNumber && errors.accountNumber ? true : false}
										fullWidth component={TextField} type="number" label="Account Number" name="accountNumber" id="accountNumber" onChange={handleChange} value={values.accountNumber !== 0 ? values.accountNumber : ''} />
									<ErrorMessage className="errMsg" component="span" name="accountNumber" />
								</Grid>
							}
							<Grid item xs={12} md={6}>
								<Field onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									error={touched.loanPurpose && errors.loanPurpose ? true : false}
									fullWidth component={TextField} label="Purpose of the Loan" name="loanPurpose" id="loanPurpose" onChange={handleChange} value={values.loanPurpose} />
								<ErrorMessage className="errMsg" component="span" name="loanPurpose" />
							</Grid>
							<Grid item xs={12} md={6}>
								<Field error={touched.loanAmount && errors.loanAmount ? true : false}
									fullWidth component={TextField} type="number" label="Loan Amount (EC $)" name="loanAmount" id="loanAmount" onChange={handleChange} value={values.loanAmount !== 0 ? values.loanAmount : ''} />
								<ErrorMessage className="errMsg" component="span" name="loanAmount" />
							</Grid>
							<Grid container item>
								<Grid item xs={btnColumnSize}>
									<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, {validateForm, setFieldTouched})}>
										Previous
									</Button>
								</Grid>
								<Grid item xs={btnColumnSize}>
									<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" type="submit" >
										Next
									</Button>
								</Grid>
								{
									props.completed &&
									<Grid item xs={btnColumnSize}>
										<Button disabled={formSubmitting || isSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
											Review
										</Button>
									</Grid>
								}
							</Grid>
						</Grid>
					</Form>
				)
			}
		</Formik>
	);
};

export default Loan