import axios from 'axios'

export default class apiReq {
    /**
     * Stores an instance of `AbortController` to aid with cancelling requests
     */
    static abortController

    /**
     * Core method that carries out API requests using Axios library
     * @param {*} request 
     * @returns 
     */
    static async manageRequest(request) {
        const { method, data } = request;
        const url = request.url.indexOf('http') >= 0 ? request.url : process.env.REACT_APP_API_URL + request.url

        this.abortController = new AbortController()

        return (await axios({ method, url, data, signal: this.abortController.signal })).data;
    }

    /**
     * Performs adhoc API requests
     * @param {*} method 
     * @param {*} url 
     * @param {*} data 
     * @returns 
     */
    static async apiRequest(method, url, data){
        return await this.manageRequest({method, url, data});
    }

    /**
     * Performs standard GET API requests
     * @param {*} url 
     * @param {*} data 
     * @returns 
     */
    static async get(url, data = {}){
        return await this.manageRequest({method: 'GET', url, data});
    }

    /**
     * Performs standard POST API requests
     * @param {*} url 
     * @param {*} data 
     * @returns 
     */
    static async post(url, data = {}){
        return await this.manageRequest({method: 'POST', url, data});
    }

    /**
     * Aborts running requests
     */
    static async stop(){
        this.abortController.abort()
    }
}