// this file is where React begins its processing
// the javascript below is executed, resulting the our main App component being rendered into the div.root element in /public/index.html

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  // strict mode is fine; React just gives more insights on errors
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

