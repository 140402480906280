import React from 'react';
import { TextField } from '@mui/material';
import { useField } from 'formik';

//This handle configuration of the text field component of the form. Instead of having a bunch of code in the 'GetInProgressAppForm'.
//I just decided to separate them in the own file and then pull them in. This way we know where exactly to go to make adjust to specific component of the FormsUI.
const TextfieldWrapper = ({
  name,
  
  ...otherProps
}) => {
  const [field, meta] = useField(name);

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: 'outlined'
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return (
    <TextField {...configTextfield} />
  );
};

export default TextfieldWrapper;