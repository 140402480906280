import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import apiReq from '../scripts/apiReq'
import '../styles/Services.css';

const Services = () => {
  const [services, setServices] = useState(null)
  const [isPending, setIsPending] = useState(true)
  const [error, setError] = useState(null);

  // get services on mount
  useEffect(() => {
    apiReq.get('services').then(response => {
      // console.log("API response: ", response)

      if (response.length > 0) {
        setServices(response)
      } else {
        setError("No services were found")
      }

      setIsPending(false)
    }).catch(e => {
      console.log("Error getting services from API:\n", e)
      setError("There was a problem loading the services")
      setIsPending(false)
    })

    // when unmounting, abort running API requests
    return () => {
      apiReq.stop()
    }
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <CssBaseline />
      <Container>
        <Box sx={!services ? { p: '10rem' }: {}}>
          { isPending && <LinearProgress /> }
          { error && <div>{ error }</div> }
          { services &&
            <>
              <h1 className="page-title center">Our Services</h1>
              <Grid container spacing={2.5}>
              {services.map((service, index) => (
                <Grid item xs={12} sm={6} md={4}>
                  <Card className="service-block">
                    <Link to={`/services/${service.slug}`} state={ service }>{ service.friendlyName }</Link>
                  </Card>
                </Grid>
              ))}
              </Grid>
            </>
          }
        </Box>
      </Container>
    </>
  );
}

export default Services;
