import DOMPurify from 'dompurify';

/**
 * Sanitizes `data` against Cross-Site Scripting (XSS) attacks
 * @param {*} data 
 * @returns `cleanData`
 */
const sanitizeInput = (data) => {
    if (data instanceof Object) {
        const cleanData = {}

        // sanitize each value inside the object / array
        Object.entries(data).forEach(entryArr => {
            // since this function handles both simple and complex values, just run each value back through the function for cleaning
            cleanData[entryArr[0]] = sanitizeInput(entryArr[1])
        })

        // if the argument was an array, convert object to an array before returning
        return (Array.isArray(data)) ? Object.values(cleanData) : cleanData
    } else {
        // only simple STRING data we need to worry about cleaning (perhaps future research may reveal otherwise)
        return (typeof data === 'string') ? DOMPurify.sanitize(data, {USE_PROFILES: {html: false, svg: false}}) : data
    }
}

export default sanitizeInput