import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField, Button, Grid } from '@mui/material'
import { useState } from "react";

const stepTwoValidationSchema = Yup.object({
	home: Yup.number().label("Home phone"),
	mobile: Yup.number().required("Required").label("Mobile phone"),
	work: Yup.number().required("Required").label("Work phone"),
	other: Yup.number().label("Other phone"),
	// island: Yup.string().required().label("Island"),
});

const Contact = (props) => {
	const [formSubmitting, setformSubmitting] = useState(false);
	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});

	const handleSubmit = async (values) => {
		await sleep(100);
		props.next({phone: values});
	};

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev({phone: values}); //go to previous step
				else props.next({phone: values}, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true); 
				setformSubmitting(false);
			}
		} else if(type === 'prev') props.prev({phone: values});
	}
	
	const btnColumnSize = props.completed ? 4 : 6;

	return (
		<Formik enableReinitialize={true} validationSchema={stepTwoValidationSchema} initialValues={props.data} onSubmit={handleSubmit} >
		{({values, handleChange, touched, errors, validateForm, setFieldTouched, isSubmitting}) => (
			<Form autoComplete="off">
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<Field error={touched.home && errors.home ? true : false}
							fullWidth component={TextField} type="number" label="Home phone (optional)" name="home" id="home" value={values.home} onChange={handleChange} />
						<ErrorMessage className="errMsg" component="span" name="home" />
					</Grid>
					<Grid item xs={12} md={6}>
						<Field error={touched.mobile && errors.mobile ? true : false}
							fullWidth component={TextField} type="number" label="Mobile phone" name="mobile" id="mobile" value={values.mobile} onChange={handleChange} />
						<ErrorMessage className="errMsg" component="span" name="mobile" />
					</Grid>
					<Grid item xs={12} md={6}>
						<Field error={touched.work && errors.work ? true : false}
							fullWidth component={TextField} type="number" label="Work / Business phone" name="work" id="work" value={values.work} onChange={handleChange} />
						<ErrorMessage className="errMsg" component="span" name="work" />
					</Grid>
                    <Grid item xs={12} md={6}>
						<Field error={touched.other && errors.other ? true : false}
							fullWidth component={TextField} type="number" label="Other phone (optional)" name="other" id="other" value={values.other} onChange={handleChange} />
						<ErrorMessage className="errMsg" component="span" name="other" />
					</Grid>

					<Grid item xs={btnColumnSize}>
						<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, {validateForm, setFieldTouched})}>
							Previous
						</Button>
					</Grid>
					<Grid item xs={btnColumnSize}>
						<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
							Next
						</Button>
					</Grid>
					{
						props.completed &&
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
								Review
							</Button>
						</Grid>
					}
				</Grid>
			</Form>
		)}
		</Formik>
	);
};

export default Contact