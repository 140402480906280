// import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { TextField, Button, Grid, MenuItem, Select, LinearProgress, FormControl, InputLabel } from '@mui/material'
import { useEffect, useState } from "react";
import { ReferenceType } from "../../../scripts/enums";


const validation = values => {
	// console.log('validating', values)
	let errors = [];

	for (let index = 0; index <= 1; index++) {
		let temp = {};

		const addressFieldValue = values[index].address;
		const nameFieldValue = values[index].name;
		const typeFieldValue = values[index].type;
		const contactFieldValue = values[index].contactNumber;
		const relationshipFieldValue = values[index].relationship;

		if (nameFieldValue === '') temp.name = 'Required';
		if (relationshipFieldValue === '') temp.relationship = 'Required';
		if (contactFieldValue === '') temp.contact = 'Required';
		if (addressFieldValue === '') temp.address = 'Required';
		if (typeFieldValue === '') temp.type = 'Required';
		else {
			//check for duplicate id type
			// console.log({values});
			let typeToCheck = index === 1 ? 0 : 1;
			if (values.length > 1) if (parseInt(typeFieldValue) === parseInt(values[typeToCheck].type)) temp.type = 'No duplicate type allowed';
		}
		//if any field was invalid add to the error array
		errors.push(temp);

	}

	//this is because there are empty objects in the errors array to determine exactly which field is invalid
	//and if both are empty (all fields validated) it would still return populated error array
	if (errors.length === 2) {
		let empty = 0;
		errors.forEach(error => { if (Object.keys(error).length === 0) empty++ });
		if (empty === 2) errors = [];
	}

	// console.log('validated', errors)
	return errors;
};

const References = (props) => {
	const [memberReferences, setMemberReferences] = useState(null);
	const [formSubmitting, setformSubmitting] = useState(false);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});

	const handleSubmit = async (values) => {
		await sleep(100);
		props.next({ references: values }, true);
	};

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if (props.completed) {
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0) {
				if (type === 'prev') props.prev({ references: values }); //go to previous step
				else props.next({ references: values }, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for (const field in validated) formikProps.setFieldTouched(field, true, true);
				setformSubmitting(false);
			}
		} else if (type === 'prev') props.prev({ references: values });
	}

	const handleChange = (e) => {
		// console.log('manageChange', e);
		let tempFields = memberReferences;
		//Determine what to update based on field name
		let targetOptions = e.target.id.split("_");
		let field = targetOptions[0], id = parseInt(targetOptions[1]);
		tempFields[id][field] = props.sanitizeInput({ fieldValue: e.target.value }).fieldValue;
		//Set to state
		setMemberReferences(() => [...tempFields]);
	}

	//everytime the data change from the props is changes the state
	useEffect(() => {
		const defaultRefData = props.data.length > 0 ? props.data : [{
			type: 1,
			name: '',
			address: '',
			contactNumber: '',
			relationship: ''
		},
		{
			type: 2,
			name: '',
			address: '',
			contactNumber: '',
			relationship: ''
		}];
		setMemberReferences(defaultRefData);
		//eslint-disable-next-line
	}, []);

	// const ReferenceTypeOptions = Object.keys(ReferenceType);
	let MenuItems = [];
	ReferenceType.getEntries().entries.forEach((entry, index) => {
		MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
	})
	// for (let index = 0; index < ReferenceTypeOptions.length; index++) {
	// 	let label = ReferenceTypeOptions[index].charAt(0).toUpperCase() + ReferenceTypeOptions[index].slice(1);
	// 	let rawName = label.match(/[A-Z][a-z]+/g);
	// 	let getLabelName = (rawName) => {
	// 		let name = '';
	// 		rawName.forEach(z => {
	// 			name += z + ' ';
	// 		});
	// 		return name
	// 	}
	// 	// console.log(getLabelName(rawName));
	// 	MenuItems.push(
	// 		<MenuItem key={index} value={ReferenceType[ReferenceTypeOptions[index]]}>{getLabelName(rawName)}</MenuItem>
	// 	)
	// }

	if (memberReferences === null) return <LinearProgress />
	const btnColumnSize = props.completed ? 4 : 6;

	return (
		<Formik enableReinitialize={true} validate={validation} initialValues={memberReferences} onSubmit={handleSubmit} >
			{({ values, touched, errors, validateForm, setFieldTouched, isSubmitting }) => (
				<Form autoComplete="off">
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{
								values.length > 0 &&
								values.map((memRef, index) => {
									//console.log({touched, errors, values, memRef});
									let error = errors[index] !== undefined ? {
										type: errors[index].type !== undefined ? true : false,
										name: errors[index].name !== undefined ? true : false,
										address: errors[index].address !== undefined ? true : false,
										contactNumber: errors[index].contact !== undefined ? true : false,
										relationship: errors[index].relationship !== undefined ? true : false,
									} : false;
									let z = index + 1;
									return (
										<Grid container spacing={2} key={index} marginBottom={2}>
											<Grid item xs={12}>
												<p><b>Reference #{z}</b></p>
											</Grid>
											<Grid item xs={12} md={6}>
												{/* <p>ID Type</p> */}
												<FormControl fullWidth>
													<InputLabel shrink htmlFor={"type_" + index}>Reference Type</InputLabel>
													<Field label="Reference Type" error={error && error.type ? true : false} value={memRef.type}
														fullWidth component={Select} onChange={(e) => handleChange({ target: { id: "type_" + index, value: e.target.value } })}
														id={"type_" + index} name={"type_" + index} >
														{MenuItems}
													</Field>
													{error && error.type && <span className="errMsg">{errors[index].type}</span>}
												</FormControl>
											</Grid>
											<Grid item xs={12} md={6}>
												<Field
													error={error && error.relationship ? true : false}
													value={memRef.relationship}
													fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Relationship" name={"relationship_" + index} id={"relationship_" + index} onChange={handleChange} />
												{error && error.relationship && <span className="errMsg">{errors[index].relationship}</span>}
											</Grid>
											<Grid item xs={12} md={4}>
												<Field
													error={error && error.name ? true : false}
													value={memRef.name}
													fullWidth component={TextField} label="Person Name" type="text" name={"name_" + index} id={"name_" + index} onChange={handleChange} />
												{error && error.name && <span className="errMsg">{errors[index].name}</span>}
											</Grid>
											<Grid item xs={12} md={4}>
												{/* <p>Issue Date</p> */}
												<Field
													error={error && error.address ? true : false}
													value={memRef.address}
													fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Address" type="text" name={"address_" + index} id={"address_" + index} onChange={handleChange} />
												{/* <ErrorMessage className="errMsg" component="span" name={"address_" + index} /> */}
												{error && error.address && <span className="errMsg">{errors[index].address}</span>}
											</Grid>
											<Grid item xs={12} md={4}>
												<Field
													error={error && error.contactNumber ? true : false}
													value={memRef.contactNumber}
													fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Contact" type="number" name={"contactNumber_" + index} id={"contactNumber_" + index} onChange={handleChange} />
												{error && error.contactNumber && <span className="errMsg">{errors[index].contactNumber}</span>}
											</Grid>

										</Grid>
									)

								})

							}
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, { validateForm, setFieldTouched })}>
								Previous
							</Button>
						</Grid>
						<Grid item xs={btnColumnSize}>
							<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
								Next
							</Button>
						</Grid>
						{
							props.completed &&
							<Grid item xs={btnColumnSize}>
								<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, { validateForm, setFieldTouched })} >
									Review
								</Button>
							</Grid>
						}

					</Grid>
				</Form>
			)}
		</Formik>
	);
};

export default References