import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Select, MenuItem, TextField, Button, Grid, InputLabel, FormControl, LinearProgress } from '@mui/material'
import { SettlementMethod } from "../../../scripts/enums";
import { COST_PER_SHARE } from "../../../scripts/constants";

// import moment from "moment";
//eslint-disable-next-line
import { useEffect, useState } from "react";

const stepOneValidationSchema = Yup.object().shape({
	sharesRequested: Yup.number().min(1, 'Required').required("Required").label("Invalid"),
	// lastName: Yup.string().required("Required").label("Invalid"),
	// email: Yup.string().required("Required").email("Invalid").label("Email Address"),
	// confirmEmail: Yup.string().email("Invalid").test({
	// 	name: 'matches',
	// 	exclusive: false,
	// 	params: {},
	// 	message: 'Email Addresses does not match',
	// 	test: function (value) {
	// 		let emailEmpty = this.parent.email === "" || this.parent.email === undefined;
	// 		let emailValue = this.parent.email;
	// 		if(!emailEmpty && value !== emailValue) return false;
			
	// 		// console.log({idEmpty, numberEmpty, value});
	// 		return true;
	// 	},
	// }).required("Required").label("Confirm Email"),
	savingsAccountNumber: Yup.number().when('isMember', {
		is: true,
		then: Yup.number().min(1, "Required").required("Required"),
		otherwise: Yup.number()
	}).label("Savings Account Number")
});

let MenuItems = [];

SettlementMethod.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})

export const Shares = (props) => {
	const { sanitizeInput } = props;
	const [fieldValues, setfieldValues] = useState(null);
	const [formSubmitting, setformSubmitting] = useState(false);
	// const [emailDisabled, setemailDisabled] = useState(false);
	// const [purchaseTotal, setpurchaseTotal] = useState(0);

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});
	
	const handleSubmit = async (values) => {
		await sleep(100);
		// delete values.confirmEmail;
		props.next(values);
	};

	/**
  * Custom field change handler. Sanitizes input before Formik does its thing
  * @param {*} event the DOM event is needed for `formikProps.handleBlur`
  * @param {*} formikProps `{ setFieldValue, handleBlur }`
  */
	const _handleFieldChange = (event, formikProps) => {
		// run user input through our sanitization logic to safeguard against XSS attacks
		const fieldValue = event.target.value;
		const fieldName = event.target.id;
		const cleanValue = sanitizeInput({ fieldValue });

		// console.log({fieldValue, fieldName: event.target})
		// update field with clean value, then run formik's change handler to carry on regular processing (such as validation)
		formikProps.setFieldValue(fieldName, cleanValue.fieldValue).then(resp => formikProps.handleBlur(event));
	}

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev(values); //go to previous step
				else props.next(values, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true);
				setformSubmitting(false);
			} 
		} else if(type === 'prev') props.prev(values);
	}

	function handleShareChange(e) {
		let thisField = e.target.id,
			fieldValue = e.target.value === null || e.target.value === '' ? 0 : parseInt(e.target.value);

		// console.log({thisField, fieldValue});
		if (fieldValue !== null) setfieldValues((prev) => ({ ...prev, [thisField]: fieldValue }));
	}

	useEffect(() => {
		if (fieldValues !== null) {
			let sharesRequested = fieldValues.sharesRequested !== null ? fieldValues.sharesRequested : 1;
			let purchaseTotal = sharesRequested * COST_PER_SHARE;

			if(fieldValues.purchaseTotal !== purchaseTotal) setfieldValues((prev) => ({ ...prev, purchaseTotal }));
			console.log({purchaseTotal});
		}
		
	}, [fieldValues]);

	//everytime the data change from the props is changes the state
	useEffect(() => {
		console.log('setting fieldval 1st', props.data);
		setfieldValues(props.data);
		//eslint-disable-next-line
	}, []);

	const btnColumnSize = props.completed ? 4 : 6;

	if (fieldValues === null) return <LinearProgress />

	return (
		<Formik enableReinitialize={true} validationSchema={stepOneValidationSchema} initialValues={fieldValues} onSubmit={handleSubmit} >
			{
				({ values, handleChange, handleBlur, touched, errors, setFieldValue, isSubmitting, validateForm, setFieldTouched }) => (
					<Form autoComplete="off">
					
						<Grid container spacing={2}>
							<Grid item xs={12} md={3}>
								<Field
									error={touched.sharesRequested && errors.sharesRequested ? true : false}
									fullWidth component={TextField} type="number" label="Shares Requested" name="sharesRequested" id="sharesRequested" onChange={handleShareChange} value={values.sharesRequested} />
								<ErrorMessage className="errMsg" component="span" name="sharesRequested" />
							</Grid>
							<Grid item xs={12} md={3}>
								<Field
									fullWidth disabled={true} component={TextField} type="number" label="Purchase Total (EC $)" name="purchaseTotal" id="purchaseTotal" onChange={handleChange} value={values.purchaseTotal} />
								<ErrorMessage className="errMsg" component="span" name="purchaseTotal" />
							</Grid>
							<Grid item xs={12} md={3}>
								<FormControl fullWidth>
									<InputLabel shrink htmlFor="settlementMethod">Settlement Method</InputLabel>
									<Field value={values.settlementMethod} onBlur={handleBlur} onChange={event => {
										event.target.name = 'settlementMethod'; handleChange(event);
									}} fullWidth component={Select} id="settlementMethod" name="settlementMethod" label="Settlement Method" >
										{MenuItems}
									</Field>
								</FormControl>
							</Grid>
							{
								values.isMember &&
								<Grid item xs={12} md={3}>
									<Field error={touched.savingsAccountNumber && errors.savingsAccountNumber ? true : false}
										onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
										fullWidth component={TextField} label="Savings Account Number" name="savingsAccountNumber" id="savingsAccountNumber" onChange={handleChange} value={values.savingsAccountNumber} />
									<ErrorMessage className="errMsg" component="span" name="savingsAccountNumber" />
								</Grid>
							}
							<Grid container item>
								<Grid item xs={btnColumnSize}>
									<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('prev', values, { validateForm, setFieldTouched })}>
										Previous
									</Button>
								</Grid>
								<Grid item xs={btnColumnSize}>
									<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
										Next
									</Button>
								</Grid>
								{
									props.completed &&
									<Grid item xs={btnColumnSize}>
										<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
											Review
										</Button>
									</Grid>
								}
							</Grid>
						</Grid>
					</Form>
				)
			}
		</Formik>
	);
};

export default Shares