import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
//eslint-disable-next-line
import { Select, MenuItem, TextField, Button, Grid, InputLabel, FormControl, Switch, Stack, Typography, Alert } from '@mui/material'
import { MaritalStatus } from "../../../scripts/enums";
// import moment from "moment";
import { useEffect, useState } from "react";

// firstName: '',
// lastName: '',
// email: '',
// dob: null,
// maritalStatus: MaritalStatus.single,
// dependents: 0,


// calculate 18 years old date
let now = new Date();
let minAge = now.setFullYear(now.getFullYear() - 18);
const stepOneValidationSchema = Yup.object().shape({
	firstName: Yup.string().required("Required").label("Invalid"),
	lastName: Yup.string().required("Required").label("Invalid"),
	email: Yup.string().required("Required").email("Invalid").label("Email Address"),
	confirmEmail: Yup.string().email("Invalid").test({
		name: 'matches',
		exclusive: false,
		params: {},
		message: 'Email Addresses does not match',
		test: function (value) {
			let emailEmpty = this.parent.email === "" || this.parent.email === undefined;
			let emailValue = this.parent.email;
			if(!emailEmpty && value !== emailValue) return false;
			
			// console.log({idEmpty, numberEmpty, value});
			return true;
		},
	}).required("Required").label("Confirm Email"),
	dob: Yup.date("Invalid").max(new Date(minAge), 'You must be 18 Years old').nullable().required("Required").label("Date of Birth"),
	dependents: Yup.number().min(0, "Invalid").required("Invalid").label("Dependants"),
	maritalStatus: Yup.string().required("Required").label('Marital Status')
});

// const MaritialStatusOptions = Object.keys(MaritalStatus);
let MenuItems = [];

MaritalStatus.getEntries().entries.forEach((entry, index) => {
	MenuItems.push(<MenuItem key={index} value={entry[1].value}>{entry[1].label}</MenuItem>)
})

// for (let index = 0; index < MaritialStatusOptions.length; index++) {
// 	MenuItems.push(
// 		<MenuItem key={index} value={MaritalStatus[MaritialStatusOptions[index]]}>
// 			{MaritialStatusOptions[index].charAt(0).toUpperCase() + MaritialStatusOptions[index].slice(1)}
// 		</MenuItem>
// 	)
// }

export const Personal = (props) => {
	const { sanitizeInput } = props;
	const [formSubmitting, setformSubmitting] = useState(false);
	const [emailDisabled, setemailDisabled] = useState(false);

	// 
	useEffect(() => {
		console.log('personal', props.data)
		if(props.data.email !== '' && props.data.email === props.data.confirmEmail) setemailDisabled(true);
		else setemailDisabled(false);
	}, [props.data])
	

	const sleep = (time) => new Promise((acc) => {
		setformSubmitting(true);
		setTimeout(acc, time);
	});
	
	const handleSubmit = async (values) => {
		await sleep(100);
		// delete values.confirmEmail;
		props.next(values);
	};

	/**
  * Custom field change handler. Sanitizes input before Formik does its thing
  * @param {*} event the DOM event is needed for `formikProps.handleBlur`
  * @param {*} formikProps `{ setFieldValue, handleBlur }`
  */
	const _handleFieldChange = (event, formikProps) => {
		// run user input through our sanitization logic to safeguard against XSS attacks
		const fieldValue = event.target.value;
		const fieldName = event.target.id;
		const cleanValue = sanitizeInput({ fieldValue });

		// console.log({fieldValue, fieldName: event.target})
		// update field with clean value, then run formik's change handler to carry on regular processing (such as validation)
		formikProps.setFieldValue(fieldName, cleanValue.fieldValue).then(resp => formikProps.handleBlur(event));
	}

	const handleCustomSubmit = async (type, values, formikProps) => {
		await sleep(100);
		if(props.completed){
			let validated = await formikProps.validateForm(); //Validate form
			// if no errors found
			if (Object.keys(validated).length === 0){
				if(type === 'prev') props.prev(values); //go to previous step
				else props.next(values, true); // go to review step
			} else {
				//found errors so loop through object and set errors
				for(const field in validated) formikProps.setFieldTouched(field, true, true);
				setformSubmitting(false);
			} 
		} else if(type === 'prev') props.prev(values);
	}

	return (
		<Formik enableReinitialize={true} validationSchema={stepOneValidationSchema} initialValues={props.data} onSubmit={handleSubmit} >
			{
				({ values, handleChange, handleBlur, touched, errors, setFieldValue, isSubmitting, validateForm, setFieldTouched }) => (
					<Form autoComplete="off">
					
						<Grid container spacing={2}>
							<Grid item xs={12} md={6}>
								<Field
									error={touched.firstName && errors.firstName ? true : false}
									onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="First Name" name="firstName" id="firstName" onChange={handleChange} value={values.firstName} />
								<ErrorMessage className="errMsg" component="span" name="firstName" />
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									error={touched.lastName && errors.lastName ? true : false}
									onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="Last Name" name="lastName" id="lastName" onChange={handleChange} value={values.lastName} />
								<ErrorMessage className="errMsg" component="span" name="lastName" />
							</Grid>
							<Grid item xs={6}>
								<Field disabled={emailDisabled}
									error={touched.email && errors.email ? true : false}
									onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="Email" name="email" id="email" onChange={handleChange} value={values.email} />
								<ErrorMessage className="errMsg" component="span" name="email" />
							</Grid>
							<Grid item xs={6}>
								<Field disabled={emailDisabled}
									error={touched.confirmEmail && errors.confirmEmail ? true : false}
									onBlur={(_e) => _handleFieldChange(_e, { setFieldValue, handleBlur })}
									fullWidth component={TextField} label="Confirm Email" name="confirmEmail" id="confirmEmail" onChange={handleChange} value={values.confirmEmail} />
								<ErrorMessage className="errMsg" component="span" name="confirmEmail" />
							</Grid>
							{/* <Grid item xs={1}></Grid> */}
							<Grid item xs={12}>
								<Alert severity="info">For your convenience, we automatically save your application progress. You are free to come back anytime within 30 days of your last update to this application. Once you click Next, we will send you an email with your access details</Alert>
							</Grid>
							{/* <Grid item xs={1}></Grid> */}
							<Grid item xs={12} md={4}>
								<Field
									error={touched.dob && errors.dob ? true : false}
									fullWidth InputLabelProps={{ shrink: true }} component={TextField} label="Date of Birth" type="date" name="dob" id="dob" onChange={handleChange} value={values.dob !== null ? values.dob : ''} />
								<ErrorMessage className="errMsg" component="span" name="dob" />
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl fullWidth>
									<InputLabel shrink htmlFor="maritalStatus">Marital Status</InputLabel>
									<Field value={values.maritalStatus} onBlur={handleBlur} onChange={event => {
										event.target.name = 'maritalStatus'; handleChange(event);
									}} fullWidth component={Select} id="maritalStatus" name="maritalStatus" label="Marital Status" >
										{MenuItems}
									</Field>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={4}>
								<Field
									error={touched.dependents && errors.dependents ? true : false}
									fullWidth component={TextField} label="Number of Dependents" type="number" name="dependents" id="dependents" onChange={handleChange} value={values.dependents} />
								<ErrorMessage className="errMsg" component="span" name="dependents" />
							</Grid>
							<Grid item xs={12}>
								<p>Do you have a Co-Applicant?</p>
								<Stack direction="row" spacing={1} alignItems="center">
									<Typography>No</Typography>
									<Field
										component={Switch} name="hasCoApplicant" id="hasCoApplicant" onChange={handleChange} checked={values.hasCoApplicant ? true : false} />
									<Typography>Yes</Typography>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" type="submit" >
									Next
								</Button>
							</Grid>
							{
								props.completed &&
								<Grid item xs={6}>
									<Button disabled={isSubmitting || formSubmitting} variant="contained" color="primary" onClick={() => handleCustomSubmit('review', values, {validateForm, setFieldTouched})} >
										Review
									</Button>
								</Grid>
							}
						</Grid>
					</Form>
				)
			}
		</Formik>
	);
};

export default Personal